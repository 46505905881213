import styled from 'styled-components'
import ReactSelect from 'react-select'

interface IStyleProps {
  small?: boolean
  xsmall?: boolean
  large?: boolean
  error?: string | undefined
  styles?: any
  isSelected?: boolean
  marginRight?: number
}

export const Select = styled(ReactSelect)`
  .Select__control {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-right: ${(props: IStyleProps) => {
      if (props.marginRight) return `${props.marginRight}px`
      return '0'
    }};

    height: 50px;
    width: ${(props: IStyleProps) => {
      if (props.xsmall) return '139px'
      if (props.small) return '175px'
      if (props.large) return '200px'
      return '315px'
    }};

    padding-left: 10px;

    background: ${(props: IStyleProps) =>
      props.error ? '#F606650D' : '#f9f9f9'};
    border: ${(props: IStyleProps) =>
      props.error ? '1px solid #f60665' : '1px solid #9c948c'};
    box-sizing: border-box;
    &:hover {
      border: 1px solid #9c948cdd;
      outline: hidden;
      background-color: #8f888133;
    }
    &:disabled {
      &::placeholder {
        color: white;
      }
      background: lightGray;
      border: 0;
    }
  }

  .Select__control--is-focused {
    box-shadow: 0 0 0 1px black;
    outline: hidden;

    &:hover {
      outline: hidden;
    }
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__indicator {
    color: #9c948c;
    padding-right: 15px;
  }

  .Select__menu {
    background-color: #fefefe;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    box-sizing: border-box;
    margin-top: 2px;
    border-radius: 5px;
    max-width: ${(props: IStyleProps) => {
      if (props.xsmall) return '139px'
      if (props.small) return '150px'
      if (props.large) return '200px'
      return '315px'
    }};
  }

  .Select__option {
    padding-left: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 15px 30px;
    text-align: left;
    overflow: hidden;
    height: 50px;
    width: ${(props: IStyleProps) => {
      if (props.xsmall) return '139px'
      if (props.small) return '150px'
      if (props.large) return '200px'
      return '315px'
    }};
    max-width: ${(props: IStyleProps) => {
      if (props.xsmall) return '139px'
      if (props.small) return '150px'
      if (props.large) return '200px'
      return '315px'
    }};

    &:hover {
      background-color: #f5f4f3;
      color: #9c948c;
    }
  }
`

export const SelectContainer = styled.div`
  position: relative;
  height: 74px;
`

export const InputError = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: start;

  box-sizing: border-box;
  padding: 5px 20px;

  width: ${(props: IStyleProps) => {
    if (props.xsmall) return '139px'
    if (props.small) return '150px'
    if (props.large) return '200px'
    return '315px'
  }};
  text-overflow: ellipsis;
  overflow: hidden;
  color: #f60665;
`
