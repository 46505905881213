import styled from 'styled-components'

interface IStyleProps {
  noMargin?: boolean
}

export default styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 25px;
  margin: 27px 0 0
    ${(props: IStyleProps) => {
      return props.noMargin ? '30px' : '227px'
    }};
`
