import { icons, ribbons } from 'helper'
import { IIcons } from 'helper/icons'
import Card from './style'

interface ICardProps {
  children: string
  variation?: string
  icon: keyof IIcons
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  wide?: boolean
  value: string
  inverted?: boolean
  plain?: boolean
  blackIcon?: boolean
  onClick?: any
  miner?: string
  tooltip?: boolean
  rating?: number
}

export default function CardComponent(props: ICardProps) {
  const { children, icon, variation, value, inverted, secondary, tooltip, rating } = props
  return (
    <Card {...props}>
      {secondary && (
        <img className="ribbon" src={ribbons.crypto} alt="ribbon" />
      )}
      <div className="rating">
        {
          rating && [...new Array(Math.min(5, rating))].map(() => (
            <img src={icons.rating} alt="card icon" />
          ))
        }
      </div>
      <div className="flex">
        {icon && <img className="icon" src={icons[icon]} alt="card icon" />}
        {inverted ? <span className="value">{children}</span>
          : (
          <div className='tooltip'>
            <span className="value">
              { String(value).length > 15 ? `${String(value).substring(0, 15)}...` : String(value) }
            </span>
            {tooltip && <span className="tooltiptext">{value}</span>}
          </div>
        )}
      </div>
      {inverted ? (
        <span className="title">{value}</span>
      ) : (
        <span className="title">{children}</span>
      )}
      {variation && <span className="variation">{variation}</span>}
    </Card>
  )
}
