import styled from 'styled-components'

interface IStyleProps {
  background?: string
}

export const Content = styled.div`
  z-index: 25 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: ${(props: IStyleProps) => props.background || 'white'};
  top: 0;
  left: 0;

  p {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: black;
  }
`
