import { useContext, useEffect, useRef, useState } from 'react'
import { SapiensContext } from 'context/sapiens'
import { getAddressByCEP, useFetch } from 'hooks'
import { Row } from 'styles'
import {
  Select,
  PageTitle,
  PagePathing,
  Input,
  ButtonNext,
  MaskedInput,
} from 'components'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { icons } from 'helper'
import { FormDiv } from './style'
import { ScrollDiv } from '../Resumo/style'
import { formatDate } from 'helper/utils'

const mockGarantia = [
  // NÃO DESCOMENTAR
  /* {
    label: 'Pro',
    value: 'pro',
  }, */
  {
    label: 'Basic',
    value: 0,
  },
]

const qtdParcelas = [
  { label: '1x', value: 1 },
  { label: '2x', value: 2 },
  { label: '3x', value: 3 },
  { label: '4x', value: 4 },
  { label: '5x', value: 5 },
  { label: '6x', value: 6 },
  { label: '7x', value: 7 },
  { label: '8x', value: 8 },
  { label: '9x', value: 9 },
  { label: '10x', value: 10 },
  { label: '11x', value: 11 },
  { label: '12x', value: 12 },
]

const qtdParcelas2 = [{ label: '1x', value: 1 }]

const mockPagamento = [
  {
    label: 'Cartão de crédito',
    value: 0,
  },
  {
    label: 'Boleto',
    value: 1,
  },
  {
    label: 'Pix',
    value: 2,
  },
]

const mockTiposImoveis = [
  {
    label: 'Casa',
    value: 'casa',
  },
  {
    label: 'Apartamento',
    value: 'apartamento',
  },
  {
    label: 'Sítio',
    value: 'sitio',
  },
]

const schemaProperty = Yup.object().shape({
  type: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required('campo obrigatório'),
  address: Yup.string().required('campo obrigatório: rua'),
  city: Yup.string().required('campo obrigatório: cidade'),
  neighborhood: Yup.string().required('campo obrigatório: bairro'),
  state: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required('campo obrigatório: state'),
  zipcode: Yup.string().min(8).max(9).required('campo obrigatório: cep'),
  addressNumber: Yup.string().required('campo obrigatório: numero'),
  complement: Yup.string().required('campo obrigatório: complemento'),
  rentValue: Yup.string().required('campo obrigatório: aluguel'),
  condominiumValue: Yup.string(),
})

const schemaContract = Yup.object().shape({
  contractType: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required('campo obrigatório: tipo de contrato'),
  paymentType: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required('campo obrigatório: tipo de pagamento'),
  installmentsCard: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required('campo obrigatório: parcelas'),
})

const schemaInquilino = Yup.object().shape({
  name: Yup.string().required('campo obrigatório: nome'),
  documentNumber: Yup.string()
    .min(14)
    .max(14)
    .required('campo obrigatório: cpf'),
  birthday: Yup.string().required('campo obrigatório: data de nascimento'),
  mainEmail: Yup.string().required('campo obrigatório: email'),
  mainPhone: Yup.string()
    .min(15)
    .max(15)
    .required('campo obrigatório: telefone'),
})

export default function Residencial({ handleNext }: any) {
  const {
    stateList,
    contract,
    setContract,
    property,
    setProperty,
    setResidents,
    residents
  } = useContext(SapiensContext)

  const [allowNextPage, setAllowNextPage] = useState(false)
  const [realty, setRealty] = useState([])
  const [inquilino, setInquilino] = useState({
    name: '',
    documentNumber: '',
    documentType: 'CPF',
    birthday: '',
    mainEmail: '',
    mainPhone: '',
    isCoresponsible: false,
  })

  const formRef = useRef<HTMLFormElement | any>(null)

  const api = useFetch('property')

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.fetch('get')
        if (response.fail) throw new Error()
        setRealty(response.data)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
    setProperty({
      type: '',
      address: '',
      addressNumber: '',
      zipcode: '',
      code: '',
      state: '',
      city: '',
      country: 'Brasil',
      complement: '',
      neighborhood: '',
      iptuValue: 0,
      condominiumValue: 0,
      rentValue: 0,
    })
    setContract({
      contractType: 0,
      paymentType: 0,
      installmentsCard: 0,
      insuranceType: 0,
      coverageAmount: 0,
      coverageType: 0,
      residentsNumber: 0,
    })
  }, [])

  const handleChangePage = async () => {
    try {
      await schemaProperty.validate(property, { abortEarly: false })
      await schemaContract.validate(contract, { abortEarly: false })
      await schemaInquilino.validate(inquilino, { abortEarly: false })
      setResidents([inquilino])
      handleNext()
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: {
          [key: string]: { path: string; message: string }
        } = {}
        err.inner.forEach((error: any) => {
          validationErrors[error.path] = error.message
        })
        formRef.current.setErrors(validationErrors)
      } else {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    const validateInputs = async () => {
      try {
        await schemaProperty.validate(property, { abortEarly: false })
        await schemaContract.validate(contract, { abortEarly: false })
        await schemaInquilino.validate(inquilino, { abortEarly: false })
        setAllowNextPage(true)
      } catch (err) {
        console.log(err)
        setAllowNextPage(false)
      }
    }
    validateInputs()
  }, [inquilino, property, contract])

  const handleCep = async (e: any) => {
    const cepValue = e.replace(/[^0-9]/g, '')
    setProperty({ ...property, zipcode: cepValue })
    if (cepValue.length === 8) {
      const r = await getAddressByCEP(cepValue)
      setProperty({
        ...property,
        address: r.data.address,
        city: r.data.city,
        neighborhood: r.data.district,
        state: { label: r.data.state, value: r.data.state },
        zipcode: e,
      })
    }
  }

  const handleMoney = (value: string) => {
    return Number(value.replace('R$ ', '').replace(/\./, '').replace(',', '.'))
  }

  const handleSelectedRealty = (e: any) => {
    const selected: { [key: string]: any } =
      realty.find((item: any) => {
        if (item.id === e.value) return item
        return null
      }) || {}
    setProperty({
      ...selected.address,
      code: e.label,
      rentValue: selected.condominiumValue,
      condominiumValue: selected.condominiumValue,
      iptuValue: selected.condominiumValue,
      state: { label: selected.address?.state, value: selected.address?.state },
      type: { label: selected.type, value: selected.type },
    })
  }

  return (
    <>
      <PagePathing
        withoutMarginLeft
      >{`Gestão de contratos > Novo contrato > Residencial`}</PagePathing>
      <ScrollDiv>
        <FormDiv>
          <Form onSubmit={() => null} ref={formRef}>
            <PageTitle withoutMarginLeft>
              <img
                className="titleIcon"
                src={icons.clientOutlined}
                alt="first title icon"
              />
              Informações do serviço
            </PageTitle>
            <Row style={{ alignItems: 'flex-start' }} gap={15}>
              <Select
                name="contractType"
                placeholder="Tipo de garantia"
                options={mockGarantia}
                onChange={(e: any) => {
                  setContract({
                    ...contract,
                    contractType: e,
                  })
                }}
              />
              <Select
                name="paymentType"
                placeholder="Forma de pagamento"
                options={mockPagamento}
                onChange={(e: any) =>
                  setContract({
                    ...contract,
                    paymentType: e,
                  })
                }
              />
              <Select
                name="installmentsCard"
                placeholder="Quantidade de parcelas do cartão"
                options={
                  contract.paymentType.value !== 0 ? qtdParcelas2 : qtdParcelas
                }
                onChange={(e: any) =>
                  setContract({
                    ...contract,
                    installmentsCard: e,
                  })
                }
              />
            </Row>
            <PageTitle withoutMarginLeft>
              <img
                className="titleIcon"
                src={icons.homeSolid}
                alt="first title icon"
              />
              Dados do imóvel
            </PageTitle>
            <Row style={{ alignItems: 'flex-start' }} gap={15}>
              <Select
                disabled={contract.contractType.value === 1}
                name="type"
                placeholder="Tipo de imóvel"
                options={mockTiposImoveis}
                onChange={(e: any) =>
                  setProperty({
                    ...property,
                    type: e,
                  })
                }
              />
              {contract.contractType.value === 1 ? (
                <Select
                  name="listImoveis"
                  placeholder="Imóveis cadastrados"
                  options={realty.map((item: any) => {
                    return {
                      label: item.code,
                      value: item.id,
                    }
                  })}
                  onChange={(e: any) => handleSelectedRealty(e)}
                />
              ) : (
                <Input
                  large
                  value={property.code}
                  type="text"
                  name="code"
                  placeholder="Código do imóvel"
                  onChange={(e: any) => {
                    setProperty({
                      ...property,
                      code: e.target.value,
                    })
                  }}
                />
              )}
              <Input
                small
                value={property.zipcode}
                type="cep"
                onChange={(e: any) => {
                  handleCep(e.target.value)
                }}
                name="zipcode"
                placeholder="CEP"
              />
              <Input
                disabled={contract.contractType.value === 1}
                small
                type="text"
                name="neighborhood"
                placeholder="Bairro"
                value={property.neighborhood}
                onChange={(e: any) =>
                  setProperty({
                    ...property,
                    neighborhood: e.target.value,
                  })
                }
              />
            </Row>
            <Row gap={15}>
              <Select
                disabled={contract.contractType.value === 1}
                small
                name="state"
                placeholder="UF"
                options={stateList}
                onChange={(e: any) =>
                  setProperty({
                    ...property,
                    state: e.value,
                  })
                }
                value={property.state}
              />
              <Input
                disabled={contract.contractType.value === 1}
                small
                placeholder="Cidade"
                type="text"
                name="city"
                value={property.city}
                onChange={(e: any) =>
                  setProperty({
                    ...property,
                    city: e.target.value,
                  })
                }
              />
              <Input
                disabled={contract.contractType.value === 1}
                type="text"
                name="address"
                placeholder="Endereço"
                value={property.address}
                onChange={(e: any) =>
                  setProperty({
                    ...property,
                    address: e.target.value,
                  })
                }
              />
              <Input
                disabled={contract.contractType.value === 1}
                small
                type="text"
                name="addressNumber"
                placeholder="Número"
                value={property.addressNumber}
                onChange={(e: any) =>
                  setProperty({
                    ...property,
                    addressNumber: e.target.value,
                  })
                }
              />
              <Input
                disabled={contract.contractType.value === 1}
                small
                type="text"
                name="complement"
                placeholder="Complemento"
                value={property.complement}
                onChange={(e: any) =>
                  setProperty({
                    ...property,
                    complement: e.target.value,
                  })
                }
              />
            </Row>
            <Row gap={15}>
              <MaskedInput
                type="money"
                handleChange={(e: any) => {
                  setProperty({
                    ...property,
                    rentValue: handleMoney(e),
                  })
                }}
                placeholder="Valor do aluguel"
                name="rentValue"
              />
              <MaskedInput
                type="money"
                handleChange={(e: any) => {
                  setProperty({
                    ...property,
                    condominiumValue: handleMoney(e),
                  })
                }}
                placeholder="Valor do condomínio"
                name="condominiumValue"
              />
              <MaskedInput
                type="money"
                handleChange={(e: any) => {
                  setProperty({
                    ...property,
                    iptuValue: handleMoney(e),
                  })
                }}
                placeholder="Valor do IPTU"
                name="iptuValue"
              />
            </Row>
            <PageTitle withoutMarginLeft>
              <img
                className="titleIcon"
                src={icons.clientOutlined}
                alt="first title icon"
              />
              Dados do inquilino
            </PageTitle>
            <Row style={{ alignItems: 'flex-start' }} gap={15}>
              <Input
                placeholder="Nome"
                type="text"
                name="name"
                value={inquilino.name}
                onChange={(e: any) =>
                  setInquilino({
                    ...inquilino,
                    name: e.target.value,
                  })
                }
              />
              <MaskedInput
                smallPad
                small
                defaultValue={inquilino.documentNumber}
                type="cpf"
                handleChange={(e: any) => {
                  setInquilino({
                    ...inquilino,
                    documentNumber: e,
                  })
                }}
                placeholder="CPF"
                name="document"
              />
              <MaskedInput
                smallPad
                small
                defaultValue={inquilino.birthday}
                type="birthday"
                handleChange={(e: any) => {
                  setInquilino({
                    ...inquilino,
                    birthday: e,
                  })
                }}
                placeholder="Data nascimento"
                name="birthday"
              />
            </Row>
            <Row gap={15}>
              <Input
                placeholder="E-mail"
                type="text"
                name="mainEmail"
                value={inquilino.mainEmail}
                onChange={(e: any) =>
                  setInquilino({
                    ...inquilino,
                    mainEmail: e.target.value,
                  })
                }
              />
              <MaskedInput
                defaultValue={inquilino.mainPhone}
                type="cell"
                handleChange={(e: any) => {
                  setInquilino({
                    ...inquilino,
                    mainPhone: e,
                  })
                }}
                name="phoneNumber"
                placeholder="Telefone"
              />
            </Row>
          </Form>
        </FormDiv>
      </ScrollDiv>
      <ButtonNext onClick={handleChangePage} disabled={!allowNextPage} />
    </>
  )
}
