import { useContext, useState, useRef } from 'react'
import { PagePathing, PageTitle, KanbanSubMenu, Modal } from 'components'
import { SapiensContext } from 'context'
import { IObject } from 'interfaces'
import { Content } from 'styles'
import { icons } from 'helper'
import Kanban from './Kanban'

import NovaRequisição from './NovaRequisicao'

export default function GestaoContratos() {
  const { extended } = useContext(SapiensContext)
  const [showModal, setShowModal] = useState<boolean>(false)
  const kanbanRef = useRef<IObject>(null)

  const categories = [
    ['Inquilinos', `/gestaoGarantias/inquilinos`, icons.client2Outlined, true],
    [
      'Proprietários',
      `/gestaoGarantias/proprietarios`,
      icons.clientPJSolid,
      false,
    ],
    ['Nova solicitação', setShowModal, icons.plusSolid, false],
  ]

  return (
    <>
      <Content extended={extended}>
        <div>
          <PagePathing>{`Gestão de cobertura > Coberturas`}</PagePathing>
          <PageTitle>Coberturas</PageTitle>
          <KanbanSubMenu categories={categories} />
        </div>
        <Kanban ref={kanbanRef} />
      </Content>
      <Modal FS showModal={showModal} setShowModal={setShowModal}>
        <NovaRequisição
          onSubmit={() => {
            setShowModal(false)
            kanbanRef.current?.refresh()
          }}
        />
      </Modal>
    </>
  )
}
