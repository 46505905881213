import uuid from 'react-uuid'
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useState,
  useEffect,
} from 'react'
import { useFetch } from 'hooks'
import { Wrapper, CardsWrap } from './style'
import {
  PagePathing,
  PageTitle,
  AccountStatus,
  Card,
  ClickableCard,
} from 'components'
import Transferir from './Transferir'
import SoonInvestir from './SoonInvestir'
import { numberToMoney } from 'helper/utils'
import { Row } from 'styles/index'

interface ISaldoModal {
  data?: any
  setPage?: Dispatch<SetStateAction<String>>
  setExtendedMenu: Dispatch<SetStateAction<ReactElement | boolean>>
}

export default function Dashboard(props: ISaldoModal) {
  const { setExtendedMenu, setPage } = props
  const [showInvestir, setShowInvestir] = useState<boolean>(false)
  const [data, setData] = useState<any>({ cards: [], status: {}})
  const api = useFetch('dashboard/assets')

  useEffect(() => {
    api
      .fetch('get')
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <Wrapper type="column">
      <div className="content-wrapper">
        <PagePathing withoutMarginLeft>{`Dashboard > Saldo`}</PagePathing>
        <PageTitle withoutMarginLeft>Saldo</PageTitle>
        <div className="margem">
          <AccountStatus status={data.status.name} color={data.status.color} />
        </div>
      </div>
      <CardsWrap type="row">
        <Row gap={20} width='100%'>
        {data.cards.map(([icon, size, value, label, disable, _var, _color, rating]: any, i: number) => {
          return (
            <Card
              green={i === 0}
              key={uuid()}
              tooltip
              variation=""
              value={value}
              wide={size === 'l'}
              icon={icon}
              disabled={disable}
              rating={rating}
            >
              {label}
            </Card>
          )
        })}
        </Row>
        <ClickableCard
          green
          onClick={() => setExtendedMenu(<Transferir />)}
          icon="sendOutlined"
        >
          Tansferir
        </ClickableCard>
        <ClickableCard
          green
          onClick={() => setPage && setPage('extract')}
          icon="statementOutlined"
        >
          Extrato
        </ClickableCard>
        <ClickableCard
          green
          icon="stonksOutlined"
          onClick={() => {
            setShowInvestir(true)
          }}
        >
          Investir
        </ClickableCard>
      </CardsWrap>
      {showInvestir && (
        <SoonInvestir showModal={showInvestir} setShowModal={setShowInvestir} />
      )}
    </Wrapper>
  )
}
