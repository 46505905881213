import styled from 'styled-components'

interface IStyleProps {
  rounded?: boolean
  small?: boolean
  xsmall?: boolean
  large?: boolean
  tall?: boolean
  size?: string | undefined
  icon?: boolean
  type?: string
  error?: string
  marginRight?: number
  smallPad?: boolean
}

export const Input = styled.input`
  display: flex;
  justify-content: flex-start !important;
  position: ${(props: IStyleProps) => (props.icon ? 'relative' : 'inherit')};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: ${(props: IStyleProps) => {
    if (props.marginRight) return `${props.marginRight}px`
    return '0'
  }};

  height: ${(props: IStyleProps) => {
    if (props.tall) return '150px'
    return '50px'
  }};

  width: ${(props: IStyleProps) => {
    if (props.size) return props.size
    if (props.xsmall) return '139px'
    if (props.small) return '175px'
    if (props.large) return '200px'
    return '315px'
  }};

  -webkit-autofill:active {
    box-shadow: 0 0 0 30px #f9f9f9 inset !important;
    background-color: #f9f9f9 !important;
  }

  padding: ${(props: IStyleProps) => {
    if (props.smallPad) return '20px 10px'
    return '20px 10px 20px 20px'
  }};

  box-sizing: border-box;
  border: ${(props: IStyleProps) =>
    props.error ? '1px solid #f60665' : '1px solid #9c948c'};
  border-radius: ${(props: IStyleProps) => (props.rounded ? '30px' : '5px')};
  background: ${(props: IStyleProps) =>
    props.error ? '#F606650D' : '#f9f9f9'};

  &:hover {
    background: ${(props: IStyleProps) =>
      props.error ? '#F6066510' : '#8f888133'};
  }

  &:disabled {
    &::placeholder {
      color: #fff;
    }
    background: lightGray;
    border: 0;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
`

export const InputArea = styled.textarea`
  position: ${(props: IStyleProps) => (props.icon ? 'relative' : 'inherit')};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: ${(props: IStyleProps) => {
    if (props.marginRight) return `${props.marginRight}px`
    return '0'
  }};

  height: ${(props: IStyleProps) => {
    if (props.tall) return '150px'
    return '50px'
  }};

  width: ${(props: IStyleProps) => {
    if (props.size) return props.size
    if (props.xsmall) return '139px'
    if (props.small) return '175px'
    if (props.large) return '200px'
    return '315px'
  }};

  -webkit-autofill:active {
    box-shadow: 0 0 0 30px #f9f9f9 inset !important;
    background-color: #f9f9f9 !important;
  }

  padding: ${(props: IStyleProps) => {
    if (props.smallPad) return '20px 10px'
    return '20px 10px 20px 20px'
  }};

  box-sizing: border-box;
  border: ${(props: IStyleProps) =>
    props.error ? '1px solid #f60665' : '1px solid #9c948c'};
  border-radius: ${(props: IStyleProps) => (props.rounded ? '30px' : '5px')};
  background: ${(props: IStyleProps) =>
    props.error ? '#F606650D' : '#f9f9f9'};

  &:hover {
    background: ${(props: IStyleProps) =>
      props.error ? '#F6066510' : '#8f888133'};
  }

  &:disabled {
    &::placeholder {
      color: #fff;
    }
    background: lightGray;
    border: 0;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
`

export const InputIcon = styled.img`
  width: ${(props: { icon: string }) => {
    if (props.icon === 'password') return '16px'
    return '20px'
  }};

  height: ${(props: { icon: string }) => {
    if (props.icon === 'password') return '16px'
    return '20px'
  }};

  filter: invert(48%) sepia(5%) saturate(92%) hue-rotate(98deg) brightness(93%)
    contrast(97%);

  position: absolute;
  right: 25px;
  top: 17px;
`

export const InputError = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: start;
  padding: 5px 20px;
  width: 215px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #f60665;
`
