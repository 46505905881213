import styled from 'styled-components'

interface IStyleProps {
  withoutMarginLeft?: boolean
}

export default styled.h3`
  height: 23px;
  font-size: 14px;
  font-weight: 400;
  color: #868686;
  margin-left: ${(props: IStyleProps) => {
    if (props.withoutMarginLeft) return `0`
    return '30px'
  }};

  .marginTop20 {
    margin-top: 20px;
  }
`
