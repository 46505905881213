import { PagePathing, PageTitle, Modal, KanbanSubMenu } from 'components'
import { SapiensContext } from 'context'
import { useContext, useState, useEffect, ReactElement } from 'react'
import { ResumoImovel, CadastroImovel } from 'pages'
import { Content } from 'styles'
import { icons, ribbons } from 'helper'
import { CardsDiv, Card } from './style'

export default function GestaoContratos() {
  const { extended } = useContext(SapiensContext)
  const [showModal, setShowModal] = useState(false)
  const [pageNumber, setPageNumber] = useState<number>(0)

  useEffect(() => {
    if (!showModal) {
      setPageNumber(0)
    }
  }, [showModal])

  const pages: { [key: number]: ReactElement } = {
    0: (
      <CadastroImovel
        handleNext={() => setPageNumber((oldValue) => oldValue + 1)}
      />
    ),
    1: (
      <ResumoImovel
        handleBack={() => setPageNumber((oldValue) => oldValue - 1)}
      />
    ),
  }

  const categories = [
    ['Imóveis', `/gestaoImoveis`, icons.client2Outlined, false],
    ['Novo imóvel', '/gestaoImoveis/novoImovel', icons.plusSolid, true],
  ]

  return (
    <Content extended={extended}>
      <div>
        <PagePathing>{`Gestão de imóveis > Novo imóvel`}</PagePathing>
        <PageTitle>Gestão de imóveis</PageTitle>
        <KanbanSubMenu categories={categories} />
        <Modal FS setShowModal={setShowModal} showModal={showModal}>
          {pages[pageNumber]}
        </Modal>
      </div>
      <CardsDiv>
        <Card onClick={() => setShowModal(true)}>
          <img
            className="iconClickable"
            src={icons.homeSolid}
            alt="card icon"
          />
          <span className="title">Residencial</span>
        </Card>
        <Card>
          <img className="selectIcon" src={ribbons.soon} alt="select icon" />
          <img
            className="iconClickable"
            src={icons.buildingSolid}
            alt="card icon"
          />
          <span className="title">Comercial</span>
        </Card>
        <Card>
          <img className="selectIcon" src={ribbons.soon} alt="select icon" />
          <img className="iconClickable" src={icons.hatSolid} alt="card icon" />
          <span className="title">Universitário</span>
        </Card>
      </CardsDiv>
      <Modal FS setShowModal={setShowModal} showModal={showModal}>
        {pages[pageNumber]}
      </Modal>
    </Content>
  )
}
