import { useRef } from 'react'
import { CodeInput, Button, Modal } from 'components'
import { Form } from '@unform/web'
import { ContainerS, PageTitle } from './style'

interface ISecurity {
  isLoading: boolean
  onClick: (r: any) => void
  onModalClose?: (r: any) => void
  error?: string
}

export default function SecurityConfirm(props: ISecurity) {
  const {
    isLoading,
    onClick,
    onModalClose,
    error
  } = props

  const formRef = useRef(null)

  return (
    <Modal
      closeButton
      setShowModal={(e: any) => { onModalClose && onModalClose(e) }}
      showModal
      width={500}
      height={500}
      hideMenu
    >
      <ContainerS>
        <div>
          <PageTitle> {`Digite os 4 primeiros dígitos`}</PageTitle>
          <PageTitle> {`da sua senha`}</PageTitle>
        </div>
        <Form onSubmit={onClick} ref={formRef}>
          <ContainerS>
            <CodeInput
              noBorder
              type="text"
              inputType="password"
              name="pin"
              maxLength={4}
              required
              autoFocus
              security
            />
            <Button $loading={isLoading} rounded>
              CONFIRMAR
            </Button>
            {
              error && <small>{error}</small>
            }
          </ContainerS>
        </Form>
      </ContainerS>
    </Modal>
  )
}
