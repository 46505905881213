import { ResumeInputCard, PageTitle } from 'components'
import { Content } from './style'
import uuid from 'react-uuid'

const mock = [
  { icon: "contractOutlined", label: '# 123546789', value: 'Contrato de garantia' },
  { icon: "houseGreenOutlined", label: '# 123456789', value: 'Contrato de aluguel' },
]

export default () => {
  return (
    <div className="sub-container">
      <PageTitle icon="hashOutlined">IDs do contrato</PageTitle>
      <Content>
        {
          mock.map((item) => (
            <ResumeInputCard
              key={uuid()}
              clickable={false}
              names="button"
              icon={item.icon}
              placeholder={item.label}
            >
              {item.value}
            </ResumeInputCard>
          ))
        }
      </Content>
    </div>
  )
}
