import styled from 'styled-components'

interface IStyleProps {
  green?: boolean
  extractFilter?: boolean
}

export const Checkbox = styled.input`
  position: relative;
  width: ${(props: IStyleProps) => {
    if (props.extractFilter) return '26px'
    return '20px'
  }};
  height: ${(props: IStyleProps) => {
    if (props.extractFilter) return '26px'
    return '20px'
  }};
  margin-right: ${(props: IStyleProps) => {
    if (props.extractFilter) return '30px'
    return '0'
  }};
  border: 1px solid;
  border-color: ${(props: IStyleProps) =>
    props.green ? '#0BF569' : '#070707'};
  border-radius: 4px;
  appearance: none;
  outline: 0;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 3px;
    left: ${(props: IStyleProps) => {
    if (props.extractFilter) return '8px'
    return '6px'
  }};
    width:${(props: IStyleProps) => {
    if (props.extractFilter) return '7px'
    return '4px'
  }};
    height: ${(props: IStyleProps) => {
    if (props.extractFilter) return '11px'
    return '7px'
  }};
    border-style: solid;
    border-color: white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: white !important;
    border-color: ${(props: IStyleProps) =>
      props.green ? '#0BF569' : '#070707'};
    background: ${(props: IStyleProps) =>
      props.green ? '#0BF569' : '#070707'};
    &::before {
      opacity: 1;
    }
  }

  &:hover {
    background-color: ${(props: IStyleProps) =>
      props.green ? '#0BF56933' : '#07070733'};
    &:checked {
      background: ${(props: IStyleProps) =>
        props.green ? '#0BF569' : '#070707'};
    }
  }
`

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  font-weight: ${(props: IStyleProps) => {
    if (props.extractFilter) return '500'
    return '400'
  }};
  color: ${(props: IStyleProps) => {
    if (props.extractFilter) return 'black'
    return '#9c948c'
  }};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`
