import 'react-calendar/dist/Calendar.css';
import { PALLETE } from 'configs/configs';
import styled from "styled-components"
import Calendar from 'react-calendar';

interface IStyleProps {
  type?: string
  marginTop?: number
  marginBottom?: number
  justifyContent?: string
}


export const CardsWrap = styled.div`
  display: flex;
  /* z-index: 100; */
  margin-top: ${(props: IStyleProps) => {
    switch (props.type) {
      case 'row':
        return '20px';
      default:
        return '20px';
    }
  }};
  flex-direction: ${(props: IStyleProps) => {
    switch (props.type) {
      case 'row':
        return 'row';
      default:
        return 'column';
    }
  }};
  max-height: 100%;
  overflow: scroll;
  gap: 20px;
  padding: 0 5px 30px 0;
  flex-wrap: ${(props: IStyleProps) => {
    switch (props.type) {
      case 'row':
        return 'wrap';
      default:
        return 'no-wrap';
    }
  }};

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`;

export const Wrapper = styled.div`
  flex-direction: ${(props: IStyleProps) => {
    switch (props.type) {
      case 'column':
        return 'column';
      default:
        return 'row';
    }
  }};
  width: ${(props: IStyleProps) => {
    switch (props.type) {
      case 'column':
        return '1100px';
      default:
        return '80%';
    }
  }};
  height: 85%;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  // overflow-x: hidden;
`;

export const ReactCalendar = styled(Calendar)`
  width: 330px !important;
  height: 300px !important;
  border: none !important;
  padding: 24px;
  margin-right: 30px;

  .react-calendar__tile {
    border-radius: 8px;
    max-width: 100%;
    background: none;
    text-align: center;
    line-height: 16px;
  }

  .react-calendar__tile--active {
    background: ${PALLETE.green} !important;
    color: white !important;
  }

  .react-calendar__tile {
    background: none;
    border-radius: 8px;
    border: 1px solid white;
    line-height: 16px;
    padding: 6px 3px;
    text-align: center;
  }

  .react-calendar__navigation__label__labelText {
    color: ${PALLETE.black};
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: ${PALLETE.inputGrey};
    margin-bottom: 10px;
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${PALLETE.lighestGrey};
  }

  abbr[title] {
    text-decoration: none !important;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-transform: capitalize;
  }

  .react-calendar__navigation__label:hover {
    background-color: transparent !important;
  }


  .react-calendar__navigation__label,
  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    height: 32px;
    min-width: 32px;
  }

  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    border: 1px solid ${PALLETE.almostWhite};
  }

  .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
    background: ${PALLETE.lightGrey};
  }
`;

export const Subtitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #070707;
  margin-top: ${(props: IStyleProps) => {
    if (props.marginTop) return `${props.marginTop}px`
    return '30px'
  }};
  margin-bottom: ${(props: IStyleProps) => {
    if (props.marginBottom) return `${props.marginBottom}px`
    return '30px'
  }};
`
export const ButtonBack = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 28px;
`