import { Modal } from 'components'
import { SapiensContext } from 'context'
import { useContext, useState, useEffect, ReactElement } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { DadosUsuario, NivelAcesso } from 'pages'
import { Content } from 'styles'

export default function GestaoUsuarios() {
  const { extended } = useContext(SapiensContext)
  const [showModal, setShowModal] = useState(true)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const location = useLocation()

  useEffect(() => {
    if (showModal) {
      setPageNumber(0)
    }
  }, [showModal])

  const pages: { [key: number]: ReactElement } = {
    0: (
      <DadosUsuario
        handleNext={() => setPageNumber((oldValue) => oldValue + 1)}
      />
    ),
    1: (
      <NivelAcesso
        handleNext={() => setPageNumber((oldValue) => oldValue + 1)}
        handleBack={() => setPageNumber((oldValue) => oldValue - 1)}
      />
    ),
  }

  if (!showModal)
    return <Navigate to="/gestaoUsuarios" state={{ from: location }} replace />

  return (
    <Content extended={extended}>
      <Modal FS setShowModal={setShowModal} showModal={showModal}>
        {pages[pageNumber]}
      </Modal>
    </Content>
  )
}
