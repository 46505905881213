import { useContext, useEffect, useState } from 'react'
import { icons } from 'helper'

import LoadGifComponent from 'components/LoadGif'
import { useNavigate } from 'react-router-dom'
import { SapiensContext } from 'context/sapiens'
import { LoadingBackground } from './style'

export default function Confirm() {
  const { title } = useContext(SapiensContext)
  const [confirmed, setConfirmed] = useState(false)
  const navigate = useNavigate()

  // TODO - resolver essa gambiarra
  const redirect = () => {
    setConfirmed(true)
    setTimeout(() => {
      if (title === 'Usuário cadastrado') {
        navigate('/gestaoUsuarios')
      }
      if (title === 'Enviado para análise') {
        navigate('/gestaoImoveis')
      }
      if (title === 'Enviado para análise ') {
        navigate('/gestaoContratos')
      }
      if (title === 'Informações atualizadas') {
        navigate('/gestaoUsuarios')
      }
      if (title === 'Senha cadastrada!') {
        navigate('/login')
      }
      if (title === 'Transferência\nrealizada!') {
        navigate('/dashboard')
      }
    }, 3000)
  }
  useEffect(() => {
    setTimeout(() => redirect(), 4000)
  }, [])
  return (
    <>
      {!confirmed && (
        <LoadingBackground color="white">
          <LoadGifComponent confirm black />
        </LoadingBackground>
      )}
      {confirmed && (
        <LoadingBackground color="#0BF569">
          <img src={icons.confirmationOutlined} alt="confirmIcon" />
          <p>{title}</p>
        </LoadingBackground>
      )}
    </>
  )
}
