import { Menu, SearchBar } from 'components'
import { useAuth } from 'hooks'

import { Navigate, Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
`

export default function PrivateRoute() {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.user?.isLogged) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (auth.user) {
    const { level } = auth.user

    if (/gestaoContratos/i.test(location.pathname) && !level.includes(0)) {
      return <Navigate to="/dashboard" state={{ from: location }} replace />
    }

    if (/gestaoImoveis/i.test(location.pathname) && !level.includes(1)) {
      return <Navigate to="/dashboard" state={{ from: location }} replace />
    }

    if (/gestaoAlugueis/i.test(location.pathname) && !level.includes(2)) {
      return <Navigate to="/dashboard" state={{ from: location }} replace />
    }

    if (/gestaoUsuarios/i.test(location.pathname) && !level.includes(4)) {
      return <Navigate to="/dashboard" state={{ from: location }} replace />
    }

    if (/gestaoFinanceira/i.test(location.pathname) && !level.includes(3)) {
      return <Navigate to="/dashboard" state={{ from: location }} replace />
    }
  }

  return (
    <Container>
      <SearchBar />
      <Menu />
      <Outlet />
    </Container>
  )
}
