import { Modal, Button } from "components"
import { Container, PageTitle, PageContent } from './style'
import excede from './excede.png'

interface IModalProps {
  onModalClose: (e: any) => void
}

export default function ExcedeLimite (props: IModalProps) {
  const { onModalClose } = props
  return (
    <Modal
    closeButton
    setShowModal={onModalClose}
    showModal
    width={500}
    height={500}
    hideMenu
    >
      <Container>
        <div>
          <PageTitle>O valor excede o limite</PageTitle>
          <PageTitle>e transferência via Pix.</PageTitle>
        </div>
        <div>
          <PageContent>O limite para transferência via pix</PageContent>
          <PageContent>é de até R$ 100.000,00 por dia.</PageContent>
        </div>
        <img src={excede} alt="imagem_TED" height={194} />
        <Button rounded secondary onClick={() => onModalClose(false)}>ENTENDI</Button>
      </Container>
    </Modal>
  )
}