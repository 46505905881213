import { Link } from 'react-router-dom'
import { ForgotPass } from './style'

interface IForgotPassProps {
  children: JSX.Element[] | JSX.Element | string
  to?: string
}

export default function RecoverPass(props: IForgotPassProps) {
  const { children, to } = props
  return (
    <Link to={to || ''}>
      <ForgotPass {...props}>{children}</ForgotPass>
    </Link>
  )
}
