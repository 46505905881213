import { SapiensContext } from 'context'
import { icons, images } from 'helper'
import { useAuth } from 'hooks'
import { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu } from './style'
import Button from './Button'

export default function MenuComponent() {
  const { extended, setExtended } = useContext(SapiensContext)
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { level } = auth.user

  const [extendedIcon, setExtendedIcon] = useState(extended)

  // TODO - usar este meio para realizar o redirect quando o token expirar
  const handleLogout = () => {
    auth.signout(() => navigate('/login'))
  }

  const handleExtendMenu = () => {
    setExtended((oldValue) => !oldValue)
    setExtendedIcon((oldValue) => !oldValue)
  }

  return (
    <Menu extended={extended}>
      <img
        className="extendButton"
        src={icons.backSolid}
        onClick={handleExtendMenu}
        onKeyDown={handleExtendMenu}
        alt="extend menu"
      />
      <div className="buttons">
        <div>
          {extendedIcon ? (
            <img src={images.logoMenu} alt="dashboard" className="logo" />
          ) : (
            <Button
              fixed
              icon="newHouseGreenSolid"
              name="logo"
              extended={false}
              to="dashboard"
            />
          )}
          <Button
            extendedIcon={extendedIcon}
            extended={extended}
            icon="dashboardOutlined"
            name="dashboard"
            active={/dashboard/i.test(location.pathname)}
            to="dashboard"
          >
            Dashboard
          </Button>
          {level.includes(0) ? (
            <Button
              extendedIcon={extendedIcon}
              extended={extended}
              icon="contractOutlined"
              name="gestaoContratos"
              active={/gestaoContratos/i.test(location.pathname)}
              to="gestaoContratos"
            >
              Gestão de garantias
            </Button>
          ) : (
            ''
          )}
          {level.includes(1) ? (
            <Button
              extendedIcon={extendedIcon}
              extended={extended}
              icon="houseGreenOutlined"
              name="gestaoImoveis"
              active={/gestaoImoveis/i.test(location.pathname)}
              to="gestaoImoveis"
            >
              Gestão de imóveis
            </Button>
          ) : (
            ''
          )}
          {level.includes(2) ? (
            <Button
              extendedIcon={extendedIcon}
              extended={extended}
              icon="buildingSolid"
              name="gestaoGarantias"
              active={/gestaoGarantias/i.test(location.pathname)}
              to="gestaoGarantias/inquilinos"
            >
              Gestão de cobertura
            </Button>
          ) : (
            ''
          )}
          {level.includes(4) ? (
            <Button
              extendedIcon={extendedIcon}
              extended={extended}
              icon="clientsOutlined"
              name="gestaoUsuarios"
              fixed
              active={/gestaoUsuarios/i.test(location.pathname)}
              to="gestaoUsuarios"
            >
              Gestao de usuários
            </Button>
          ) : (
            ''
          )}
        </div>
        <Button
          fixed
          extended={extended}
          extendedIcon
          icon="logoutOutlined"
          name="Logout"
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>
    </Menu>
  )
}
