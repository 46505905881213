import * as r from 'assets/Ribbon'

export interface IRibbons {
  basic: string
  crypto: string
  personal: string
  pro: string
  soon: string
}

const ribbons: IRibbons = {
  basic: r.basic,
  crypto: r.crypto,
  personal: r.personal,
  pro: r.pro,
  soon: r.soon,
}

export default ribbons
