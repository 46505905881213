import { Row } from 'styles'
import {
  PageTitle,
  PagePathing,
  ButtonNext,
  ResumeInputCard,
  ResumeSelectCard,
} from 'components'
import { Form } from '@unform/web'
import { useContext, useRef } from 'react'
import { SapiensContext } from 'context/sapiens'
import * as Yup from 'yup'
import { icons } from 'helper'
import { clearString } from 'helper/utils'
import { FormDiv, ScrollDiv } from './style'

const mockImoveis = [
  {
    address: 'rua iole monteiro de lima',
    city: 'Belho Horizonte',
    neighborhood: 'Castelo',
    state: 'MG',
    zipcode: 30840470,
    addressNumber: '10',
    complement: 'perto do restaurante mexicano',
    id: 10,
    code: '4549',
    type: 'Casa',
  },
  {
    address: 'rua ebano pereira de lima',
    city: 'Curitiba',
    neighborhood: 'Centro',
    state: 'PR',
    zipcode: 80410240,
    addressNumber: '45',
    complement: 'apto 110',
    id: 11,
    code: '4550',
    type: 'Apartamento',
  },
  {
    address: 'rua rio de janeiro',
    city: 'Divinopolis',
    neighborhood: 'Centro',
    state: 'MG',
    zipcode: 35500009,
    addressNumber: '643',
    complement: 'apto 1401',
    id: 12,
    code: '4551',
    type: 'Apartamento',
  },
]

const mockGarantia = [
  {
    label: 'Basic',
    value: 0,
  },
  /* {
    label: 'Pro',
    value: 1,
  }, */
]

const qtdParcelas = [
  { label: '1x', value: 1 },
  { label: '2x', value: 2 },
  { label: '3x', value: 3 },
  { label: '4x', value: 4 },
  { label: '5x', value: 5 },
  { label: '6x', value: 6 },
  { label: '7x', value: 7 },
  { label: '8x', value: 8 },
  { label: '9x', value: 9 },
  { label: '10x', value: 10 },
  { label: '11x', value: 11 },
  { label: '12x', value: 12 },
]

const qtdParcelas2 = [{ label: '1x', value: 1 }]

const mockPagamento = [
  {
    label: 'Cartão de crédito',
    value: 0,
  },
  {
    label: 'Boleto',
    value: 1,
  },
  {
    label: 'Pix',
    value: 2,
  },
]

const mockTiposImoveis = [
  {
    label: 'Casa',
    value: 'casa',
  },
  {
    label: 'Apartamento',
    value: 'apartamento',
  },
  {
    label: 'Sítio',
    value: 'sitio',
  },
]

export default function Resumo({ handleNext, handleBack }: any) {
  const {
    stateList,
    contract,
    setContract,
    property,
    setProperty,
    residents,
    setResidents,
  } = useContext(SapiensContext)

  const formRef = useRef<HTMLFormElement | any>(null)

  const handleChangePage = async () => {
    try {
      handleNext()
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: {
          [key: string]: { path: string; message: string }
        } = {}
        err.inner.forEach((error: any) => {
          validationErrors[error.path] = error.message
        })
        formRef.current.setErrors(validationErrors)
      } else {
        console.log(err)
      }
    }
  }

  const changeResidents = (e: any, name: string) => {
    const updateResident = residents[0]
    updateResident[name] = e
    setResidents([updateResident])
  }

  const handleMoney = (value: string) => {
    return Number(value.split(' ')[1].replace('.', '').replace(',', '.'))
  }

  const handleSelectedRealty = (e: any) => {
    const selected = mockImoveis.find((item: any) => {
      if (item.id === e.value) {
        return item
      }
      return null
    })
    setProperty({
      ...selected,
      state: { label: selected?.state, value: selected?.state },
      type: { label: selected?.type, value: selected?.type },
    })
  }

  return (
    <>
      <PagePathing
        withoutMarginLeft
      >{`Gestão de contratos > Novo contrato > Residencial`}</PagePathing>
      <PageTitle withoutMarginLeft>Resumo da solicitação</PageTitle>
      <ScrollDiv>
        <FormDiv>
          <Form onSubmit={() => null} ref={formRef}>
            <PageTitle withoutMarginLeft>
              <img
                className="titleIcon"
                src={icons.leafOutlined}
                alt="first title icon"
              />
              Informações do serviço
            </PageTitle>
            <Row gap={15} className="row">
              <ResumeSelectCard
                small
                name={contract.contractType.label || ''}
                icon="calendarOutlined"
                options={mockGarantia}
                handleChange={(e: any) => {
                  setContract({
                    ...contract,
                    contractType: e,
                  })
                }}
              >
                Tipo de garantia
              </ResumeSelectCard>
              <ResumeSelectCard
                small
                name={contract.paymentType.label || ''}
                icon="calendarOutlined"
                options={mockPagamento}
                handleChange={(e: any) =>
                  setContract({
                    ...contract,
                    paymentType: e,
                  })
                }
              >
                Forma de pagamento
              </ResumeSelectCard>
              <ResumeSelectCard
                small
                name={contract.installmentsCard.label || ''}
                icon="calendarOutlined"
                options={
                  contract.paymentType.value !== 'cartao_credito'
                    ? qtdParcelas2
                    : qtdParcelas
                }
                handleChange={(e: any) =>
                  setContract({
                    ...contract,
                    installmentsCard: e,
                  })
                }
              >
                Quantidade de parcelas
              </ResumeSelectCard>
            </Row>
            <PageTitle withoutMarginLeft>
              <img
                className="titleIcon"
                src={icons.homeSolid}
                alt="first title icon"
              />
              Imóvel residencial
            </PageTitle>
            <Row gap={15} className="row">
              <ResumeSelectCard
                disabled={contract.contractType.value !== 0}
                name={property.type.label || ''}
                icon="mailOutlined"
                options={mockTiposImoveis}
                handleChange={(e: any) =>
                  setProperty({
                    ...property,
                    type: e,
                  })
                }
              >
                Tipo de imóvel
              </ResumeSelectCard>
              <ResumeInputCard
                clickable={contract.contractType.value === 0}
                handleChangeInput={(e: any) =>
                  setProperty({
                    ...property,
                    zipcode: clearString(e),
                  })
                }
                names="resumo-meta-captacao"
                small
                icon="calendarOutlined"
                placeholder={property.zipcode}
              >
                CEP
              </ResumeInputCard>
              <ResumeInputCard
                clickable={contract.contractType.value === 0}
                handleChangeInput={(e: any) =>
                  setProperty({
                    ...property,
                    addressNumber: e,
                  })
                }
                names="resumo-meta-captacao"
                small
                icon="calendarOutlined"
                placeholder={property.addressNumber}
              >
                Número
              </ResumeInputCard>
              <ResumeInputCard
                clickable={contract.contractType.value === 0}
                handleChangeInput={(e: any) =>
                  setProperty({
                    ...property,
                    complement: e,
                  })
                }
                names="resumo-meta-captacao"
                small
                icon="calendarOutlined"
                placeholder={property.complement}
              >
                Complemento
              </ResumeInputCard>
            </Row>
            <Row gap={15} className="row">
              <ResumeInputCard
                clickable={contract.contractType.value === 0}
                handleChangeInput={(e: any) =>
                  setProperty({
                    ...property,
                    neighborhood: e,
                  })
                }
                names="resumo-meta-captacao"
                small
                icon="calendarOutlined"
                placeholder={property.neighborhood}
              >
                Bairro
              </ResumeInputCard>
              <ResumeInputCard
                clickable={contract.contractType.value === 0}
                handleChangeInput={(e: any) =>
                  setProperty({
                    ...property,
                    address: e,
                  })
                }
                names="resumo-meta-captacao"
                icon="mailOutlined"
                placeholder={property.address}
              >
                Endereço
              </ResumeInputCard>
              <ResumeSelectCard
                disabled={contract.contractType.value !== 0}
                small
                name={property.state.label || ''}
                icon="calendarOutlined"
                options={stateList}
                handleChange={(e: any) =>
                  setProperty({
                    ...property,
                    state: e,
                  })
                }
              >
                UF
              </ResumeSelectCard>
              <ResumeInputCard
                clickable={contract.contractType.value === 0}
                handleChangeInput={(e: any) =>
                  setProperty({
                    ...property,
                    city: e.target.value,
                  })
                }
                names="resumo-meta-captacao"
                small
                icon="calendarOutlined"
                placeholder={property.city}
              >
                Cidade
              </ResumeInputCard>
            </Row>
            <Row gap={15} className="row">
              <ResumeSelectCard
                disabled={contract.contractType.value !== 1}
                small
                name={property.code || ''}
                icon="mailOutlined"
                options={mockImoveis.map((item) => {
                  return {
                    label: item.code,
                    value: item.id,
                  }
                })}
                handleChange={(e: any) => handleSelectedRealty(e)}
              >
                Código do imóvel
              </ResumeSelectCard>
              <ResumeInputCard
                isMask="R$ num"
                blocks={{
                  num: {
                    mask: Number,
                    thousandsSeparator: '.',
                  },
                }}
                handleChangeInput={(e: any) =>
                  setProperty({
                    ...property,
                    rentValue: handleMoney(e),
                  })
                }
                names="resumo-meta-captacao"
                small
                icon="calendarOutlined"
                placeholder={property.rentValue}
              >
                Valor do aluguel
              </ResumeInputCard>
              <ResumeInputCard
                isMask="R$ num"
                blocks={{
                  num: {
                    mask: Number,
                    thousandsSeparator: '.',
                  },
                }}
                handleChangeInput={(e: any) =>
                  setProperty({
                    ...property,
                    condominiumValue: handleMoney(e),
                  })
                }
                names="resumo-meta-captacao"
                small
                icon="calendarOutlined"
                placeholder={property.condominiumValue}
              >
                Valor do condomínio
              </ResumeInputCard>
              <ResumeInputCard
                isMask="R$ num"
                blocks={{
                  num: {
                    mask: Number,
                    thousandsSeparator: '.',
                  },
                }}
                handleChangeInput={(e: any) =>
                  setProperty({
                    ...property,
                    iptuValue: handleMoney(e),
                  })
                }
                names="resumo-meta-captacao"
                small
                icon="calendarOutlined"
                placeholder={property.iptuValue}
              >
                Valor do IPTU
              </ResumeInputCard>
            </Row>
            <PageTitle withoutMarginLeft>
              <img
                className="titleIcon"
                src={icons.clientOutlined}
                alt="first title icon"
              />
              Dados do inquilino
            </PageTitle>
            <Row gap={15} className="row">
              <ResumeInputCard
                handleChangeInput={(e: any) => changeResidents(e, 'name')}
                names="resumo-meta-captacao"
                icon="mailOutlined"
                placeholder={residents[0].name}
              >
                Nome
              </ResumeInputCard>
              <ResumeInputCard
                isMask="000.000.000-00"
                small
                handleChangeInput={(e: any) =>
                  changeResidents(clearString(e), 'documentNumber')
                }
                names="resumo-meta-captacao"
                icon="mailOutlined"
                placeholder={residents[0].documentNumber}
              >
                CPF
              </ResumeInputCard>
              <ResumeInputCard
                small
                handleChangeInput={(e: any) => changeResidents(e, 'birthday')}
                names="resumo-meta-captacao"
                icon="mailOutlined"
                placeholder={residents[0].birthday}
              >
                Data nascimento
              </ResumeInputCard>
            </Row>
            <Row gap={15} className="row">
              <ResumeInputCard
                handleChangeInput={(e: any) => changeResidents(e, 'mainEmail')}
                names="resumo-meta-captacao"
                icon="mailOutlined"
                placeholder={residents[0].mainEmail}
              >
                E-mail
              </ResumeInputCard>
              <ResumeInputCard
                isMask="(00) 00000-0000"
                handleChangeInput={(e: any) =>
                  changeResidents(clearString(e), 'mainPhone')
                }
                names="resumo-meta-captacao"
                icon="phoneOutlined"
                placeholder={residents[0].mainPhone}
              >
                Telefone
              </ResumeInputCard>
            </Row>
          </Form>
        </FormDiv>
      </ScrollDiv>
      <ButtonNext static onClick={handleChangePage} onClickBack={handleBack} />
    </>
  )
}
