import { CenteredContainer, ErrorMessage, LoginContainer } from 'styles'
import { images } from 'helper'
import { useRef, useContext, useEffect, useState } from 'react'
import { Input, Button, RecoverPass, ErrorMsg } from 'components'
import { useAuth, useFetch } from 'hooks'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { SapiensContext } from 'context'

export default function LoginPage() {
  const navigate = useNavigate()
  const location: any = useLocation()
  const { loading, setLoading } = useContext(SapiensContext)
  const [msgError, setMsgError] = useState(false)

  const auth = useAuth()
  const { fetch, isLoading } = useFetch('person/login')

  const from = location.state?.from?.pathname || '/'

  const formRef = useRef<HTMLFormElement | any>(null)

  const handleSubmit = async (data: { email: string; password: string }) => {
    const schema = Yup.object().shape({
      password: Yup.string().required('Insira uma senha'),
      email: Yup.string().required('Insira um e-mail'),
    })
    formRef.current.setErrors({})
    try {
      await schema.validate(data, { abortEarly: false })
      const response = await fetch('post', data)
      if (response.fail) setMsgError(true)
      auth.signin(response.data.token, () => {
        navigate(from, { replace: true })
      })
    } catch (err: any) {
      setMsgError(err.message)
      if (err instanceof Yup.ValidationError) {
        const validationErrors: {
          [key: string]: { path: string; message: string }
        } = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      } else {
        formRef.current.setErrors({ code: err.message })
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  const handleChange = () => {
    formRef.current.setErrors({})
  }

  if (auth.user?.isLogged) {
    return <Navigate to="/dashboard" replace />
  }

  return (
    <CenteredContainer login>
      <img src={images.logoSapiens} alt="Sapiens Logo" />
      <LoginContainer>
        Acessar plataforma
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input
            name="email"
            type="email"
            rounded
            placeholder="Email"
            onChange={handleChange}
          />
          <Input
            name="password"
            icon="eye"
            type="password"
            rounded
            placeholder="Senha"
            onChange={handleChange}
          />
          <Button $loading={loading} rounded>
            ENTRAR
          </Button>
          {msgError ? <ErrorMsg>Usuário/Senha inválidos</ErrorMsg> : ''}
        </Form>
      </LoginContainer>
      <RecoverPass to="/recover">Esqueceu a senha?</RecoverPass>
    </CenteredContainer>
  )
}
