import jwtDecode from 'jwt-decode'
import { useRef, useEffect, useState } from 'react'
import { useFetch, useSocket } from 'hooks'
import { images } from 'helper'
import { IObject } from 'interfaces'

import { CenteredContainer, LoginContainer } from 'styles'
import { Input, Button, ErrorMsg, LoadingBanner } from 'components'
import { Form } from '@unform/web'
import { Navigate, useSearchParams, useNavigate } from 'react-router-dom'
import { TextSpan } from './style'
import { CONNECTION } from '../../../configs/configs.js'

export default function RedefinirSenha() {
  const navigate = useNavigate()
  const [error, setError] = useState(false)
  const [socket, setSocket] = useState<IObject>()
  const [status, setStatus] = useState<string>('idle')

  const [searchParams, _] = useSearchParams()
  const [token] = useState<string>(searchParams.get('token') || '')
  const api = useFetch('person/recover')

  const [isValidToken, setIsValidToken] = useState<boolean>(true)

  const formRef = useRef<HTMLFormElement | any>(null)

  useEffect(() => {
    try {
      if (!token) throw new Error()
      const { hash, issuer, audience } = jwtDecode<any>(token)
      if (!hash || !issuer || !audience) throw new Error('data is missing')
      if (issuer !== CONNECTION.ISSUER) throw new Error('malformed server')
      if (audience !== CONNECTION.AUDIENCE) throw new Error('malformed app')
    } catch (err: any) {
      formRef.current.setErrors(err)
      setIsValidToken(false)
    }
  }, [token])

  useSocket((io: IObject) => {
    setSocket(io)
  }, socket)

  const handleSubmit = async (data: {
    password: string
    confirmPassword: string
    token: string
  }) => {
    try {
      setStatus('loading')
      const { password, confirmPassword } = data
      if (password !== confirmPassword) return setError(true)
      data.token = token
      const response = await api.fetch('put', data)
      if (response.fail) throw new Error()
      return setStatus('loaded')
    } catch (err) {
      formRef.current.setErrors(err)
      console.log(err)
      return setStatus('idle')
    }
  }

  const handleChange = () => {
    formRef.current.setErrors({})
  }

  if (!isValidToken) return <Navigate to="/login/recover" replace />

  switch (status) {
    case 'loading':
      return <LoadingBanner status={status} />
    case 'loaded':
      socket?.emit('refresh', { command: 'adm_parner' })
      return (
        <LoadingBanner
          status={status}
          confirmationText="Senha cadastrada!"
          onFinished={() => navigate('/')}
        />
      )
    default:
      return (
        <CenteredContainer login>
          <img src={images.logoSapiens} alt="Sapiens Logo" />
          <LoginContainer>
            Crie uma senha
            <TextSpan>
              Guarde a sua senha. Você precisará dela sempre que acessar a
              plataforma. Ela deve ter apenas números e, no mínimo, 8 dígitos.
            </TextSpan>
            <Form onSubmit={handleSubmit} ref={formRef}>
              <Input
                name="password"
                icon="eye"
                type="password"
                rounded
                placeholder="Criar nova senha"
                onChange={handleChange}
              />
              <Input
                name="confirmPassword"
                icon="eye"
                type="password"
                rounded
                placeholder="Confirmar nova senha"
                onChange={handleChange}
              />
              <Button $loading={status !== 'idle'} rounded>
                ALTERAR SENHA
              </Button>
              {error && (
                <ErrorMsg>As duas senhas precisam ser idênticas</ErrorMsg>
              )}
            </Form>
          </LoginContainer>
        </CenteredContainer>
      )
  }
}
