import icons, { IIcons } from 'helper/icons'
import { numberToMonth } from 'helper/utils'
import { Card, Row } from './style'

interface IHistoryCardProps {
  icon: keyof IIcons
  signal: string
  value: string
  label: string
  date: Date
  onClick?: any
}

export default (props: IHistoryCardProps) => {
  const { icon, signal, value, label, date, onClick } = props

  const newDate = (data: Date) => {
    const [__, month] = numberToMonth(data.getMonth())
    const day = data.getDate()
    return `${day} ${month}`
  }

  const newHour = (data: Date) => {
    const hour = data.getHours() < 10 ? `0${data.getHours()}` : data.getHours()
    const min = data.getMinutes() < 10 ? `0${data.getMinutes()}` : data.getMinutes()
    return `${hour}:${min}`
  }

  return (
    <Card signal={signal} onClick={onClick}>
      <Row>
        <img className="icon" src={icons[icon]} alt="history-icon" />
        <p className="value">{`${signal} ${value}`}</p>
      </Row>
      <p>{`${newDate(date)} às ${newHour(date)}`}</p>
      <p className="bold">{label}</p>
    </Card>
  )
}
