import icons from 'helper/icons'
import Div from './style'

interface IRadioButtonProps {
  name?: string
  checked: boolean
  children: string
  value: string
  id: string
  onClick: any
}

export default function RadioButton(props: IRadioButtonProps) {
  const { name, checked, children, value, id, onClick } = props
  return (
    <Div {...props}>
      <input
        type="radio"
        name={name}
        value={value}
        id={id}
        onClick={onClick}
        checked={checked}
      />
      {checked ? (
        <img
          className="iconClickable"
          src={icons.checkedSolid}
          alt="card icon"
        />
      ) : (
        <span className="checkmark" />
      )}
      <label htmlFor={id}>{children}</label>
    </Div>
  )
}
