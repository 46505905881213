import styled from 'styled-components'

export const ForgotPass = styled.div`
  max-width: 150px;
  height: 20px;
  color: #0bf569;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  border: none;
  background-color: white;
  margin-top: 30px;
`
