import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  theme: ITheme
  green?: boolean
  secondary?: boolean
  disabled?: boolean
}

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  height: 145px;
  width: 175px;

  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #00000040;

  background: ${(props: IStyleProps) => {
    if (props.green) return `${props.theme.card.backgroundColor.green}`
    if (props.secondary) return `${props.theme.card.backgroundColor.secondary}`
    return `${props.theme.card.backgroundColor.primary}`
  }};

  cursor: ${(props: IStyleProps) => {
    if (props.disabled) return ''
    return 'pointer'
  }};

  .title {
    color: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.fontColor.disabled}`
      if (props.secondary) return `${props.theme.card.fontColor.secondary}`
      return `${props.theme.card.fontColor.primary}`
    }};

    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    text-align: left;
    line-height: 12px;

    margin: 0 0 22px 25px;
  }

  .iconClickable {
    filter: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.icon.disabled}`
      if (props.secondary) return `${props.theme.card.icon.white}`
      if (props.green) return `${props.theme.card.icon.green}`
      return `${props.theme.card.icon.green}`
    }};
    max-width: 24px;
    height: 24px;
    margin: 0 0 12px 25px;
  }

  .selectIcon {
    width: 53px;
    margin-bottom: 24px;
    margin-left: -4px;
  }
`

export const CardsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 25px;
  margin: 27px 0 0 226px;
`

export const Span = styled.div`
  width: 475px;
  height: 17px;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 1%;
  margin-bottom: 30px;
`
