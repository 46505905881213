import ProgressBar from './style'

interface IProgressBar {
  progress: number
}

export default function ProgressBarComponent({ progress }: IProgressBar) {
  return (
    <ProgressBar progress={progress}>
      <div className="fillbar" />
    </ProgressBar>
  )
}
