import { CenteredContainer, LoginContainer } from 'styles'
import { images } from 'helper'
import { useRef, useContext, useState } from 'react'
import { Input, Button, ErrorMsg } from 'components'
import { Form } from '@unform/web'
import { useFetch } from 'hooks'
import { SapiensContext } from 'context'
import { TextSpan } from './style'

export default function Recover() {
  const { loading } = useContext(SapiensContext)
  const [msgError, setMsgError] = useState(false)
  const [msg, setMsg] = useState('')

  const api = useFetch('person/recover')

  const formRef = useRef<HTMLFormElement | any>(null)

  const handleSubmit = async (data: { email: string }) => {
    try {
      const response = await api.fetch('post', data)
      response.fail
        ? setMsg('Usuário não encontrado')
        : setMsg('E-mail enviado com sucesso!')
      setMsgError(true)
    } catch (e) {
      console.log(e)
    }
  }

  const handleChange = () => {
    formRef.current.setErrors({})
  }

  return (
    <CenteredContainer login>
      <img src={images.logoSapiens} alt="Sapiens Logo" />
      <LoginContainer>
        Redefina sua Senha
        <TextSpan>
          Digite seu email e enviaremos um link para redefinir sua senha
        </TextSpan>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input
            name="email"
            type="email"
            rounded
            placeholder="Email"
            onChange={handleChange}
          />
          <Button $loading={loading} rounded>
            REDEFINIR SENHA
          </Button>
          {msgError ? <ErrorMsg green={msg.includes('!')}>{msg}</ErrorMsg> : ''}
        </Form>
      </LoginContainer>
    </CenteredContainer>
  )
}
