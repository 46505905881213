import { useEffect, useState } from "react"
import { RadioContainer, Box, CircleIn, CircleOut, TouchableIcon } from './style'
import { icons } from 'helper'

interface IRadioProps {
  options?: any
  selectionColor?: string
  onSelect?: any
  boxStyle?: any
  selected?: number
  row?: boolean

}

export default function RadioInput( props: IRadioProps ) {
  const {
    options = [],
    selectionColor = '#0BF569',
    onSelect = () => {},
    boxStyle = {},
    selected = -1,
    row = false,
  } = props
  const [selectedIndex, setSelectedIndex] = useState(selected)

  useEffect(() => {
    setSelectedIndex(selected)
  }, [selected])

  const handleSelect = (item: any, index: number) => {
    if (!item.available) return;
    onSelect(item)
    setSelectedIndex(index)
  }

  const isSelected = (index: number) => index === selectedIndex;
  
  return (
    <RadioContainer row={row}>
      {options.map((item: any, index: number) => (
          <Box
            key={item.id}
            style={boxStyle}
            >
            {/* <button
              type="button"
              style={{flexDirection: 'row', alignItems: 'center', border: 'none', backgroundColor: 'transparent' }}
              onClick={() => { handleSelect(item, index) }}
            > */}
              <CircleOut
                type="button"
                onClick={() => { handleSelect(item, index) }}
                unavailable={!item.available}
                selected={isSelected(index)}
                selectionColor={selectionColor} 
                >
                <CircleIn
                unavailable={!item.available}
                selectionColor={selectionColor}
                selected={isSelected(index)}
                />
              </CircleOut>
            {item.content}
            {/* </button> */}
            {item.icon && (
              <TouchableIcon onClick={() => item.icon.onPress()} type="button">
                <img className="icon" src={icons[item.icon]} alt="radio icon" />
              </TouchableIcon>
            )}
          </Box>
        )
      )}
    </RadioContainer>
  )
}