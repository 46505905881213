import styled from "styled-components"

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const PageTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  width: auto;
  /* text-align: center !important; */
  margin: 0;
`
export const PageContent = styled.p`
  color: #9C948C;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* text-align: center !important; */
  margin: 0;
`

export const GreenText = styled.span`
color: #0BF569;
font-weight: 400;
font-size: 12px;
line-height: 16px;
margin-left: 10px;
`