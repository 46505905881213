import { IMAGES } from 'configs/configs.js'
import { images } from '.'

export interface IMenuIcons {
  [key: string]: string
}

const boxIcons: IMenuIcons = {
  boxchatOutlined: `${IMAGES.URL}/outlined/boxChat.svg`,
  boxchatSolid: `${IMAGES.URL}/solid/boxChat.png`,
  boxclientOutlined: `${IMAGES.URL}/outlined/boxclient.svg`,
  boxclientSolid: `${IMAGES.URL}/solid/boxClient.png`,
  boxdashboardOutlined: `${IMAGES.URL}/outlined/boxdashboard.svg`,
  boxdashboardSolid: `${IMAGES.URL}/solid/boxDashboard.png`,
  boxlogoutOutlined: `${IMAGES.URL}/outlined/boxlogout.svg`,
  boxlogoutSolid: `${IMAGES.URL}/solid/boxLogout.png`,
  boxproductOutlined: `${IMAGES.URL}/outlined/boxproduct.svg`,
  boxproductSolid: `${IMAGES.URL}/solid/boxProduct.png`,
  dashboardOutlined: `${IMAGES.URL}/outlined/dashboard.svg`,
  chatOutlined: `${IMAGES.URL}/outlined/chat.svg`,
  clientOutlined: `${IMAGES.URL}/outlined/client.svg`,
  leafOutlined: `${IMAGES.URL}/outlined/leaf.svg`,
  leafSolid: `${IMAGES.URL}/solid/leaf.svg`,
  logo: images.logoMenu,
  logoutOutlined: `${IMAGES.URL}/outlined/logout.svg`,
  houseGreenSolid: `${IMAGES.URL}/solid/houseGreen.svg`,
  houseGreenOutlined: `${IMAGES.URL}/outlined/houseGreen.svg`,
  contractOutlined: `${IMAGES.URL}/outlined/contract.svg`,
  clientsOutlined: `${IMAGES.URL}/outlined/clients.svg`,
  lockOutlined: `${IMAGES.URL}/outlined/lock.svg`,
  newHouseGreenSolid: `${IMAGES.URL}/solid/newHouseGreen.svg`,
  buildingSolid: `${IMAGES.URL}/solid/building.svg`,
}

export default boxIcons
