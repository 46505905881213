import { Dispatch, SetStateAction } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  DraggableLocation,
} from 'react-beautiful-dnd'
import { KanbanCard } from 'components'
import { icons } from 'helper'
import { IKanbanColumns, IKanbanItem } from 'interfaces'
import { KanbanColumn, KanbanContainer } from './style'

interface IKanbanProps {
  data: { [key: number]: IKanbanColumns }
  setData: Dispatch<SetStateAction<{ [key: number]: IKanbanColumns }>>
  onCardClick?(_event?: object): any
  onChatClick?(_item?: object): any
  withoutChatIcon?: boolean
  withoutIcon?: boolean
  onDropCard?: any
}

export default function KanbanComponent(props: IKanbanProps) {
  const {
    data,
    setData,
    onCardClick,
    onChatClick,
    withoutChatIcon,
    withoutIcon,
    onDropCard,
  } = props
  // função que muda a ordem dos cards de uma mesma coluna
  const reorder = (
    list: IKanbanItem[],
    startIndex: number,
    endIndex: number
  ) => {
    // clona o array de itens
    const result = [...list]
    // remove o card do array
    const [removed] = result.splice(startIndex, 1)
    // reinsere ele na posição desejada
    result.splice(endIndex, 0, removed)

    return result
  }

  // função que passa um card de uma coluna para outra
  const move = (
    source: IKanbanItem[],
    destination: IKanbanItem[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ) => {
    const result: { [key: string]: IKanbanItem[] } = {}

    // clona array itens de onde o card foi selecionado
    const sourceClone = [...source]
    // clona array itens para onde o card foi movido
    const destClone = [...destination]
    // retira o card movido do array de origem
    const [removed] = sourceClone.splice(droppableSource.index, 1)
    // insere o card no array de destino
    destClone.splice(droppableDestination.index, 0, removed)

    // retorna o objeto com os 2 novos arrays de itens
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone
    return result
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    const sourceId = source.droppableId
    if (!destination) {
      return
    }

    const curRule = data[Number(source.droppableId)].moveable
    const destinationIndex = String(data[Number(destination.droppableId)]?.id)

    if (Array.isArray(curRule) && !curRule?.includes(destinationIndex)) {
      return
    }

    const destinationId = destination.droppableId
    if (sourceId === destinationId) {
      // se o id da coluna de origen === coluna de destino...
      //  reordena os cards na coluna
      const items = reorder(
        data[Number(sourceId)].items,
        source.index,
        destination.index
      )
      // salva novo estado
      const newState = {
        ...data,
        [sourceId]: { ...data[Number(sourceId)], items },
      }
      setData(newState)
    } else {
      // se o id da coluna de origen !== coluna de destino...
      // reordena os cards entre as colunas
      const results = move(
        data[Number(sourceId)].items,
        data[Number(destinationId)].items,
        source,
        destination
      )

      // chama função onDropCard para mudar o status do card no BD - ex em gestao kanban de gestao de usuarios
      if (onDropCard) {
        const userId = data[Number(sourceId)].items[source.index].id
        const columnID = Number(destination.droppableId)
        onDropCard(userId, columnID)
      }

      // salva novo estado
      const newState = {
        ...data,
        [sourceId]: { ...data[Number(sourceId)], items: results[sourceId] },
        [destinationId]: {
          ...data[Number(destinationId)],
          items: results[destinationId],
        },
      }
      setData(newState)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <KanbanContainer>
        {Object.entries(data).map(
          ([
            columnId,
            { items, name, icon, color, moveable = true, itemData },
          ]) => {
            return (
              <Droppable droppableId={columnId} key={`column-${columnId}`}>
                {(provided, _snapshot) => {
                  return (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <KanbanColumn column={columnId}>
                        <span className="columnName">
                          {withoutIcon ? (
                            ''
                          ) : (
                            <img
                              src={icons[icon]}
                              alt="column icon"
                              width={16}
                            />
                          )}
                          {name}: {items.length}
                        </span>
                        {items.map((item, index) => {
                          return (
                            <Draggable
                              draggableId={String(item.id)}
                              index={index}
                              key={item.id}
                            >
                              {(providedDrag, snapshotDrag) => {
                                return !moveable ? (
                                  // COLUNAS BLOQUEADAS
                                  <div ref={providedDrag.innerRef}>
                                    <KanbanCard
                                      isClock={item?.isClock}
                                      color={color}
                                      column={columnId}
                                      values={item}
                                      data={itemData}
                                      document={item.document}
                                      onChatClick={onChatClick}
                                      noChatIcon={withoutChatIcon}
                                      onClick={() => {
                                        if (onCardClick) {
                                          onCardClick(item)
                                        }
                                      }}
                                      email={item.email}
                                      isDragging={snapshotDrag.isDragging}
                                      name={item.name}
                                    />
                                  </div>
                                ) : (
                                  // COLUNAS DESBLOQUEADAS
                                  <div
                                    ref={providedDrag.innerRef}
                                    {...providedDrag.draggableProps}
                                    {...providedDrag.dragHandleProps}
                                  >
                                    <KanbanCard
                                      isClock={item?.isClock}
                                      values={item}
                                      data={itemData}
                                      color={color}
                                      column={columnId}
                                      document={item.document}
                                      email={item.email}
                                      isDragging={snapshotDrag.isDragging}
                                      noChatIcon={withoutChatIcon}
                                      onChatClick={() => {
                                        onChatClick && onChatClick(item)
                                      }}
                                      onClick={() => {
                                        if (onCardClick) {
                                          onCardClick(item)
                                        }
                                      }}
                                      name={item.name}
                                    />
                                  </div>
                                )
                              }}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </KanbanColumn>
                    </div>
                  )
                }}
              </Droppable>
            )
          }
        )}
      </KanbanContainer>
    </DragDropContext>
  )
}
