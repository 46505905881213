import { Row } from 'styles'
import {
  Select,
  PageTitle,
  PagePathing,
  Input,
  ButtonNext,
  InsuranceCard,
} from 'components'
import { Form } from '@unform/web'
import { useContext, useEffect, useRef, useState } from 'react'
import { SapiensContext } from 'context/sapiens'
import * as Yup from 'yup'
import { icons } from 'helper'
import { numberToMoney } from 'helper/utils'
import { FormDiv, CardsDiv, Span, Card, ScrollDiv } from './style'

const schemaContract = Yup.object().shape({
  coverageType: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required('campo obrigatório'),
  insuranceType: Yup.number().required(),
})

export default function Seguro({ handleNext, handleBack }: any) {
  const { contract, setContract, property } = useContext(SapiensContext)
  const [selected, setSelected] = useState('')
  const [allowNextPage, setAllowNextPage] = useState(false)

  const formRef = useRef<HTMLFormElement | any>(null)

  const handleChangePage = async () => {
    try {
      await schemaContract.validate(contract, { abortEarly: false })
      handleNext()
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: {
          [key: string]: { path: string; message: string }
        } = {}
        err.inner.forEach((error: any) => {
          validationErrors[error.path] = error.message
        })
        formRef.current.setErrors(validationErrors)
      } else {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    const validateInputs = async () => {
      try {
        await schemaContract.validate(contract, { abortEarly: false })
        setAllowNextPage(true)
      } catch (err) {
        console.log(err)
        setAllowNextPage(false)
      }
    }
    validateInputs()
  }, [contract])

  const choseOption = (id: string) => {
    if (selected === id) {
      setSelected('')
      setContract({ ...contract, insuranceType: 0 })
    } else {
      setSelected(id)
      const insuranceType = id === 'basic' ? 0 : id === 'complete' ? 1 : 2
      setContract({
        ...contract,
        insuranceType,
      })
    }
  }

  useEffect(() => {
    const coverageValue =
      (property.rentValue + property.condominiumValue + property.iptuValue) *
      200
    setContract({
      ...contract,
      coverageAmount: coverageValue,
    })
    const validateInputs = async () => {
      try {
        await schemaContract.validate(contract, { abortEarly: false })
        setAllowNextPage(true)
      } catch (err) {
        console.log(err)
        setAllowNextPage(false)
      }
    }
    validateInputs()
  }, [])

  return (
    <>
      <PagePathing
        withoutMarginLeft
      >{`Gestão de inquilinos > Novo inquilino > Cadastrar imóvel residencial > Seguro`}</PagePathing>

      <ScrollDiv>
        <FormDiv>
          <Form onSubmit={() => null} ref={formRef}>
            <PageTitle withoutMarginLeft>
              <img
                className="titleIcon"
                src={icons.shieldOutlined}
                alt="first title icon"
              />
              Contratação de seguro obrigatório
            </PageTitle>
            <Row style={{ alignItems: 'flex-start' }} gap={15}>
              <Input
                type="text"
                disabled
                name="type"
                placeholder={property.type.label}
                value={property.type.label}
              />
              <Select
                name="coverageType"
                placeholder="Tipo de cobertura"
                options={[{ label: '100%', value: 1 }]}
                onChange={(e: any) =>
                  setContract({
                    ...contract,
                    coverageType: e,
                  })
                }
              />
              <Input
                type="text"
                disabled
                name="coverageAmount"
                placeholder="Valor da cobertura"
                value={`R$ ${numberToMoney(contract.coverageAmount)}`}
              />
            </Row>
            <PageTitle withoutMarginLeft>
              <img
                className="titleIcon"
                src={icons.shieldOutlined}
                alt="first title icon"
              />
              Modalidade da cobertura
            </PageTitle>
            <Row>
              <CardsDiv>
                <div>
                  <InsuranceCard
                    green={selected === 'basic'}
                    icon="homeSolid"
                    id="basic"
                    onSelect={() => choseOption('basic')}
                  >
                    Básico
                  </InsuranceCard>
                  <Span>
                    <img
                      className="titleIcon"
                      src={
                        selected === 'basic'
                          ? icons.greenCheckOutlined
                          : icons.greyCheckOutlined
                      }
                      alt="first title icon"
                    />
                    <p>Raios</p>
                  </Span>
                  <Span>
                    <img
                      className="titleIcon"
                      src={
                        selected === 'basic'
                          ? icons.greenCheckOutlined
                          : icons.greyCheckOutlined
                      }
                      alt="first title icon"
                    />
                    <p>Explosão</p>
                  </Span>
                  <Span>
                    <img
                      className="titleIcon"
                      src={
                        selected === 'basic'
                          ? icons.greenCheckOutlined
                          : icons.greyCheckOutlined
                      }
                      alt="first title icon"
                    />
                    <p>Incêndio</p>
                  </Span>
                  <Span>
                    <img
                      className="titleIcon"
                      src={
                        selected === 'basic'
                          ? icons.greenCheckOutlined
                          : icons.greyCheckOutlined
                      }
                      alt="first title icon"
                    />
                    <p>Alagamento</p>
                  </Span>
                  {selected === 'basic' ? (
                    <Card>
                      <span className="title">R$ 100,00</span>
                      <span>mensal</span>
                    </Card>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <InsuranceCard
                    green={selected === 'complete'}
                    icon="buildingSolid"
                    id="complete"
                    onSelect={() => choseOption('complete')}
                  >
                    Completo
                  </InsuranceCard>
                  <Span>
                    <img
                      className="titleIcon"
                      src={
                        selected === 'complete'
                          ? icons.greenCheckOutlined
                          : icons.greyCheckOutlined
                      }
                      alt="first title icon"
                    />
                    <p>Básico</p>
                  </Span>
                  <Span>
                    <img
                      className="titleIcon"
                      src={
                        selected === 'complete'
                          ? icons.greenCheckOutlined
                          : icons.greyCheckOutlined
                      }
                      alt="first title icon"
                    />
                    <p>Danos Elétricos</p>
                  </Span>
                  <Span>
                    <img
                      className="titleIcon"
                      src={
                        selected === 'complete'
                          ? icons.greenCheckOutlined
                          : icons.greyCheckOutlined
                      }
                      alt="first title icon"
                    />
                    <p>Danos hidráulicos</p>
                  </Span>
                  <Span>
                    <img
                      className="titleIcon"
                      src={
                        selected === 'complete'
                          ? icons.greenCheckOutlined
                          : icons.greyCheckOutlined
                      }
                      alt="first title icon"
                    />
                    <p>Encanador</p>
                  </Span>
                  {selected === 'complete' ? (
                    <Card>
                      <span className="title">R$ 100,00</span>
                      <span>mensal</span>
                    </Card>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <InsuranceCard
                    green={selected === 'premium'}
                    icon="hatSolid"
                    id="premium"
                    onSelect={() => choseOption('premium')}
                  >
                    Premium
                  </InsuranceCard>
                  <Span>
                    <img
                      className="titleIcon"
                      src={
                        selected === 'premium'
                          ? icons.greenCheckOutlined
                          : icons.greyCheckOutlined
                      }
                      alt="first title icon"
                    />
                    <p>Básico</p>
                  </Span>
                  <Span>
                    <img
                      className="titleIcon"
                      src={
                        selected === 'premium'
                          ? icons.greenCheckOutlined
                          : icons.greyCheckOutlined
                      }
                      alt="first title icon"
                    />
                    <p>Completo</p>
                  </Span>
                  <Span>
                    <img
                      className="titleIcon"
                      src={
                        selected === 'premium'
                          ? icons.greenCheckOutlined
                          : icons.greyCheckOutlined
                      }
                      alt="first title icon"
                    />
                    <p>Roubo e furto qualificado</p>
                  </Span>
                  <Span>
                    <img
                      className="titleIcon"
                      src={
                        selected === 'premium'
                          ? icons.greenCheckOutlined
                          : icons.greyCheckOutlined
                      }
                      alt="first title icon"
                    />
                    <p>Auxílio Aluguel</p>
                  </Span>
                  {selected === 'premium' ? (
                    <Card>
                      <span className="title">R$ 100,00</span>
                      <span>mensal</span>
                    </Card>
                  ) : (
                    ''
                  )}
                </div>
              </CardsDiv>
            </Row>
          </Form>
        </FormDiv>
      </ScrollDiv>

      <ButtonNext
        onClick={handleChangePage}
        alsoBack
        onClickBack={handleBack}
        disabled={!allowNextPage}
      />
    </>
  )
}
