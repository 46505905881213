import styled from 'styled-components'

interface IButtonProps {
  disabled?: boolean
  static?: boolean
  large?: boolean
  noSpaceBetween?: boolean
  notFixed?:boolean
  isExpensible?:boolean
  marginBottom?: string
}

export const ButtonNext = styled.button`
  display: flex;
  color: #070707;
  border: 0;
  background: transparent;
  /* background-color: blue; */
  justify-content: ${(props: IButtonProps) => {
    if (props.noSpaceBetween) return ''
    return 'space-between'
  }};
  align-items: center;
  height: 50px;
  max-height: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  position: ${(props: IButtonProps) => {
    if (props.static) return 'static'
    return ''
  }};
  bottom: 72px;

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43px;
    height: 43px;
    background-color: ${(props: IButtonProps) => {
      if (props.disabled) return '#ebeae8'
      return '#0bf569'
    }};
    border-radius: 50px;
    margin: 20px;
  }
`

export const ButtonsContainer = styled.div`
  /* position: fixed;
  bottom: 40px; */
  /* background-color: white; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .button-back {
    transform: rotate(180deg);
  }

  .circle-new {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43px;
    height: 43px;
    background-color: #0bf569;
    border-radius: 50px;
    margin-right: 15px;
  }
`
export const Background = styled.div`
  width: 90%;
  background-color: ${(props: IButtonProps) => {
    if (props.isExpensible) return 'transparent'
    return 'white'
  }};
  /* border: 1px solid red; */
  position: ${(props: IButtonProps) => {
    if (props.notFixed) return 'static'
    return 'fixed'
  }};
  bottom: ${(props: IButtonProps) => {
    if (props.marginBottom) return props.marginBottom
    return '40px'
  }};
  /* height: 10px; */
`
