import { BugCard } from 'components'
import { SapiensContext } from 'context/sapiens'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'
import { CardsContainer } from 'styles'
import Tooltip from 'react-tooltip'
import { Container, DivRow } from './style'

interface SelectList {
  label: string
  color: string
}

interface Card {
  createdAt: string
  id: string
  message: string
  subject: {
    label: string
    color: string
  }
  type: number
  updatedAt: string
}

// const selectList = [
//   {
//     label: 'Aluguel-verde',
//     value: 'Aluguel-verde',
//     color: ' #0BF569',
//   },
// ]

export default () => {
  const { newCard, setNewCard } = useContext(SapiensContext)
  const [selectList, setSelectetList] = useState<any>([
    {
      label: 'Aluguel-verde',
      value: 'Aluguel-verde',
      color: ' #0BF569',
    },
  ])
  const api = useFetch('person')

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const list = await api.fetch('get', {}, `person/report/list-topics`)
        if (list.fail) throw new Error()
        const { data } = list
        const select = data.map((item: SelectList) => {
          return {
            label: item.label,
            value: item.label,
            color: item.color,
          }
        })
        setSelectetList(select)
      } catch (e) {
        console.error(e)
      }
    }
    fetchCards()
  }, [newCard])

  return (
    <Container>
      <DivRow width={350}>
        <p className="bold-text">Relatar Problemas</p>
        <button
          type="button"
          className="add"
          data-for="add"
          data-tip="Adicione para relatar <br /> problemas à Sapiensbank"
          onClick={() => setNewCard(true)}
        >
          Adicionar +
        </button>
        <Tooltip place="bottom" multiline id="add" effect="solid" />
      </DivRow>
      <CardsContainer
        overflow
        scrollTrack="#f9f9f9"
        withoutMarginLeft
        width="800px"
        height="800px"
      >
        {newCard && (
          <BugCard
            isNewCard
            selectList={selectList}
            // onSend
            type="bug"
          />
        )}
      </CardsContainer>
    </Container>
  )
}
