import styled from 'styled-components'

export default styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #8e8080;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 0;
  height: 50px;
  width: 315px;
  max-width: 315px;
  padding: 20px 17px 20px 20px;
  box-sizing: border-box;
  border: 1px solid #9c948c;
  border-radius: 5px;
  background: #f9f9f9;
  &:hover {
    background: #8f888133;
  }
  &:disabled {
    &::placeholder {
      color: #fff;
    }
    background: lightGray;
    border: 0;
  }

  img {
    position: relative;
  }

  input {
    display: none;
  }
`
