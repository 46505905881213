import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import uuid from 'react-uuid'
import {
  PagePathing,
  PageTitle,
  HistoryCard,
  Checkbox,
  ButtonNext,
} from 'components'
import {
  Wrapper,
  CardsWrap,
  ReactCalendar,
  Subtitle,
} from './style'
import { toCapitalizeCase, numberToMoney } from 'helper/utils'
import Detalhes from './DetalhesExtrato'
import { Form } from '@unform/web'
import { useFetch } from 'hooks';
import { IObject } from 'interfaces'

const filterOptions = [
  { label: 'Fee', value: 'fee' },
  { label: 'TAC', value: 'tac' },
  { label: 'Comissões de venda', value: 'comission' },
]


interface IComponent {
  extendedMenu?: any
  setExtendedMenu: Dispatch<SetStateAction<ReactElement | boolean>>
  setPage: Dispatch<SetStateAction<String>>
}

export default function Extrato(props: IComponent) {
  const { setExtendedMenu, setPage, extendedMenu } = props
  const [selected, setSelected] = useState<number>(-1)
  const [data, setData] = useState<any>([]);
  const api = useFetch('transaction');
  const [filterList, setFilterList] = useState<IObject>({})

  const update = (req: any) => {
    req.then((res: any) => {
      setData(res.data.map((item: any) => ({
        ...item,
        icon: item.translatedIcon,
        value: item.rawValue,
      })));
    })
    .catch((error: any) => {
      console.error(error);
    });
  }

  useEffect(() => {
    if (!extendedMenu) {
      return setSelected(-1)
    }
    return;
  }, [extendedMenu])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (Object.keys(filterList).length === 0) return;
      const filter = btoa(JSON.stringify(filterList));
      const url = `transaction/${filter}`;
      update(api.fetch('get', {}, url))
    }, 500);

    return () => {
      clearTimeout(timer);
    }
  }, [filterList]);

  useEffect(() => {
    update(api.fetch('get'))
  }, []);


  const handleClick = (index: number) => {
    setSelected(index)
    setExtendedMenu(<Detalhes data={data[index]} />)
  }

  const formRef = useRef<HTMLFormElement | any>(null)

  return (
    <Wrapper>
      <div style={{ flexDirection: 'row' }}>
        <PagePathing withoutMarginLeft>{`Dashboard > Extrato`}</PagePathing>
        <PageTitle withoutMarginLeft>Extrato</PageTitle>
        <ReactCalendar
          locale="pt-br"
          selectRange
          allowPartialRange
          prev2Label={null}
          next2Label={null}
          navigationLabel={({ label }) => toCapitalizeCase(label.split(' ')[0])}
          onChange={(value: any) => {
            let [from, to] = value;
            [from] = new Date(from).toISOString().split('T');
            to = to || `${from}T23:59:59.000Z`;
            setFilterList((list) => ({ ...list, from, to }));
          }}
        />
        {
          (selected !== -1) && (
            <HistoryCard
              signal={data[selected].signal}
              label={data[selected].label}
              date={new Date(data[selected].registredAt)}
              value={`R$ ${numberToMoney(data[selected].value)}`}
              icon={data[selected].icon}
            />
          )
        }
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '90%', marginTop: '50px' }}>
        <div style={{ flexDirection: 'row' }}>
          <Subtitle>Qual tipo de saldo você quer filtrar?</Subtitle>
        </div>
        <Form onSubmit={() => {}} ref={formRef}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '70%',
            }}
          >
            {filterOptions.map((item: any) => {
              return (
                <Checkbox
                  label={item.label}
                  onChange={(e: any) => {
                    setFilterList((list) => ({
                      ...list,
                      filter: {
                        ...list.filter,
                        [item.value]: e.target.checked,
                      },
                    }))
                  }}
                  value={item.label}
                  name={item.label}
                  green
                  extractFilter
                />
              )
            })}
          </div>
        </Form>

        <CardsWrap>
          {data.map((item: any, i: number) => (
            <HistoryCard
              key={uuid()}
              signal={item.signal}
              label={item.label}
              date={new Date(item.registredAt)}
              value={`R$ ${numberToMoney(item.value)}`}
              icon={item.icon}
              onClick={() => {
                handleClick(i)
              }}
            />
          ))}
        </CardsWrap>
      </div>
      <ButtonNext justBack alsoBack children="VOLTAR" marginBottom="80px" onClickBack={() => setPage('home')} isExpensible/>
    </Wrapper>
  )
}
