import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

export const CardsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const FormDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 50px;
  padding-bottom: 60px;
`
export const Span = styled.div`
  display: flex;
  min-width: 217px;
  color: #070707;
  max-height: 26px;
  text-align: center;
  align-items: center;
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.2px;

  img {
    margin-right: 14px;
    position: relative;
    left: 0;
    width: 16px;
  }
`

interface IStyleProps {
  theme: ITheme
  green?: boolean
  secondary?: boolean
  disabled?: boolean
}

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  height: 72px;
  width: 175px;

  margin-top: 20px;

  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #00000040;

  background: ${(props: IStyleProps) => {
    if (props.green) return `${props.theme.card.backgroundColor.green}`
    if (props.secondary) return `${props.theme.card.backgroundColor.secondary}`
    return `${props.theme.card.backgroundColor.primary}`
  }};

  .title {
    color: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.fontColor.disabled}`
      if (props.secondary) return `${props.theme.card.fontColor.secondary}`
      return `${props.theme.card.fontColor.primary}`
    }};

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;

    margin: 10px 0 0 0;

    letter-spacing: 0.2px;
  }

  .iconClickable {
    filter: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.icon.disabled}`
      if (props.secondary) return `${props.theme.card.icon.white}`
      if (props.green) return `${props.theme.card.icon.green}`
      return `${props.theme.card.icon.green}`
    }};
    max-width: 24px;
    height: 24px;
    margin: 0 0 12px 25px;
  }

  .selectIcon {
    max-width: 15.79px;
    max-height: 15.79px;
    margin: 0 0 50px 150px;
  }

  span {
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.2px;
  }
`
export const ScrollDiv = styled.div`
  overflow: scroll;
  height: 100%;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`
