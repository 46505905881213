import { ResumeInputCard, PageTitle } from 'components'
import { maskDocument, timestampToDate } from 'helper/utils'
import { Content } from './style'
import uuid from 'react-uuid'

const mock = [
  { icon: "clientOutlined", label: 'Felipe inquilino da silva', value: 'Nome' },
  { icon: "clientOutlined", label: maskDocument('08048945801'), value: 'CPF' },
  { icon: "mailOutlined", label: 'Inquilino@gmail.com', value: 'E-mail' },
  { icon: "phoneOutlined", label: '+55 (41) 9 8758-8569', value: 'Telefone' },
  { icon: "calendarOutlined", label: timestampToDate(new Date()), value: 'Data de nascimento' },
]

export default () => {
  return (
    <div className="sub-container">
      <PageTitle icon="clientOutlined">
        Dados do inquilino
      </PageTitle>
      <Content>
        {
          mock.map((item) => (
            <ResumeInputCard
              key={uuid()}
              clickable={false}
              names="button"
              icon={item.icon}
              placeholder={item.label}
            >
              {item.value}
            </ResumeInputCard>
          ))
        }
      </Content>
    </div>
  )
}
