import {
  AccountStatus,
  ButtonNext,
  InsuranceCard,
  PagePathing,
  ResumeInputCard,
} from 'components'
import { SapiensContext } from 'context/sapiens'
import { clearString, timestampToDate } from 'helper/utils'
import { getAddressByCEP, useFetch } from 'hooks'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CardsContainer, Row } from 'styles'
import * as Yup from 'yup'
import { ContentContainer, Title } from './style'

const schemaAlteredUser = Yup.object().shape({
  rg: Yup.string().min(8).required('campo obrigatório: rg'),
  document: Yup.string().min(11).max(11).required('campo obrigatório: cpf'),
  email: Yup.string().required('campo obrigatório: email'),
  cellphone: Yup.string()
    .min(11)
    .max(16)
    .required('campo obrigatório: telefone'),

  level: Yup.array().min(1).required('campo obrigatório: algum level'),
  address: Yup.object().shape({
    neighborhood: Yup.string().required('campo obrigatório: bairro'),
    address: Yup.string().required('campo obrigatório: rua'),
    addressNumber: Yup.string().required('campo obrigatório: numero'),
    complement: Yup.string().required('campo obrigatório: complemento'),
    state: Yup.string().required('campo obrigatório: state'),
    city: Yup.string().required('campo obrigatório: cidade'),
    zipcode: Yup.string().required('campo obrigatório: cep'),
  }),
})

export default () => {
  const { setTitle, userData } = useContext(SapiensContext)
  const [isPosting, setIsPosting] = useState(false)
  const navigate = useNavigate()
  const [isValidated, setIsValidated] = useState(false)
  const [alteredUser, setAlteredUser] = useState(userData)
  const api = useFetch('person')

  useEffect(() => {
    const validateInputs = async () => {
      try {
        await schemaAlteredUser.validate(alteredUser, { abortEarly: false })
        setIsValidated(true)
      } catch (err) {
        console.log(err)
        setIsValidated(false)
      }
    }
    validateInputs()
  }, [alteredUser])

  const choseOption = (id: number) => {
    setIsPosting(true)
    if (!alteredUser.level.includes(id)) {
      const newLevel = alteredUser.level
      newLevel.push(id)
      setAlteredUser((cur: any) => {
        return { ...cur, level: newLevel }
      })
    } else {
      const newLevel = alteredUser.level
      newLevel.splice(newLevel.indexOf(id), 1)
      setAlteredUser((cur: any) => {
        return { ...cur, level: newLevel }
      })
    }
  }

  const handleCep = async (e: any) => {
    const cepValue = e.replace(/[^0-9]/g, '')
    setAlteredUser((cur: any) => ({
      ...cur,
      address: {
        ...alteredUser.address,
        zipcode: cepValue,
      },
    }))

    if (cepValue.length === 8) {
      const r = await getAddressByCEP(cepValue)
      setAlteredUser((cur: any) => ({
        ...cur,
        address: {
          ...alteredUser.address,
          address: r.data.address,
          city: r.data.city,
          neighborhood: r.data.district,
          state: r.data.state,
          zipcode: cepValue,
        },
      }))
    }
  }

  const handleChangePage = async () => {
    const body = {
      address: {
        address: alteredUser.address.address,
        addressNumber: alteredUser.address.addressNumber,
        zipcode: String(alteredUser.address.zipcode),
        complement: alteredUser.address.complement,
        state: alteredUser.address.state,
        city: alteredUser.address.city,
        neighborhood: alteredUser.address.neighborhood,
      },
      email: alteredUser.email,
      phoneNumber: clearString(alteredUser.cellphone),
      level: alteredUser.level,
    }
    try {
      const response = await api.fetch('put', body, `person/${userData.id}`)
      if (!response.fail) {
        setTitle('Informações atualizadas')
        navigate('/confirm')
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ContentContainer>
      <PagePathing withoutMarginLeft>
        {'Gestão de usuários > Informações do usuário'}
      </PagePathing>
      <Title>{alteredUser.name}</Title>
      <AccountStatus
        color={alteredUser.status.color}
        status={alteredUser.status.label}
      />
      <CardsContainer withoutMarginLeft width="100%" overflow height="70%">
        <Row gap={20}>
          <ResumeInputCard
            clickable={false}
            names="cep"
            small
            icon="calendarOutlined"
            placeholder={timestampToDate(new Date(alteredUser.createdAt))}
          >
            Data de criação
          </ResumeInputCard>
          <ResumeInputCard
            isMask="000.000.000-00"
            clickable={false}
            names="cpf"
            icon="clientOutlined"
            placeholder={alteredUser.document}
          >
            CPF
          </ResumeInputCard>
          <ResumeInputCard
            isMask="00.000.000"
            clickable={false}
            names="rg"
            icon="clientOutlined"
            placeholder={alteredUser.rg}
          >
            RG
          </ResumeInputCard>
        </Row>
        <Row gap={20}>
          <ResumeInputCard
            handleChangeInput={(e: any) => {
              setIsPosting(true)
              handleCep(e)
            }}
            isMask="00000-000"
            names="cep"
            small
            icon="locationOutlined"
            placeholder={alteredUser.address.zipcode}
          >
            CEP
          </ResumeInputCard>
          <ResumeInputCard
            handleChangeInput={(e: any) => {
              setIsPosting(true)
              setAlteredUser((cur: any) => ({
                ...cur,
                address: {
                  ...cur.address,
                  addressNumber: e,
                },
              }))
            }}
            names="addressNumber"
            small
            icon="locationOutlined"
            placeholder={alteredUser.address.addressNumber}
          >
            Número
          </ResumeInputCard>
          <ResumeInputCard
            handleChangeInput={(e: any) => {
              setIsPosting(true)
              setAlteredUser((cur: any) => ({
                ...cur,
                address: {
                  ...cur.address,
                  complement: e,
                },
              }))
            }}
            names="complement"
            small
            icon="locationOutlined"
            placeholder={alteredUser.address.complement}
          >
            Complemento
          </ResumeInputCard>
          <ResumeInputCard
            handleChangeInput={(e: any) => {
              setIsPosting(true)
              setAlteredUser((cur: any) => ({
                ...cur,
                address: {
                  ...cur.address,
                  district: e,
                },
              }))
            }}
            names="district"
            small
            icon="locationOutlined"
            placeholder={alteredUser.address.neighborhood}
          >
            Bairro
          </ResumeInputCard>
        </Row>
        <Row gap={20}>
          <ResumeInputCard
            handleChangeInput={(e: any) => {
              setIsPosting(true)
              setAlteredUser((cur: any) => ({
                ...cur,
                address: {
                  ...cur.address,
                  address: e,
                },
              }))
            }}
            names="address"
            icon="locationOutlined"
            placeholder={alteredUser.address.address}
          >
            Endereço
          </ResumeInputCard>
          <ResumeInputCard
            clickable={false}
            names="state"
            small
            icon="locationOutlined"
            placeholder={alteredUser.address.state}
          >
            UF
          </ResumeInputCard>
          <ResumeInputCard
            clickable={false}
            names="city"
            small
            icon="locationOutlined"
            placeholder={alteredUser.address.city}
          >
            Cidade
          </ResumeInputCard>
        </Row>
        <Row gap={20} width="100%">
          <ResumeInputCard
            isMask="(00) 0 0000-0000"
            handleChangeInput={(e: any) => {
              setIsPosting(true)
              setAlteredUser((cur: any) => ({
                ...cur,
                cellphone: e,
              }))
            }}
            names="address"
            icon="phoneOutlined"
            placeholder={alteredUser.cellphone}
          >
            Whatsapp
          </ResumeInputCard>
          <ResumeInputCard
            handleChangeInput={(e: any) => {
              setIsPosting(true)
              setAlteredUser((cur: any) => ({
                ...cur,
                email: e,
              }))
            }}
            names="email"
            icon="mailOutlined"
            placeholder={alteredUser.email}
          >
            Email
          </ResumeInputCard>
        </Row>
        <Row width="100%">
          <Title>Nível de acesso</Title>
        </Row>
        <Row gap={20}>
          <InsuranceCard
            green={alteredUser.level.includes(0)}
            icon="contractOutlined"
            id="0"
            onSelect={() => choseOption(0)}
          >
            Gestão de garantias
          </InsuranceCard>
          <InsuranceCard
            green={alteredUser.level.includes(1)}
            icon="houseGreenOutlined"
            id="1"
            onSelect={() => choseOption(1)}
          >
            Gestão de imóveis
          </InsuranceCard>
          <InsuranceCard
            green={alteredUser.level.includes(2)}
            icon="buildingSolid"
            id="2"
            onSelect={() => choseOption(2)}
          >
            Gestão de alugueis
          </InsuranceCard>
          <InsuranceCard
            green={alteredUser.level.includes(4)}
            icon="teamOutlined"
            id="4"
            onSelect={() => choseOption(4)}
          >
            Gestão de usuários
          </InsuranceCard>
          <InsuranceCard
            green={alteredUser.level.includes(3)}
            icon="stonksOutlined"
            id="3"
            onSelect={() => choseOption(3)}
          >
            Gestão financeira
          </InsuranceCard>
        </Row>
      </CardsContainer>
      <ButtonNext
        noSpaceBetween
        large
        static
        onClick={handleChangePage}
        disabled={!isPosting || !isValidated}
      >
        CONCLUIR
      </ButtonNext>
    </ContentContainer>
  )
}
