import styled from 'styled-components'

interface IStyleProps {
  error?: string | undefined
  noBorder?: boolean
}

export const CodeInput = styled.input`
  letter-spacing: 15px;
  font-family: Monospace;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 78px;
  width: 240px;
  text-transform: uppercase;
  color: ${(props: IStyleProps) => {
    if (props.error) return '#f60665'
    return 'black'
  }};

  background: ${(props: IStyleProps) => {
    if (props.noBorder) return `transparent`
    return `repeating-linear-gradient(
      to right,
      black 0 1ch,
      transparent 1ch calc(1ch + 15px)
    )
    bottom/100% 2px content-box no-repeat;
    `
  }};

  &:focus {
    outline: none;
  }
`

export const CodeInputError = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  padding: 5px 30px;
  width: 215px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #f60665;
`
