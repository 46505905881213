import styled from 'styled-components'

interface IStyleProps {
  withoutMarginLeft?: boolean
  marginBottom?: boolean
}

export default styled.h2`
  display: flex;

  height: 23px;
  font-size: 20px;
  margin-bottom: ${(props: IStyleProps) => {
    if (props.marginBottom) return `30px`
    return '20px'
  }};
  margin-left: ${(props: IStyleProps) => {
    if (props.withoutMarginLeft) return `0`
    return '30px'
  }};

  img {
    margin-right: 20px;
  }
`
