import { IIcons } from 'helper/icons'
import { createContext, Dispatch, SetStateAction, useState } from 'react'
import { ITheme, LightTheme, DarkTheme } from 'styles/Themes'

interface ICardInfo {
  label: string
  variation?: string
  value: string
  backgroundColor: string
  size: string
  icon: keyof IIcons
}

interface ICardsInfo {
  clientNumber: ICardInfo | Record<string, never>
  money: ICardInfo | Record<string, never>
  custody: ICardInfo | Record<string, never>
}
interface ISapiensContextType {
  cardsInfo: ICardsInfo
  setCardsInfo: Dispatch<SetStateAction<ICardsInfo>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  extended: boolean
  setExtended: Dispatch<SetStateAction<boolean>>
  searchParam: string
  setSearchParam: Dispatch<SetStateAction<string>>
  theme: ITheme
  toggleDarkMode: VoidFunction
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  preview?: string
  preview64?: string
  setPreview: Dispatch<SetStateAction<string>>
  setPreview64: Dispatch<SetStateAction<string>>
  showModalNewClient: boolean
  setShowModalNewClient: Dispatch<SetStateAction<boolean>>
  selected?: any
  setSelected?: any
  captationData?: any
  setCaptationData?: any
  pin: string
  setPin: any
  title: string
  setTitle: any
  userInfo: any
  setUserInfo: any
  stateList: any
  propertyData: any
  setPropertyData: any
  ownerData: any
  setOwnerData: any
  contract: any
  setContract: any
  residents: any
  setResidents: any
  property: any
  setProperty: any
  newCard: any
  setNewCard: any
  userData: any
  setUserData: any
}

export const SapiensContext = createContext<ISapiensContextType>(null!)

export function SapiensProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(false)

  // State list
  const stateList = [
    { label: 'AC', value: 'AC' },
    { label: 'AL', value: 'AL' },
    { label: 'AM', value: 'AM' },
    { label: 'BA', value: 'BA' },
    { label: 'CE', value: 'CE' },
    { label: 'DF', value: 'DF' },
    { label: 'ES', value: 'ES' },
    { label: 'GO', value: 'GO' },
    { label: 'MA', value: 'MA' },
    { label: 'MT', value: 'MT' },
    { label: 'MS', value: 'MS' },
    { label: 'MG', value: 'MG' },
    { label: 'PA', value: 'PA' },
    { label: 'PB', value: 'PB' },
    { label: 'PR', value: 'PR' },
    { label: 'PE', value: 'PE' },
    { label: 'PI', value: 'PI' },
    { label: 'RJ', value: 'RJ' },
    { label: 'RN', value: 'RN' },
    { label: 'RS', value: 'RS' },
    { label: 'RO', value: 'RO' },
    { label: 'RR', value: 'RR' },
    { label: 'SC', value: 'SC' },
    { label: 'SP', value: 'SP' },
    { label: 'SE', value: 'SE' },
    { label: 'TO', value: 'TO' },
  ]

  const rawContract = {
    contractType: '',
    paymentType: '',
    installmentsCard: 0,
    insuranceType: '',
    coverageAmount: 0,
    coverageType: '',
    residentsNumber: 0,
  }

  const rawProperty = {
    type: '',
    address: '',
    zipcode: '',
    state: '',
    city: '',
    country: 'Brasil',
    complement: '',
    neighborhood: '',
    iptuValue: 0,
    condominiumValue: 0,
    rentValue: 0,
  }

  const rawResident = {
    name: '',
    documentNumber: '',
    documentType: 'CPF',
    birthday: '',
    mainEmail: '',
    mainPhone: '',
    isCoresponsible: false,
  }

  // Gestao de contratos
  const [contract, setContract] = useState(rawContract)
  const [property, setProperty] = useState(rawProperty)
  const [residents, setResidents] = useState([rawResident])

  // Gestao de imoveis
  const [propertyData, setPropertyData] = useState(rawProperty)

  const [ownerData, setOwnerData] = useState({
    name: '',
    mainEmail: '',
    mainPhone: '',
    maritalStatus: '',
    documentType: 'CPF',
    documentNumber: '',
    birthday: '',
  })

  // Gestao de usuarios
  const [userInfo, setUserInfo] = useState({
    name: '',
    lastName: '',
    address: '',
    addressNumber: '',
    zipcode: '',
    complement: '',
    state: '',
    city: '',
    country: 'Brasil',
    neighborhood: '',
    rgNumber: '',
    documentNumber: '',
    email: '',
    phoneNumber: '',
    level: [],
  })

  // MODAL Cliente
  const [showModalNewClient, setShowModalNewClient] = useState(false)

  // CARDS Gestão Cliente
  const [cardsInfo, setCardsInfo] = useState<ICardsInfo>({
    clientNumber: {},
    money: {},
    custody: {},
  })

  // MENU
  const [extended, setExtended] = useState(false)

  // SUBMENU Conta Digital
  const [searchParam, setSearchParam] = useState('')

  // MODAL Cliente
  const [showModal, setShowModal] = useState(false)

  // NOVA CAPTAÇÃO Preview
  const [preview, setPreview] = useState('')
  const [preview64, setPreview64] = useState('')
  const [selected, setSelected] = useState({})
  const [captationData, setCaptationData] = useState({})

  // TEMAS
  const [theme, setTheme] = useState(LightTheme)

  // PIN
  const [pin, setPin] = useState('')

  // CONFIRM TITLE PAGE
  const [title, setTitle] = useState('')

  // NOVO CARD - BUG REPORT - HISTORY REPORT
  const [newCard, setNewCard] = useState(true)

  // Infos User para gestao de usuário
  const [userData, setUserData] = useState({
    id: '',
    status: {
      color: '',
      label: '',
    },
    name: '',
    createdAt: '',
    document: '',
    rg: '',
    address: {
      zipcode: '',
      number: '',
      complement: '',
      neighborhood: '', // neighborhood
      street: '',
      state: '',
      city: '',
    },
    cellphone: '',
    email: '',
    level: [0],
  })

  const [buffer, setBuffer] = useState({})

  const toggleDarkMode = () => {
    if (theme.dark) setTheme(LightTheme)
    else setTheme(DarkTheme)
  }

  const value = {
    buffer,
    setBuffer,
    userData,
    setUserData,
    newCard,
    setNewCard,
    cardsInfo,
    setCardsInfo,
    preview,
    setPreview,
    preview64,
    setPreview64,
    showModal,
    setShowModal,
    toggleDarkMode,
    theme,
    loading,
    setLoading,
    extended,
    setExtended,
    searchParam,
    setSearchParam,
    selected,
    setSelected,
    captationData,
    setCaptationData,
    pin,
    setPin,
    title,
    setTitle,
    userInfo,
    setUserInfo,
    setShowModalNewClient,
    showModalNewClient,
    stateList,
    propertyData,
    setPropertyData,
    ownerData,
    setOwnerData,
    contract,
    setContract,
    residents,
    setResidents,
    property,
    setProperty,
  }

  return (
    <SapiensContext.Provider value={value}>{children}</SapiensContext.Provider>
  )
}
