import { icons } from 'helper'
import { RefObject } from 'react'
import Tooltip from 'react-tooltip'
import KanbanCard from './style'

interface ICardProps {
  isClock?: string
  name: string
  data?: []
  values: { [key: string]: any }
  document?: string
  email?: string
  green?: boolean
  black?: boolean
  disabled?: boolean
  step?: { [key: string]: any }
  lastMessage?: string
  column: string | number
  color: string
  destinationList?: string[]
  noChatIcon?: boolean
  onClick?(_event?: object): any
  ref?: (
    _instance: HTMLDivElement | null
  ) => void | RefObject<HTMLDivElement> | null
  isDragging?: boolean
  trash?: boolean
  onClickTrash?: any
  onChatClick?: () => any
}

export default function KanbanCardComponent(props: ICardProps) {
  const {
    name,
    document,
    email,
    trash,
    onClickTrash,
    onChatClick,
    noChatIcon,
    lastMessage,
    data,
    values,
    isClock,
  } = props

  return (
    <KanbanCard {...props}>
      <div className="trashContainer">
        <span className="name">{name}</span>
        {trash && (
          <img
            className="icon trash"
            src={icons.trashOutlined}
            alt="card icon"
            onClick={() => onClickTrash({ document, name, email })}
          />
        )}
        {isClock && (
          <>
            <img
              className="icon trash"
              src={icons.infoOutlined}
              alt="card-icon"
              data-for="info-days"
              data-tip={isClock}
            />
            <Tooltip place="bottom" multiline id="info-days" effect="solid" />
          </>
        )}
      </div>
      {lastMessage ? (
        <span className="message">
          &quot;{lastMessage.substring(0, 85)}...&quot;
        </span>
      ) : (
        (data || []).map((item, i) => (
          <span className="cpf" key={`label_${i}`}>
            {item[0]}:{' '}
            <span>
              {String(values[item[1]]).length > 20
                ? `${String(values[item[1]]).substring(0, 20)}...`
                : String(values[item[1]])}
            </span>
          </span>
        ))
      )}
      <div className="iconsRow right">
        {noChatIcon || (
          <img
            onClick={onChatClick}
            className="icon"
            src={icons.chatSolid}
            alt="card icon"
          />
        )}
      </div>
    </KanbanCard>
  )
}
