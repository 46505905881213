import { ChangeEvent, useState, useEffect, useRef, useContext } from 'react'
import { IInputIcons } from 'helper/inputIcon'
import { useField } from '@unform/core'
import { SapiensContext } from 'context/sapiens'
import { CodeInputError, CodeInput } from './style'

interface IInputProps {
  noBorder?: boolean
  name: string
  type: string
  rounded?: boolean
  icon?: keyof IInputIcons
  placeholder?: string
  disabled?: boolean
  value?: string
  security?: boolean
  onChange?: (_e: ChangeEvent<HTMLInputElement>) => void
  initialError?: string
  maxLength: number
  required: boolean
  autoFocus?: boolean
  inputType?: string
}

export default function InputComponent({
  security,
  name,
  inputType = 'text',
  initialError,
  ...rest
}: IInputProps) {
  const { fieldName, registerField, defaultValue, error } = useField(name)
  const { setPin } = useContext(SapiensContext)
  const inputRef = useRef(null)
  const [erro, setErro] = useState(initialError)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [registerField, fieldName])

  const handleRemoveError = (code: number | string) => {
    security && setPin(code)
    setErro('')
  }

  useEffect(() => {
    if (error) {
      setErro(error)
    }
  }, [error])

  return (
    <>
      <CodeInput
        ref={inputRef}
        {...rest}
        error={erro}
        type={inputType}
        onChange={(e: any) => handleRemoveError(e.target.value)}
        defaultValue={defaultValue}
      />
      {erro && <CodeInputError>{erro}</CodeInputError>}
    </>
  )
}
