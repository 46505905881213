import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  theme: ITheme
  green?: boolean
  secondary?: boolean
  disabled?: boolean
}

export { default as Banner } from './Containers/BannerContainer'
export { default as CardsContainer } from './Containers/CardsContainer'
export { default as CenteredContainer } from './Containers/CenteredContainer'
export { default as Column } from './Containers/Column'
export { default as Content } from './Containers/Content'
export { default as InputContainer } from './Containers/InputContainer'
export { default as LoginContainer } from './Containers/LoginContainer'
export { default as Row } from './Containers/Row'
export { default as CardsDiv } from './Containers/CardsDiv'
export { default as GlobalStyle } from './globalStyle'
export { default as ErrorMessage } from './ErrorMessage'
export { default as Button } from './Buttons/Basic'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  height: 145px;
  width: 175px;

  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #00000040;

  background: ${(props: IStyleProps) => {
    if (props.green) return `${props.theme.card.backgroundColor.green}`
    if (props.secondary) return `${props.theme.card.backgroundColor.secondary}`
    return `${props.theme.card.backgroundColor.primary}`
  }};

  cursor: ${(props: IStyleProps) => {
    if (props.disabled) return ''
    return 'pointer'
  }};

  .title {
    color: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.fontColor.disabled}`
      if (props.secondary) return `${props.theme.card.fontColor.secondary}`
      return `${props.theme.card.fontColor.primary}`
    }};

    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    text-align: left;
    line-height: 12px;

    margin: 0 0 22px 25px;
  }

  .iconClickable {
    filter: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.icon.disabled}`
      if (props.secondary) return `${props.theme.card.icon.white}`
      if (props.green) return `${props.theme.card.icon.green}`
      return `${props.theme.card.icon.green}`
    }};
    max-width: 24px;
    height: 24px;
    margin: 0 0 12px 25px;
  }

  .selectIcon {
    width: 53px;
    margin-bottom: 24px;
    margin-left: -4px;
  }
`
