import { AccountStatus } from './style'

interface IAccountStatus {
  status: string
  type?: string
  color: string
}

export default function AccountStatusComponent(props: IAccountStatus) {
  const { status, type, color } = props
  return (
    <AccountStatus {...props}>
      {type && (
        <div>
          {
            {
              pf: 'Conta Pessoa Física',
              pj: 'Conta Pessoa Jurídica',
            }[type]
          }
        </div>
      )}
      <div className="row">
        <div className="ball-icon" style={{ background: color }} />
        {`${status}`}
      </div>
    </AccountStatus>
  )
}
