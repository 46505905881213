import { Row } from 'styles'
import {
  Select,
  PageTitle,
  PagePathing,
  Input,
  ButtonNext,
  MaskedInput,
} from 'components'
import { Form } from '@unform/web'
import { getAddressByCEP } from 'hooks'
import { useContext, useEffect, useRef, useState } from 'react'
import { SapiensContext } from 'context/sapiens'
import * as Yup from 'yup'
import { icons } from 'helper'
import { FormDiv, ScrollDiv } from './style'

const mockTiposImoveis = [
  {
    label: 'Casa',
    value: 'Casa',
  },
  {
    label: 'Apartamento',
    value: 'Apartamento',
  },
  {
    label: 'Sítio',
    value: 'sitio',
  },
]

const schemaPropertyData = Yup.object().shape({
  type: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required('campo obrigatório'),
  address: Yup.string().required('campo obrigatório: rua'),
  city: Yup.string().required('campo obrigatório: cidade'),
  neighborhood: Yup.string().required('campo obrigatório: bairro'),
  state: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required('campo obrigatório: state'),
  zipcode: Yup.string().min(8).max(9).required('campo obrigatório: cep'),
  addressNumber: Yup.string().required('campo obrigatório: numero'),
  complement: Yup.string().required('campo obrigatório: complemento'),
})

const schemaOwner = Yup.object().shape({
  name: Yup.string().required('campo obrigatório: nome'),
  documentNumber: Yup.string()
    .min(14)
    .max(14)
    .required('campo obrigatório: cpf'),
  birthday: Yup.string()
    .min(10)
    .required('campo obrigatório: data de nascimento'),
  mainEmail: Yup.string().required('campo obrigatório: email'),
  mainPhone: Yup.string()
    .min(15)
    .max(15)
    .required('campo obrigatório: telefone'),
  maritalStatus: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required('campo obrigatório: estado civil'),
})

export default function CadastroImovel({ handleNext }: any) {
  const { propertyData, setPropertyData, stateList, ownerData, setOwnerData } =
    useContext(SapiensContext)
  const [allowNextPage, setAllowNextPage] = useState(false)

  const formRef = useRef<HTMLFormElement | any>(null)

  useEffect(() => {
    const validateInputs = async () => {
      try {
        await schemaPropertyData.validate(propertyData, { abortEarly: false })
        await schemaOwner.validate(ownerData, { abortEarly: false })
        setAllowNextPage(true)
      } catch (err) {
        console.log(err)
        setAllowNextPage(false)
      }
    }
    validateInputs()
  }, [propertyData, ownerData])

  useEffect(() => {
    setPropertyData({
      type: '',
      zipcode: '',
      neighborhood: '',
      address: '',
      state: '',
      city: '',
      addressNumber: '',
      complement: '',
      code: '',
    })
    setOwnerData({
      name: '',
      documentNumber: '',
      birthday: '',
      mainEmail: '',
      mainPhone: '',
      maritalStatus: '',
    })
  }, [])

  const handleChangePage = async () => {
    try {
      console.log(propertyData, ownerData)
      await schemaPropertyData.validate(propertyData, { abortEarly: false })
      await schemaOwner.validate(ownerData, { abortEarly: false })
      handleNext()
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: {
          [key: string]: { path: string; message: string }
        } = {}
        err.inner.forEach((error: any) => {
          validationErrors[error.path] = error.message
        })
        formRef.current.setErrors(validationErrors)
      } else {
        console.log(err)
      }
    }
  }

  const handleCep = async (e: any) => {
    const cepValue = e.replace(/[^0-9]/g, '')
    setPropertyData({ ...propertyData, zipcode: cepValue })
    if (cepValue.length === 8) {
      const r = await getAddressByCEP(cepValue)
      setPropertyData({
        ...propertyData,
        address: r.data.address,
        city: r.data.city,
        neighborhood: r.data.district,
        state: { label: r.data.state, value: r.data.state },
        zipcode: e,
      })
    }
  }

  const maritalList = [
    { label: 'Solteiro', value: 'solteiro' },
    { label: 'Casado', value: 'casado' },
  ]

  return (
    <>
      <PagePathing
        withoutMarginLeft
      >{`Gestão de imóveis > Novo imóvel > Residencial`}</PagePathing>
      <ScrollDiv>
        <FormDiv>
          <Form onSubmit={handleChangePage} ref={formRef}>
            <PageTitle withoutMarginLeft>
              <img
                className="titleIcon"
                src={icons.homeSolid}
                alt="first title icon"
              />
              Endereço do imóvel
            </PageTitle>
            <Row style={{ alignItems: 'flex-start' }} gap={15}>
              <Select
                name="type"
                placeholder="Tipo de imóvel"
                value={propertyData.type || ''}
                options={mockTiposImoveis}
                onChange={(e: any) =>
                  setPropertyData({
                    ...propertyData,
                    type: e,
                  })
                }
              />
              <MaskedInput
                small
                type="cep"
                handleChange={(e: any) => {
                  handleCep(e)
                }}
                name="zipcode"
                placeholder="CEP"
              />
              <Input
                small
                type="text"
                name="neighborhood"
                placeholder="Bairro"
                value={propertyData.neighborhood}
                onChange={(e: any) =>
                  setPropertyData({
                    ...propertyData,
                    neighborhood: e.target.value,
                  })
                }
              />
              <Input
                type="text"
                name="address"
                placeholder="Endereço"
                value={propertyData.address}
                onChange={(e: any) =>
                  setPropertyData({
                    ...propertyData,
                    address: e.target.value,
                  })
                }
              />
            </Row>
            <Row gap={15}>
              <Select
                small
                name="state"
                placeholder="UF"
                options={stateList}
                value={propertyData.state || ''}
                onChange={(e: any) =>
                  setPropertyData({
                    ...propertyData,
                    state: e,
                  })
                }
              />
              <Input
                small
                placeholder="Cidade"
                type="text"
                name="city"
                value={propertyData.city}
                onChange={(e: any) =>
                  setPropertyData({
                    ...propertyData,
                    city: e.target.value,
                  })
                }
              />
              <Input
                small
                type="text"
                name="addressNumber"
                placeholder="Número"
                value={propertyData.addressNumber}
                onChange={(e: any) =>
                  setPropertyData({
                    ...propertyData,
                    addressNumber: e.target.value,
                  })
                }
              />
              <Input
                small
                type="text"
                name="complement"
                placeholder="Complemento"
                value={propertyData.complement}
                onChange={(e: any) =>
                  setPropertyData({
                    ...propertyData,
                    complement: e.target.value,
                  })
                }
              />
              <Input
                type="text"
                name="code"
                placeholder="Código do imóvel"
                value={propertyData.code}
                onChange={(e: any) =>
                  setPropertyData({
                    ...propertyData,
                    code: e.target.value,
                  })
                }
              />
            </Row>
            <PageTitle withoutMarginLeft>
              <img
                className="titleIcon"
                src={icons.clientOutlined}
                alt="first title icon"
              />
              Dados do proprietário
            </PageTitle>
            <Row style={{ alignItems: 'flex-start' }} gap={15}>
              <Input
                placeholder="Nome"
                type="text"
                name="name"
                value={ownerData.name}
                onChange={(e: any) =>
                  setOwnerData({
                    ...ownerData,
                    name: e.target.value,
                  })
                }
              />
              <MaskedInput
                type="cpf"
                handleChange={(e: any) => {
                  setOwnerData({
                    ...ownerData,
                    documentNumber: e,
                  })
                }}
                placeholder="CPF"
                name="document"
              />
              <MaskedInput
                small
                type="birthday"
                handleChange={(e: any) => {
                  const [day, month, year] = e.split('/');
                  const birthday = `${year}-${month}-${day}`;
                  setOwnerData({
                    ...ownerData,
                    birthday,
                  })
                }}
                placeholder="Data nascimento"
                name="birthday"
              />
            </Row>
            <Row gap={15}>
              <Input
                placeholder="E-mail"
                type="text"
                name="mainEmail"
                value={ownerData.mainEmail}
                onChange={(e: any) =>
                  setOwnerData({
                    ...ownerData,
                    mainEmail: e.target.value,
                  })
                }
              />
              <MaskedInput
                type="cell"
                handleChange={(e: any) => {
                  setOwnerData({
                    ...ownerData,
                    mainPhone: e,
                  })
                }}
                name="phoneNumber"
                placeholder="Telefone"
              />
              <Select
                small
                name="maritalStatus"
                placeholder="Estado civil"
                options={maritalList}
                onChange={(e: any) =>
                  setOwnerData({
                    ...ownerData,
                    maritalStatus: e,
                  })
                }
              />
            </Row>
          </Form>
        </FormDiv>
      </ScrollDiv>
      <ButtonNext onClick={handleChangePage} disabled={!allowNextPage} />
    </>
  )
}
