import * as p from 'assets/Banners'
import { IMAGES } from 'configs/configs.js'

export interface IImages {
  agro: string
  blank: string
  city: string
  dash: string
  girl: string
  logoMenu: string
  logoSapiens: string
  ocean: string
  solarwind: string
  wires: string
}

const images: IImages = {
  agro: p.agro,
  blank: p.blank,
  city: p.city,
  dash: p.bannerDashboard,
  girl: p.girl,
  logoMenu: p.logoMenu,
  logoSapiens: `${IMAGES.URL}/misc/logoMenu.png`,
  ocean: p.ocean,
  solarwind: p.solarwind,
  wires: p.wires,
}

export default images
