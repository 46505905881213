import { useEffect, useState } from 'react';
import { ResumeInputCard, PageTitle } from 'components'
import { Content } from './style'
import uuid from 'react-uuid'

interface IComponent {
  data: { [key: string]: any }
}

const mock = [
  { icon: "contractOutlined", label: '# 123546789', value: 'Contrato de garantia', status: 9 },
  { icon: "houseGreenOutlined", label: '# 123456789', value: 'Contrato de aluguel', status: 9 },
  { icon: "contractOutlined", label: '# 123546789', value: 'Contrato de garantia', status: 9 },
  { icon: "houseGreenOutlined", label: '# 123456789', value: 'Contrato de aluguel', status: 7 },
  { icon: "contractOutlined", label: '# 123546789', value: 'Contrato de garantia', status: 9 },
  { icon: "houseGreenOutlined", label: '# 123456789', value: 'Contrato de aluguel', status: 9 },
]

export default (props: IComponent) => {
  const green = '#0BF569';
  const purple = '#CB6BE6';
  const white = '#F9F9F9';

  const [list, setList] = useState<{[key: string]: any}>([]);

  const { data } = props;

  useEffect(() => {
    setList(data.contractList);
  }, []);

  return (
    <div className="sub-container">
      <PageTitle icon="hashOutlined">IDs do contrato</PageTitle>
      <Content>
        {
          list.map((item: any, i: number) => (
            <ResumeInputCard
              key={uuid()}
              clickable={false}
              names="button"
              icon={item.icon}
              placeholder={item.label}
              cardStyle={{
                backgroundColor: i%2 ? (
                  item.status === 9 ? purple : white
                ) : green
              }}
              placeholderStyle={{
                backgroundColor: i%2 ? (
                  item.status === 9 ? purple : white
                ) : green,
                color: 'black',
              }}
            >
              {
                `${item.value} ${item.status === 9 ? 'concluído' : ''}`
              }
            </ResumeInputCard>
          ))
        }
      </Content>
    </div>
  )
}
