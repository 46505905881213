import { PagePathing, PageTitle, Modal, KanbanSubMenu } from 'components'
import { SapiensContext } from 'context'
import { useContext, useState, useEffect, ReactElement } from 'react'
import { Moradores, Residencial, Resumo, Seguro } from 'pages'
import { Content, CardsDiv, Card } from 'styles'
import { icons, ribbons } from 'helper'

export default function GestaoContratos() {
  const { extended } = useContext(SapiensContext)
  const [showModal, setShowModal] = useState(false)
  const [pageNumber, setPageNumber] = useState<number>(0)

  useEffect(() => {
    if (!showModal) {
      setPageNumber(0)
    }
  }, [showModal])

  const pages: { [key: number]: ReactElement } = {
    0: (
      <Residencial
        handleNext={() => setPageNumber((oldValue) => oldValue + 1)}
      />
    ),
    1: (
      <Resumo
        handleNext={() => {
          setPageNumber((oldValue) => oldValue + 1)
        }}
        handleBack={() => {
          setPageNumber((oldValue) => oldValue - 1)
        }}
      />
    ),
    2: (
      <Seguro
        handleNext={() => setPageNumber((oldValue) => oldValue + 1)}
        handleBack={() => setPageNumber((oldValue) => oldValue - 1)}
      />
    ),
    3: (
      <Moradores handleBack={() => setPageNumber((oldValue) => oldValue - 1)} />
    ),
  }

  const categories = [
    ['Contratos', `/gestaoContratos`, icons.client2Outlined, false],
    ['Novo contrato', '/gestaoContratos/novoContrato', icons.plusSolid, true],
  ]

  return (
    <Content extended={extended}>
      <div>
        <PagePathing>{`Gestão de contratos > Novo contrato`}</PagePathing>
        <PageTitle>Tipo de contrato</PageTitle>
        <KanbanSubMenu categories={categories} />
        <Modal FS setShowModal={setShowModal} showModal={showModal}>
          {pages[pageNumber]}
        </Modal>
      </div>
      <CardsDiv>
        <Card onClick={() => setShowModal(true)}>
          <img
            className="iconClickable"
            src={icons.homeSolid}
            alt="card icon"
          />
          <span className="title">Residencial</span>
        </Card>
        <Card>
          <img className="selectIcon" src={ribbons.soon} alt="select icon" />
          <img
            className="iconClickable"
            src={icons.buildingSolid}
            alt="card icon"
          />
          <span className="title">Comercial</span>
        </Card>
        <Card>
          <img className="selectIcon" src={ribbons.soon} alt="select icon" />
          <img className="iconClickable" src={icons.hatSolid} alt="card icon" />
          <span className="title">Universitário</span>
        </Card>
      </CardsDiv>
      <Modal FS setShowModal={setShowModal} showModal={showModal}>
        {pages[pageNumber]}
      </Modal>
    </Content>
  )
}
