import styled from 'styled-components'

export const FormDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 75px;

  .row {
    margin-bottom: 20px;
  }
`
export const ScrollDiv = styled.div`
  height: 100%;
  overflow: scroll;
  /* border: 1px solid red; */

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`
