import styled from "styled-components";
import { IMaskInput } from 'react-imask';
import { colorConverter } from 'helper/utils';
import * as p from '../../../configs/configs'

interface IStyle {
  marginTop?: number
  marginBottom?: number
  justifyContent?: string
  available?: any
  err?: boolean
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 87%;
  max-height: 85%;
  min-height: 50%;
  overflow: scroll;
  padding: 50px;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: #F5F4F3;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`
export const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #070707;
  margin: 0;
`
export const Subtitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #070707;
  margin-top: ${(props: IStyle) => {
    if (props.marginTop) return `${props.marginTop}px`
    return '30px'
  }};
  margin-bottom: ${(props: IStyle) => {
    if (props.marginBottom) return `${props.marginBottom}px`
    return '30px'
  }};
`

export const RadioContent = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props: IStyle) => {
    if (props.available === false) return p.PALLETE.lighestGrey
    return p.PALLETE.black
  }};
  margin: 0;
`

export const RadioContainer = styled.div`
width: 100%;
display: flex;
justify-content: ${(props: IStyle) => {
  if (props.justifyContent) return props.justifyContent
  return 'space-around'
}};

  input[type='radio']:after {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: -7px;
    left: -1px;
    position: relative;
    background-color: #F5F4F3;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #CCCCCC;
    padding: 3px;
    /* transform: scale(0);
  transition: 120ms transform ease-in-out; */
  }

  input[type='radio']:checked:after {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top:  -7px;
    left: -1px;
    position: relative;
    background-color: #0BF569;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #0BF569;
    /* transform: scale(1); */
  }

  .label {
    min-height: 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    min-width: 33%;
  }

  .value {
    margin: 0 0 0 40px;
    width: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #070707;
      }
`

export const TinyText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props: IStyle) => {
    if (props.err) return '#F50B97'
    return '#070707'
  }};
  margin: 0;
`

export const Amount = styled.p`
  font-weight: 700;
  font-size: 40px;
  line-height: 20px;
  color: ${(props: IStyle) => {
    if (props.err) return '#F50B97'
    return '#070707'
  }};
  margin: -60px 0 -60px 0;
  `

export const AmountInput = styled(IMaskInput)`
  font-weight: 700;
  font-size: 40px;
  line-height: 20px;
  color: ${(props: IStyle) => {
    if (props.err) return '#F50B97'
    return '#070707'
  }};
  ::placeholder {
    color: #CCCCCC;
  }
  :focus {
    outline: none;
  }
  background-color: transparent;
  border: none;
  margin-left: 10px;
  `

export const AmountContainer = styled.div`
  margin: 60px 0 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

`

export const TinyRadioText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
  margin-top: 5px;
`

export const TrashContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-left: 30px;

  img {
    filter: ${() => {
    const c = colorConverter('#0BF569')
    return typeof c === 'object'
      ? c.filter
      : 'invert(0%) sepia(0%) saturate(2016%) hue-rotate(167deg) brightness(109%) contrast(80%)'
  }};
  /* filter: invert(78%) sepia(23%) saturate(4710%) hue-rotate(86deg) brightness(102%) contrast(95%); */


  }
`

export const Trash = styled.button`
  border: none;
  background-color: transparent;
`

export const Err = styled.p`
  margin: 0 0 35px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #F50B97;
`