import {
  useContext,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Kanban, Modal, Chat, PagePathing, PageTitle, Card } from 'components'
import { SapiensContext } from 'context'
import { clearString } from 'helper/utils'
import useFetch from 'hooks/useFetch'
import { useSocket } from 'hooks'
import { CardsDiv } from 'styles'

import { IKanbanColumns, IKanbanData, IObject } from 'interfaces'

export default forwardRef((_props: any, ref: any) => {
  const [kanbanData, setKanbanData] = useState<IKanbanData>([])
  const [filteredData, setFilteredData] = useState({})
  const [socket, setSocket] = useState<IObject>({})
  const [chatRoom, setChatRoom] = useState<string>('av')
  const [selected, setSelected] = useState<IObject>({})

  const api = useFetch('guarantee?format=kanban')
  const changeApi = useFetch('guarantee')

  const { searchParam, showModal, setShowModal } = useContext(SapiensContext)

  useImperativeHandle(ref, () => ({
    refresh() {
      api
        .fetch('get')
        .then((response) => {
          if (response.fail) throw new Error()
          setKanbanData(response.data)
        })
        .catch((err) => {
          console.error(err.message)
        })
    },
  }))

  const onCardMove = async (id: string, status: string) => {
    try {
      const response = await changeApi.fetch('put', { id, status })
      setKanbanData(response.data)
      socket.emit('refresh', { command: 'adm_rental' })
    } catch (err: any) {
      console.error(err.message)
      setKanbanData(filteredData)
    }
  }

  const onChatClick = async (data: any) => {
    setSelected(data)
    setChatRoom(clearString(data.code))
    setShowModal(true)
  }

  useEffect(() => {
    setShowModal(false)
    const fetchUsers = async () => {
      try {
        const response = await api.fetch('get')
        if (response.fail) throw new Error()
        setKanbanData(response.data)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
  }, [])

  useEffect(() => {
    setFilteredData(kanbanData)
  }, [kanbanData])

  useEffect(() => {
    const shownData = Object.entries(kanbanData).reduce(
      (obj: { [key: string]: IKanbanColumns }, [columnId, { items }]) => {
        obj[columnId] = {
          ...kanbanData[Number(columnId)],
          items: items.filter(
            (item: IObject) =>
              item?.name?.toLowerCase().includes(searchParam.toLowerCase()) ||
              item?.document
                ?.toLowerCase()
                .includes(searchParam.toLowerCase()) ||
              item?.email?.toLowerCase().includes(searchParam.toLowerCase())
          ),
        }
        return obj
      },
      {}
    )
    setFilteredData(shownData)
  }, [searchParam])

  useSocket((io: IObject) => {
    setSocket(io)
    io.on('av_refresh_coverage', () => {
      api.fetch('get').then((res) => {
        setKanbanData(res.data)
      })
    })
  }, socket)

  return (
    <>
      <CardsDiv>
        <Kanban
          data={filteredData}
          setData={setKanbanData}
          onDropCard={onCardMove}
          onCardClick={onChatClick}
        />
      </CardsDiv>
      <Modal
        FS
        showModal={showModal}
        setShowModal={setShowModal}
        extendedMenuData={
          <Chat
            title={`Solicitação: ${selected?.code}`}
            subtitle={`Contrato: ${selected?.id?.split('-')[0]}`}
            name="Sapiensbank"
            room={chatRoom}
            socket={socket}
          />
        }
      >
        <>
          <div>
            <PagePathing>{`Gestão de garantias > Resumo`}</PagePathing>
            <PageTitle>{`Solicitação: ${selected?.code}`}</PageTitle>
          </div>
          <CardsDiv noMargin>
            <Card
              value={selected?.id?.split('-')[0]}
              wide={false}
              icon="docOutlined"
            >
              Id da solicitação
            </Card>
            <Card value={selected?.userName} wide={false} icon="clientOutlined">
              Cliente
            </Card>
            <Card
              value={selected?.document}
              wide={false}
              icon="creditCardOutlined"
            >
              Documento do cliente
            </Card>
            <Card value={selected?.code} wide={false} icon="contractOutlined">
              Código da requisição
            </Card>
            <Card
              value={selected?.contractId}
              wide={false}
              icon="contractOutlined"
            >
              Id do contrato
            </Card>
            <Card value={selected?.email} wide={false} icon="emailOutlined">
              E-mail do cliente
            </Card>
          </CardsDiv>
        </>
      </Modal>
    </>
  )
})
