import { ErrorMsg } from './style'

interface IForgotPassProps {
  children: JSX.Element[] | JSX.Element | string
  green?: boolean
}

export default function RecoverPass(props: IForgotPassProps) {
  const { children } = props
  return <ErrorMsg {...props}>{children}</ErrorMsg>
}
