import uuid from 'react-uuid';
import { icons } from 'helper'
import Select, { StylesConfig } from 'react-select'
import LoadGifComponent from 'components/LoadGif'
import { useContext, useState } from 'react'
import { useFetch } from 'hooks'
import { SapiensContext } from 'context/sapiens'
import { Card } from './style'
import { timestampToDate, dateToHour } from '../../../helper/utils'

interface IBugCardProps {
  isNewCard: boolean
  reports?: any
  selectList?: any
  type?: string
}

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
})

export default (props: IBugCardProps) => {
  const { setNewCard } = useContext(SapiensContext)

  const { isNewCard, reports, selectList, type } = props
  const [loading, setGLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)
  const api = useFetch(`person/report/${type}`)

  const sendCard = async () => {
    if (message === '' || selectedIndex === null) {
      setError(true)
    } else {
      setError(false)
      setGLoading(true)
      try {
        const r = await api.fetch('post', {
          message,
          index: selectedIndex,
        })
        console.log(r)
        if (r.fail) throw new Error()
      } catch (e) {
        console.error(e)
      }
      setGLoading(false)
      setNewCard(false)
    }
  }

  const colourStyles: StylesConfig = {
    container: (styles) => ({
      ...styles,
      width: '280px',
    }),
    control: (styles) => ({
      ...styles,
      maxWidth: '280px',
      fontSize: '14px',
      lineHeight: '20px',
      backgroundColor: ' #fdfdfd',
      border: '0 !important',
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important',
      },
    }),
    option: (styles, { data, isFocused }: any) => ({
      ...styles,
      backgroundColor: isFocused ? '#F5F4F3' : '#fdfdfd',
      color: '#9C948C',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      height: '50px',
      maxWidth: '280px',
      border: 'none',
      ...dot(data.color),
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 212,
      backgroundColor: ' #fdfdfd',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      textAlign: 'left',
      boxSizing: 'border-box',
      borderRadius: '5px',
      maxWidth: '280px',
      border: '0 !important',
      boxShadow: '0 !important',
    }),
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#9C948C') }),
    singleValue: (styles, { data }: any) => ({ ...styles, ...dot(data.color) }),
  }

  return isNewCard ? (
    <Card isNewCard={isNewCard}>
      {error && <p className="error-message">Preencha todos os campos.</p>}
      <textarea
        rows={4}
        autoFocus
        onChange={(e) => setMessage(e.target.value)}
      />
      <div className="first-row">
        <Select
          options={selectList}
          placeholder="Assunto"
          isSearchable={false}
          styles={colourStyles}
          onChange={(e) => setSelectedIndex(selectList.indexOf(e))}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
        {loading ? (
          <div className="send">
            <LoadGifComponent greenLoad />
          </div>
        ) : (
          <button className="send" onClick={() => sendCard()} type="button">
            Enviar
          </button>
        )}
      </div>
    </Card>
  ) : (
    reports.map((report: any) => {
      return (
        <Card isNewCard={isNewCard} key={uuid()}>
          <div className="first-row">
            <img src={icons.calendarOutlined} alt="card-icon" />
            <p className="big-date">
              {timestampToDate(report.date)}{' '}
              <span className="small-hour">{`às ${dateToHour(
                report.date
              )}`}</span>
            </p>
          </div>
          <p className="bold">{report.status}</p>
          <div className="first-row">
            <div className="ball-icon" style={{ background: report.color }} />
            <p className="light">{report.place}</p>
          </div>
        </Card>
      )
    })
  )
}
