import { ResumeInputCard, PageTitle } from 'components'
import { maskDocument } from 'helper/utils'
import { Content } from './style'
import uuid from 'react-uuid'

const mock = [
  { icon: 'houseOutlined', label: 'Casa', value: 'Tipo de imóvel' },
  { icon: 'locationOutlined', label: '88025-120', value: 'CEP' },
  { icon: 'locationOutlined', label: '120', value: 'Número' },
  { icon: 'locationOutlined', label: 'Apartamento', value: 'Complemento' },
  { icon: 'locationOutlined', label: 'Água Verde', value: 'Bairro' },
  { icon: 'locationOutlined', label: 'Rua Dom pedro I', value: 'Endereço' },
  { icon: 'locationOutlined', label: 'PR', value: 'UF' },
  { icon: 'locationOutlined', label: 'Curitiba', value: 'Cidade' },
]

export default () => {
  return (
    <div className="sub-container">
      <PageTitle icon="houseOutlined">Dados do imóvel</PageTitle>
      <Content>
        {
          mock.map((item) => (
            <ResumeInputCard
              key={uuid()}
              clickable={false}
              names='button'
              icon={item.icon}
              placeholder={item.label}
            >
              {item.value}
            </ResumeInputCard>
          ))
        }
      </Content>
    </div>
  )
}
