import styled from 'styled-components'

interface IStyleProps {
  green?: boolean
}

export const ErrorMsg = styled.span`
  color: ${(props: IStyleProps) => {
    if (props.green) return '#0bf569'
    return 'red'
  }};
  font-size: 15px;
`
