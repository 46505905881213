import { InputContainer } from 'styles'
import { ChangeEvent, useState, useEffect, useRef } from 'react'
import { inputIcons } from 'helper'
import { IInputIcons } from 'helper/inputIcon'
import { useField } from '@unform/core'
import { Input, InputArea, InputIcon, InputError } from './style'

interface IInputProps {
  ref?: any
  name: string
  type: string
  rounded?: boolean
  icon?: keyof IInputIcons
  placeholder?: string
  disabled?: boolean
  value?: string
  small?: boolean
  xsmall?: boolean
  large?: boolean
  tall?: boolean
  size?: any
  marginRight?: number
  maxLength?: number
  smallPad?: boolean
  onChange?: (_e: ChangeEvent<HTMLInputElement>) => void
  initialError?: string
}

export default function InputComponent(props: IInputProps) {
  const { maxLength, icon, type, name, initialError, ...rest } = props
  const [iconState, setIconState] = useState(0)
  const { fieldName, registerField, defaultValue, error } = useField(name)
  const inputRef = useRef(null)
  const [Erro, setErro] = useState(initialError)

  const handleChangeState = () => {
    setIconState((oldState) => {
      if (oldState === 0) return 1
      return 0
    })
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [registerField, fieldName])

  useEffect(() => {
    if (error) {
      setErro(error)
    }
  }, [error])

  return (
    <InputContainer>
      <Input
        maxLength={maxLength}
        name={name}
        ref={inputRef}
        error={Erro}
        type={
          type === 'password' ? (iconState === 0 ? 'password' : 'text') : type
        }
        defaultValue={defaultValue}
        {...rest}
      />
      {icon && (
        <InputIcon
          icon={icon}
          src={inputIcons[icon][iconState]}
          alt="input icon"
          onClick={handleChangeState}
        />
      )}
      {Erro && <InputError>{Erro}</InputError>}
    </InputContainer>
  )
}
