import { useEffect } from 'react'
import { CONNECTION } from '../configs/configs.js'

const useSocket = (callback: any, unmountSock?: any) => {
  useEffect(() => {
    import('socket.io-client')
      .then(({ default: io }) => io(CONNECTION.SOCKET_URL))
      .then((socket) => callback(socket))
  }, [])

  useEffect(() => {
    return () => {
      console.log('unmounted')
      unmountSock?.connected && unmountSock.off()
    }
  }, [unmountSock])
}

export default useSocket
