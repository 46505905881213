import styled from "styled-components";

interface IStyled {
  selected?: boolean
  selectionColor?: string
  unavailable?: boolean
  row?: boolean
}

export const RadioContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: ${(props: IStyled) => {
    if (props.row) return 'row'
    return 'column'
  }};
  justify-content: flex-start;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 5px;
  min-width: 33%;
`;

export const CircleOut = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  /* overflow: hidden; */
  border-radius: 25px;
  border: ${(props: IStyled) => {
    if (props.selected) return `1px solid ${props.selectionColor}`
    if (props.unavailable) return `1px solid ${'#CCCCCC'}`
    return `1px solid ${'#757575'}`
  }};
  padding: 3px;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  background-color: transparent;
`;

export const CircleIn = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background-color: ${(props: IStyled) => {
    if (props.selected) return props.selectionColor
    if (props.unavailable) return 'transparent'; 
    return 'transparent'
  }};
  /* margin-left: 1px; */
`;

export const TouchableIcon = styled.button`
  height: 15px;
  width: 15px;
  margin-left: 15px;
`