import { Modal, Button } from 'components'
import { Container, PageTitle, PageContent, GreenText } from './style'
import investir from './investir.png'

interface IModalProps {
  setShowModal?: any
  showModal?: any
}

export default function SoonInvestir(props: IModalProps) {
  const { setShowModal, showModal } = props
  return (
    <Modal
      closeButton
      setShowModal={setShowModal}
      showModal={showModal}
      width={500}
      height={500}
      hideMenu
    >
      <Container>
        <div style={{ alignSelf: 'flex-start' }}>
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            <PageTitle>Investir
            <GreenText>(em breve!)</GreenText>
            </PageTitle>
          </div>
          <div style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
            <PageContent>Lorem ipsum dolor sit amet, consectetur</PageContent>
            <PageContent>
              adipiscing elit. Elit mauris tincidunt diam.
            </PageContent>
          </div>
        </div>
        <img src={investir} alt="imagem_investir" width={220} />
        <Button rounded secondary onClick={() => setShowModal(false)}>
          ENTENDI
        </Button>
      </Container>
    </Modal>
  )
}
