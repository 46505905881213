import {
  PageTitle,
  PagePathing,
  ButtonNext,
  InsuranceCard,
  LoadingBanner,
} from 'components'
import { useContext, useEffect, useState } from 'react'
import { SapiensContext } from 'context/sapiens'
import { useFetch } from 'hooks'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import ErrorMessage from 'styles/ErrorMessage'
import { CardsDiv, Span } from './style'
import { formatDate } from 'helper/utils'

const schemaUserLevel = Yup.object().shape({
  level: Yup.array().min(1).required('campo obrigatório: algum level'),
})

export default function NivelAcesso({ handleBack }: any) {
  const { userInfo, setUserInfo, setTitle } = useContext(SapiensContext)
  const [allowNextPage, setAllowNextPage] = useState(false)
  const [isPosting, setIsPosting] = useState(false)
  const navigate = useNavigate()
  const api = useFetch('person/user-register')
  const [errs, setError] = useState(false)

  const [status, setStatus] = useState<string>('idle')

  const handleChangePage = async () => {
    try {
      setStatus('loading')
      setAllowNextPage(false)
      api
        .fetch('post', {
          ...userInfo,
          birthday: formatDate(userInfo.birthday),
          state: userInfo.state.value,
        })
        .then(() => {
          setUserInfo({
            name: '',
            lastName: '',
            address: '',
            addressNumber: '',
            zipcode: '',
            complement: '',
            state: '',
            city: '',
            country: 'Brasil',
            neighborhood: '',
            rgNumber: '',
            documentNumber: '',
            email: '',
            phoneNumber: '',
            level: [],
          })
          setStatus('loaded')
        })
        .catch((r: any) => {
          if (r.fail) throw new Error(r.errors)
        })
    } catch (err: any) {
      setStatus('idle')
      console.log(err)
    }
  }

  useEffect(() => {
    const validateInputs = async () => {
      try {
        await schemaUserLevel.validate(userInfo, { abortEarly: false })
        setAllowNextPage(true)
      } catch (err) {
        console.log(err)
        setAllowNextPage(false)
      }
    }
    validateInputs()
  }, [userInfo])

  const choseOption = (id: number) => {
    if (!userInfo.level.includes(id)) {
      const newLevel = userInfo.level
      newLevel.push(id)
      setUserInfo((curr: any) => {
        return { ...curr, level: newLevel }
      })
    } else {
      const newLevel = userInfo.level
      newLevel.splice(newLevel.indexOf(id), 1)
      setUserInfo((curr: any) => {
        return { ...curr, level: newLevel }
      })
    }
  }

  switch (status) {
    case 'loading': // Página branca de loading
      return <LoadingBanner status="loading" />
    case 'loaded': // Página verde de confirmação
      return (
        <LoadingBanner
          status="loaded"
          confirmationText="Usuário Cadastrado!"
          onFinished={() => {
            navigate('/gestaoUsuarios')
          }}
        />
      )
    default: // Página que o cliente está renderizando
      return (
        <div style={{ opacity: isPosting ? '0.4' : '1.0', overflow: 'hidden' }}>
          <PagePathing
            withoutMarginLeft
          >{`Gestão de time > Novo usuário > Nível de acesso`}</PagePathing>
          <PageTitle withoutMarginLeft>Nível de acesso</PageTitle>
          <Span>
            Clique para selecionar o nível de visibilidade a ser disponibilizado
            ao usuário
          </Span>
          <CardsDiv>
            <InsuranceCard
              green={userInfo.level.includes(0)}
              icon="contractOutlined"
              id="0"
              onSelect={() => choseOption(0)}
            >
              Gestão de garantias
            </InsuranceCard>
            <InsuranceCard
              green={userInfo.level.includes(1)}
              icon="houseGreenOutlined"
              id="1"
              onSelect={() => choseOption(1)}
            >
              Gestão de imóveis
            </InsuranceCard>
            <InsuranceCard
              green={userInfo.level.includes(2)}
              icon="buildingSolid"
              id="2"
              onSelect={() => choseOption(2)}
            >
              Gestão de alugueis
            </InsuranceCard>
            <InsuranceCard
              green={userInfo.level.includes(4)}
              icon="teamOutlined"
              id="4"
              onSelect={() => choseOption(4)}
            >
              Gestão de usuários
            </InsuranceCard>
            <InsuranceCard
              green={userInfo.level.includes(3)}
              icon="stonksOutlined"
              id="3"
              onSelect={() => choseOption(3)}
            >
              Gestão financeira
            </InsuranceCard>
          </CardsDiv>
          <ButtonNext
            onClick={handleChangePage}
            alsoBack
            onClickBack={handleBack}
            disabled={!allowNextPage}
          >
            CADASTRAR
          </ButtonNext>
          {errs && <ErrorMessage>Erro de conexão</ErrorMessage>}
        </div>
      )
  }
}
