import { useState } from 'react'
import axios from 'axios'

import { CONNECTION } from 'configs/configs.js'

import { useAuth } from '.'

interface IMethod {
  [key: string]: () => Promise<{ data: any; status: number; fail: false, errors?: any }>
}

const parseError = (error: any) => {
  const { response, isAxiosError } = error
  if (response && response.data) {
    const { data, status } = response
    return [data, status] || []
  }
  if (isAxiosError) {
    return { message: 'Falha ao enviar dados ao servidor' }
  }
  return []
}

const useFetch = (endpoint = '/') => {
  const { token, setToken } = useAuth()

  const [curEndpoint, setEndpoint] = useState<string>(endpoint)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // TODO - Acrescentei o parâmetro URL provisóriamente até descobrir o motivo
  // pelo qual o setEndpoint não estar funcionando
  const fetch = async (
    request: any,
    data?: { [key: string]: any },
    url?: string
  ) => {
    const URL = `${CONNECTION.URL}${url || curEndpoint}`
    try {
      setIsLoading(true)
      const options = { headers: { Authorization: token } }
      if (data && 'token' in data) {
        options.headers.Authorization = data.token
        data.token = undefined
      }
      const method: IMethod = {
        post: async () => {
          const r = await axios.post(URL, data, options)
          if (r.data?.token) setToken(r.data.token)
          else if (curEndpoint === 'login') setToken(r.data.response)
          return { data: r.data, status: r.status, fail: false }
        },
        put: async () => {
          const r = await axios.put(URL, data, options)
          if (r.data?.token) setToken(r.data.token)

          return { data: r.data, status: r.status, fail: false }
        },
        get: async () => {
          const r = await axios.get(URL, options)
          if (r.data?.token) setToken(r.data.token)

          return { data: r.data, status: r.status, fail: false }
        },
        delete: async () => {
          const r = await axios.delete(URL, options)
          return { data: r.data, status: r.status, fail: false }
        },
      }
      const response = await method[request]()
      setIsLoading(false)
      return response
    } catch (error) {
      setIsLoading(false)
      return { data: null, fail: true, errors: parseError(error) }
    }
  }

  return {
    setEndpoint, // TODO - Por algum motivo, isto não está atualizando
    fetch,
    isLoading,
  }
}

export default useFetch
