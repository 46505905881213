import { RefObject } from 'react'
import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  green?: boolean
  black?: boolean
  disabled?: boolean
  column: string | number
  color: string
  ref?: (
    _instance: HTMLDivElement | null
  ) => void | RefObject<HTMLDivElement> | null
  isDragging?: boolean
  theme: ITheme
}

export default styled.div`
  line-height: 16px;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 142px;
  width: 210px;
  border-radius: 5px;
  border: none;

  cursor: ${(props: IStyleProps) => {
    if (props.isDragging) return 'grabbing'
    return props.disabled ? 'pointer' : 'grab'
  }};
  box-shadow: ${(props: IStyleProps) =>
    props.isDragging && `0px 3px 4px 0px ${props.theme.boxShadow}`};

  color: #fdfdfd;
  background: ${(props: IStyleProps) => {
    return props.color || '#191919'
  }};

  .name {
    color: white;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-left: 10px;
  }

  .cpf,
  .message,
  .email {
    color: white;
    font-weight: 300;
    font-size: 12px;
    margin-left: 10px;

    span {
      font-weight: 400;
      color: white;
    }
  }

  .iconsRow {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    gap: 15px;
    .icon {
      filter: brightness(0) saturate(100%);
      filter: invert(100%) sepia(92%) saturate(156%) hue-rotate(182deg)
        brightness(114%) contrast(95%);
      max-width: 30px;
      height: 13px;
      margin: 0 10px 17px 0;
      cursor: pointer;
    }
    .right {
      position: relative;
      left: 25%;
    }
    .notification {
      z-index: 10;
      position: absolute;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background-color: red;
      margin-right: 10px;
      margin-top: -2px;
    }
  }
  .trashContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
`
