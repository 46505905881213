import { Form } from '@unform/web'
import * as c from 'components'
import { SapiensContext } from 'context'
// import DetalhesUsuario from 'pages/GestaoUsuarios/DetalhesUsuario'
import { GestaoFinancas } from '..'
import { useContext, useRef, useState } from 'react'
import styled from 'styled-components'
import { Content } from 'styles'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 20px;
`

const Row = styled.div`
  display: flex;
  gap: 10px;
  margin: 5px;
`

const suggestions = [
  'Batata',
  'Batatão',
  'Bolo',
  'Chocolate',
  'Danoninho',
  'Lasanha',
  'Paçoca',
  'Salsicha',
  'Trakinas',
]

export default function Sandbox() {
  const formRef = useRef<HTMLFormElement | any>(null)
  const handleSubmit = (data: any) => {
    console.log(data)
  }
  const { extended, toggleDarkMode } = useContext(SapiensContext)
  const [showModal, setShowModal] = useState(false)
  const [extendedMenu, setExtendedMenu] = useState<any>(false)

  return (
    <Content extended={extended}>
      <Row>
        <button type="button" onClick={() => setShowModal(true)}>
          click for modal saque
        </button>
        <c.Modal
          FS
          setShowModal={setShowModal}
          showModal={showModal}
          extendedMenuData={extendedMenu}
          setExtendedMenu={setExtendedMenu}
        >
          <GestaoFinancas setExtendedMenu={setExtendedMenu} />
        </c.Modal>
      </Row>
      <Row>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Column>
            <c.Button $loading={false} onClick={toggleDarkMode}>
              Default
            </c.Button>
            <c.Button $loading>Loading</c.Button>
            <Row>
              <c.Button $loading={false} small secondary>
                Small Secondary
              </c.Button>
              <c.Button $loading={false} small>
                Small
              </c.Button>
            </Row>
            <c.Button $loading={false} rounded>
              Rounded
            </c.Button>
            <c.Button $loading={false} outlined>
              Outlined
            </c.Button>
            <c.Button $loading={false} disabled>
              Disabled
            </c.Button>
            <Row>
              {/* #CHECKBOX */}
              <c.Checkbox value="1" name="checkboxA" />
              <c.Checkbox
                value="1"
                name="checkboxA"
                label="default"
                defaultChecked
              />
              <c.Checkbox value="1" name="checkboxC" green />
              <c.Checkbox
                value="1"
                name="checkboxC"
                green
                label="green"
                defaultChecked
              />
            </Row>
            {/* #SELECT */}
            <c.Select
              name="Select"
              placeholder="Placeholder"
              options={[
                { value: '1', label: 'option 1' },
                { value: '2', label: 'option 2' },
                { value: '3', label: 'option 3' },
              ]}
            />
            {/* #INPUT */}
            <c.Input placeholder="Default" type="text" name="inputA" />
            <c.FileInput name="file" />
            <c.AutoComplete
              suggestions={suggestions}
              name="inputAutocomplete"
              placeholder="Autocomplete"
              type="text"
            />

            <c.Input
              placeholder="Rounded"
              type="text"
              name="inputRounded"
              rounded
            />
            <c.Input
              placeholder="Error"
              type="text"
              name="inputError"
              initialError="Error"
            />
            <c.Input
              placeholder="Eye Icon"
              type="text"
              name="inputEye"
              icon="eye"
            />
            <c.Input
              placeholder="Password Icon"
              type="text"
              name="inputPassword"
              icon="password"
            />
            <c.Input
              placeholder="Upload Icon"
              type="text"
              name="inputIcon"
              icon="upload"
            />
            <c.Input
              disabled
              placeholder="Disabled"
              type="text"
              name="inputA"
              icon="upload"
            />
          </Column>
        </Form>
        <Column>
          {/* #CLICKABLECARD */}
          <Row>
            <c.ClickableCard icon="cryptoOutlined" to="">
              Clickable
            </c.ClickableCard>
            <c.ClickableCard icon="esgOutlined" secondary to="">
              Secondary
            </c.ClickableCard>
            <c.ClickableCard icon="esgOutlined" green to="">
              Green
            </c.ClickableCard>
            <c.ClickableCard icon="sapiensCalendarOutlined" disabled to="">
              Disabled
            </c.ClickableCard>
          </Row>
          {/* CARD */}
          <Row>
            <c.Card value="4" icon="houseGreenOutlined" variation="+123">
              Default
            </c.Card>
            <c.Card value="12" icon="phoneOutlined" variation="+123" secondary>
              Secondary
            </c.Card>
            <c.Card value="1" icon="bugSolid" variation="+123" green>
              Green
            </c.Card>
          </Row>
          <Row>
            <c.Card icon="loanOutlined" value="123123123" wide variation="+123">
              Wide
            </c.Card>
            <c.Card
              icon="coinOutlined"
              value="123123123"
              wide
              variation="+123"
              inverted
            >
              Invert
            </c.Card>
          </Row>
          <Row>
            <c.Card
              icon="currencyOutlined"
              value="99999"
              wide
              variation="+123"
              secondary
            >
              Black
            </c.Card>
          </Row>
          <Row>
            <c.FundingCard
              modality="Pré-Fixado"
              minValue={1000}
              months={36}
              image="agro"
              flavor="eco"
            >
              Funding
            </c.FundingCard>
            <c.EsgCard
              paid={1234}
              rentability="IPCA +10"
              value={1000}
              months={36}
              image="solarwind"
              parcel={20}
              progress={12}
              ribbon="pro"
            >
              ESG
            </c.EsgCard>
          </Row>
          <Row>
            <c.KanbanCard
              column={0}
              values={{}}
              name="Kanban"
              document="123.456.789-00"
              email="dani@bol.com.br"
              color="red"
            />
            <c.KanbanCard
              column={1}
              values={{}}
              name="Kanban"
              document="123.456.789-00"
              email="dani@bol.com.br"
              color="red"
            />
            <c.KanbanCard
              column={2}
              values={{}}
              name="Kanban"
              document="123.456.789-00"
              email="dani@bol.com.br"
              isDragging
              color="red"
            />
          </Row>
        </Column>
      </Row>
    </Content>
  )
}
