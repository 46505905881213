import styled from 'styled-components'

interface IStyleProps {
  extended?: boolean
}

export const SearchBar = styled.input`
  transition: width 1s;

  width: 805px;
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  height: 64px;
  box-sizing: border-box;

  color: #d4d4d4;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;

  ::placeholder {
    color: #d4d4d4;
  }
`

export const SearchBarContainer = styled.div`
  transition: margin-left 1s;

  background-color: #ffffff;
  z-index: 3;
  position: fixed;
  top: 0;
  height: 64px;
  box-sizing: border-box;
  padding: 23px 0 26px 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 255px);

  margin-left: ${(props: IStyleProps) => {
    if (props.extended) return '255px'
    return '76px'
  }};

  .leftIcon {
    position: relative;
    top: 0;
    height: 25px;
    left: 3px;
    margin-right: 15px;
    filter: invert(98%) sepia(1%) saturate(1173%) hue-rotate(259deg)
      brightness(113%) contrast(66%);
  }

  .rightIcon {
    position: relative;
    height: 25px;
    top: 0;
    right: 40px;
    filter: invert(98%) sepia(1%) saturate(1173%) hue-rotate(259deg)
      brightness(113%) contrast(66%);
  }

  .row {
    display: flex;
    align-items: center;
  }
`
