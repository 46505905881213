import { IMAGES } from 'configs/configs.js'

export interface IIcons {
  [key: string]: string | any
}

const icons: IIcons = {
  agroGreenOutlined: `${IMAGES.URL}/outlined/agroGreen.svg`,
  alertOutlined: `${IMAGES.URL}/outlined/alert.svg`,
  arrowLeftSolid: `${IMAGES.URL}/solid/arrowLeft.svg`,
  arrowRightSolid: `${IMAGES.URL}/solid/arrowRight.svg`,
  backSolid: `${IMAGES.URL}/solid/back.svg`,
  bellOutlined: `${IMAGES.URL}/outlined/bell.svg`,
  bellSolid: `${IMAGES.URL}/solid/bell.svg`,
  billOutlined: `${IMAGES.URL}/outlined/bill.svg`,
  birthdayOutlined: `${IMAGES.URL}/outlined/birthday.svg`,
  blockedContractOutlined: `${IMAGES.URL}/outlined/blockedContract.svg`,
  blockedSolid: `${IMAGES.URL}/solid/blocked.svg`,
  bottleOutlined: `${IMAGES.URL}/outlined/bottle.svg`,
  btcSolid: `${IMAGES.URL}/solid/btc.svg`,
  bugSolid: `${IMAGES.URL}/solid/bug.svg`,
  buildingSolid: `${IMAGES.URL}/solid/building.svg`,
  calendarOutlined: `${IMAGES.URL}/outlined/calendar.svg`,
  cellphoneOutlined: `${IMAGES.URL}/outlined/cellphone.svg`,
  chatLeafLogo: `${IMAGES.URL}/solid/chatLeafLogo.svg`,
  chatOutlined: `${IMAGES.URL}/outlined/chat.svg`,
  chatSolid: `${IMAGES.URL}/solid/chat.svg`,
  checkedContractOutlined: `${IMAGES.URL}/outlined/checkedContract.svg`,
  checkedSolid: `${IMAGES.URL}/solid/checked.svg`,
  checkOutlined: `${IMAGES.URL}/outlined/check.svg`,
  circularArrowOutlined: `${IMAGES.URL}/outlined/circularArrow.svg`,
  client2Outlined: `${IMAGES.URL}/outlined/client2.svg`,
  clientOutlined: `${IMAGES.URL}/outlined/client.svg`,
  clientPFSolid: `${IMAGES.URL}/solid/clientPF.svg`,
  clientPJSolid: `${IMAGES.URL}/solid/clientPJ.svg`,
  clientSolid: `${IMAGES.URL}/solid/client.svg`,
  clientsOutlined: `${IMAGES.URL}/outlined/clients.svg`,
  clockOutlined: `${IMAGES.URL}/outlined/clock.svg`,
  closedContractOutlined: `${IMAGES.URL}/outlined/closedContract.svg`,
  co2Outlined: `${IMAGES.URL}/outlined/co2.svg`,
  coinOutlined: `${IMAGES.URL}/outlined/coin.svg`,
  coinSolid: `${IMAGES.URL}/solid/coin.svg`,
  confirmationOutlined: `${IMAGES.URL}/outlined/confirmation.svg`,
  confirmLoadGreenOutlined: `${IMAGES.URL}/outlined/confirmLoadGreen.svg`,
  confirmSolid: `${IMAGES.URL}/solid/confirm.svg`,
  contractOutlined: `${IMAGES.URL}/outlined/contract.svg`,
  creditCardOutlined: `${IMAGES.URL}/outlined/creditCard.svg`,
  crosshairOutlined: `${IMAGES.URL}/outlined/crosshair.svg`,
  cryptoOutlined: `${IMAGES.URL}/outlined/crypto.svg`,
  cryptoSolid: `${IMAGES.URL}/solid/crypto.svg`,
  currencyOutlined: `${IMAGES.URL}/outlined/currency.svg`,
  dashboardOutlined: `${IMAGES.URL}/outlined/dashboard.svg`,
  dashboardSolid: `${IMAGES.URL}/solid/dashboard.svg`,
  dataDetailsOutlined: `${IMAGES.URL}/outlined/dateDetails.svg`,
  deleteSolid: `${IMAGES.URL}/solid/delete.svg`,
  docOutlined: `${IMAGES.URL}/outlined/doc.svg`,
  docSolid: `${IMAGES.URL}/solid/doc.svg`,
  dollarsignOutlined: `${IMAGES.URL}/outlined/dollarsign.svg`,
  dotSolid: `${IMAGES.URL}/solid/dot.svg`,
  downloadSolid: `${IMAGES.URL}/solid/download.svg`,
  dropdownSolid: `${IMAGES.URL}/solid/dropdown.svg`,
  editOutlined: `${IMAGES.URL}/outlined/edit.svg`,
  emailOutlined: `${IMAGES.URL}/outlined/mail.svg`,
  emailSolid: `${IMAGES.URL}/solid/email.svg`,
  errorOutlined: `${IMAGES.URL}/outlined/error.svg`,
  errorSolid: `${IMAGES.URL}/solid/error.svg`,
  esgOutlined: `${IMAGES.URL}/outlined/esg.svg`,
  exchangeSolid: `${IMAGES.URL}/solid/exchange.svg`,
  eyeClosedSolid: `${IMAGES.URL}/solid/eyeClosed.svg`,
  eyeOpenSolid: `${IMAGES.URL}/solid/eyeOpen.svg`,
  filterOutlined: `${IMAGES.URL}/outlined/filter.svg`,
  greenCheckOutlined: `${IMAGES.URL}/outlined/greenCheck.svg`,
  greyCheckOutlined: `${IMAGES.URL}/outlined/greyCheck.svg`,
  h2oOutlined: `${IMAGES.URL}/outlined/h2o.svg`,
  hashOutlined: `${IMAGES.URL}/outlined/hash.svg`,
  hatSolid: `${IMAGES.URL}/solid/hat.svg`,
  homeSolid: `${IMAGES.URL}/solid/home.svg`,
  houseGreenOutlined: `${IMAGES.URL}/outlined/houseGreen.svg`,
  houseOutlined: `${IMAGES.URL}/outlined/house.svg`,
  infoOutlined: `${IMAGES.URL}/outlined/info.svg`,
  kanbancheck: `${IMAGES.URL}/outlined/kanbancheck.svg`,
  kanbandoc: `${IMAGES.URL}/outlined/kanbandoc.svg`,
  kanbanphone: `${IMAGES.URL}/outlined/kanbanphone.svg`,
  kanbanplay: `${IMAGES.URL}/outlined/kanbanplay.svg`,
  kanbanselfie: `${IMAGES.URL}/outlined/kanbanselfie.svg`,
  kanbansuccess: `${IMAGES.URL}/outlined/kanbansuccess.svg`,
  leafOutlined: `${IMAGES.URL}/outlined/leaf.svg`,
  leafSolid: `${IMAGES.URL}/solid/leaf.svg`,
  loadGifOutlined: `${IMAGES.URL}/outlined/loadGif.svg`,
  loanOutlined: `${IMAGES.URL}/outlined/loan.svg`,
  locationOutlined: `${IMAGES.URL}/outlined/location.svg`,
  lockClosedOutlined: `${IMAGES.URL}/outlined/lockClosed.svg`,
  lockOpenOutlined: `${IMAGES.URL}/outlined/lockOpen.svg`,
  lockOutlined: `${IMAGES.URL}/outlined/lock.svg`,
  loginOutlined: `${IMAGES.URL}/outlined/login.svg`,
  loginReverseOutlined: `${IMAGES.URL}/outlined/loginReverse.svg`,
  logoutOutlined: `${IMAGES.URL}/outlined/logout.svg`,
  mailOutlined: `${IMAGES.URL}/outlined/mail.svg`,
  moneyPng: `${IMAGES.URL}/png/money.svg`,
  moneyOutlined: `${IMAGES.URL}/outlined/money.svg`,
  moneyRunSolid: `${IMAGES.URL}/outlined/coin.svg`,
  newClientOutlined: `${IMAGES.URL}/outlined/newClient.svg`,
  notificationOutlined: `${IMAGES.URL}/outlined/notification.svg`,
  paperClockOutlined: `${IMAGES.URL}/outlined/paperClock.svg`,
  passwordOutlined: `${IMAGES.URL}/outlined/password.svg`,
  phoneOutlined: `${IMAGES.URL}/outlined/phone.svg`,
  phoneSolid: `${IMAGES.URL}/solid/phone.png`,
  plusSolid: `${IMAGES.URL}/solid/plus.svg`,
  productSolid: `${IMAGES.URL}/solid/product.svg`,
  productsOutlined: `${IMAGES.URL}/outlined/products.svg`,
  profitabilityOutlined: `${IMAGES.URL}/outlined/profitability.svg`,
  rating: `${IMAGES.URL}/solid/rating.svg`,
  sapiensCalendarOutlined: `${IMAGES.URL}/outlined/sapiensCalendar.svg`,
  sapiensCoinSolid: `${IMAGES.URL}/solid/sapiensCoinMineradora.svg`,
  searchOutlined: `${IMAGES.URL}/outlined/search.svg`,
  securityOutlined: `${IMAGES.URL}/outlined/security.svg`,
  selfieOutlined: `${IMAGES.URL}/outlined/selfie.svg`,
  sendSolid: `${IMAGES.URL}/solid/send.svg`,
  sendOutlined: `${IMAGES.URL}/outlined/send.svg`,
  sentSolid: `${IMAGES.URL}/solid/sent.svg`,
  shieldOutlined: `${IMAGES.URL}/outlined/shield.svg`,
  smsSolid: `${IMAGES.URL}/solid/sms.svg`,
  statementOutlined: `${IMAGES.URL}/outlined/statement.svg`,
  stonksOutlined: `${IMAGES.URL}/outlined/stonks.svg`,
  successOutlined: `${IMAGES.URL}/outlined/success.svg`,
  teamOutlined: `${IMAGES.URL}/outlined/team.svg`,
  trashOutlined: `${IMAGES.URL}/outlined/trash.svg`,
  trashSolid: `${IMAGES.URL}/solid/trash.svg`,
  uploadSolid: `${IMAGES.URL}/solid/upload.svg`,
  userOutlined: `${IMAGES.URL}/outlined/user.svg`,
  vectorOutlined: `${IMAGES.URL}/outlined/vector.svg`,
  whatsSolid: `${IMAGES.URL}/solid/whats.png`,
  windmillOutlined: `${IMAGES.URL}/outlined/windmill.svg`,
  windmillSolid: `${IMAGES.URL}/solid/windmill.svg`,
  xSolid: `${IMAGES.URL}/solid/x.svg`,
}

export default icons
