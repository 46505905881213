import { PagePathing } from "components/index";
import { Container } from "./style";

import Details from './details';
import Contract from './contract';
import Lodger from './lodger';
import Property from './property';
import ContractList from './contractList';

interface IComponent {
  data: { [key: string]: any }
}

export default (props: IComponent) => {
  const { data } = props;
  return (
    <Container>
      <PagePathing>{`Dashboard > Saldo > Detalhes do extrato`}</PagePathing>
      <div className="container">
        <Details data={data} />
        {
          'contractId' in data && (
            <>
              <Contract />
              <Lodger />
              <Property />
            </>
          )
        }
        {
          'contractList' in data && (
            <ContractList data={data} />
          )
        }
      </div>
    </Container>
  )
}