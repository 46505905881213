import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  theme: ITheme
  secondary?: boolean
  disabled?: boolean
  green?: boolean
  red?: boolean
  wide?: boolean
  value?: string
  inverted?: boolean
  variation?: string
  plain?: boolean
  blackIcon?: boolean
  miner?: string
  color?: string
}

export default styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  height: 142px;
  width: ${(props: IStyleProps) => {
    if (props.wide) return '315px'
    return '206px'
  }};

  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #00000040;

  background: ${(props: IStyleProps) => {
    if (props.green) return `${props.theme.card.backgroundColor.green}`
    if (props.red) return `${props.theme.card.backgroundColor.danger}`
    if (props.secondary) return `${props.theme.card.backgroundColor.secondary}`
    return `${props.theme.card.backgroundColor.primary}`
  }};

  user-select: none;

  .icon {
    align-self: center;
    justify-content: center;
    filter: ${(props: IStyleProps) => {
      if (props.green) return `${props.theme.card.icon.green}`
      if (props.disabled) return `${props.theme.card.icon.disabled}`
      if (props.miner === 'sapiensCoinSolid') return ''
      if (props.secondary) return `${props.theme.card.icon.secondary}`
      if (props.blackIcon)
        return 'invert(0%) sepia(17%) saturate(2041%) hue-rotate(154deg) brightness(82%) contrast(105%)'
      return `${props.theme.card.icon.primary}`
    }};

    max-width: 25px;
    height: 25px;
    margin-left: ${(props: IStyleProps) => {
      if (props.wide && !props.plain) return 45
      return 25
    }}px;
    margin-bottom: 10px;
  }

  .value {
    display: flex;
    margin-left: 10px;
    font-size: ${(props: IStyleProps) => {
      if (props.inverted && props.wide) return '16px'
      if (props.plain && props.wide) return '18px'
      if (props.wide) return '22px'
      return '18px'
    }};
    font-style: normal;
    font-weight: ${(props: IStyleProps) => {
      if (props.inverted) return '400'
      if (props.plain) return '300'
      return '700'
    }};
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    height: auto;

    max-width: ${(props: IStyleProps) => {
      if (props.wide) return '220px'
      return '150px'
    }};

    color: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.fontColor.disabled}`
      if (props.secondary) return `${props.theme.card.fontColor.secondary}`
      if (props.green) return `${props.theme.card.fontColor.green}`
      return `${props.theme.card.fontColor.primary}`
    }};
  }

  .title {
    font-size: ${(props: IStyleProps) => {
      if (props.inverted && props.wide) return '22px'
      if (props.wide || props.plain) return '16px'
      return '12px'
    }};
    font-style: normal;
    font-weight: ${(props: IStyleProps) => {
      if (props.inverted || props.plain) return '700'
      return '400'
    }};
    line-height: 16px;
    text-align: left;
    color: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.fontColor.disabled}`
      if (props.secondary) return `${props.theme.card.fontColor.secondary}`
      if (props.green) return `${props.theme.card.fontColor.green}`
      return `${props.theme.card.fontColor.primary}`
    }};
    width: auto;
    margin: ${(props: IStyleProps) => (
      (props.wide && !props.plain) ? '0 0 30px 45px' : '0 0 30px 25px'
    )};
  }

  .ribbon {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 57px;
    height: 60px;
  }

  .variation {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.01em;
    text-align: left;

    color: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.variation.disabled}`
      if (props.secondary) return `${props.theme.card.variation.secondary}`
      if (props.green) return `${props.theme.card.variation.green}`
      return `${props.theme.card.variation.primary}`
    }};

    margin: ${(props: IStyleProps) => {
      if (props.wide) return '0 0 20px 45px'
      return '0 0 20px 25px'
    }};
  }

  .flex {
    display: flex;
    align-items: baseline;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    margin-right: 22px;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    font-size: 12px;
    visibility: hidden;
    width: auto;
    background-color: #000000;
    color: #fff;
    margin: 0;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;

    /* Position the tooltip text */
    bottom: 135%;
    left: 50%;
    margin-left: -60px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .rating {
    position: absolute;
    top: 17.5px;
    right: 17.5px;
    text-align: right;

    img {
      margin-right: 7.67px;
    }
  }
`
