import { useState, useEffect } from 'react';
import { ResumeInputCard, PageTitle } from 'components'
import uuid from 'react-uuid'
import { Content } from './style'
import { PALLETE } from 'configs/configs.js'
import { useFetch } from 'hooks';

interface IComponent {
  data: { [key: string]: any }
}

export default (props: IComponent) => {
  const { data } = props;
  const [list, setList] = useState<any[]>([]);
  const api = useFetch(`transaction?data=${btoa(JSON.stringify(data))}`);

  useEffect(() => {

    api.fetch('get')
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
  }, []); 

  return (
    <>
      <PageTitle>Detalhes do extrato</PageTitle>
      <Content>
        {
          list.map((item) => (
            <ResumeInputCard
              key={uuid()}
              clickable={false}
              names="button"
              icon={item.icon}
              placeholderStyle={(item.signal && item.signal === '-') ? { color: PALLETE.pink } : undefined}
              placeholder={item.signal ? `${item.signal} ${item.label}` : `${item.label}`}
            >
              {item.value}
            </ResumeInputCard>
          ))
        }
      </Content>
    </>
  )
}
