module.exports = {
  CONNECTION: {
    // -- PRODUÇÃO --
    URL: 'https://api.sapiensbank.com.br:8156/',
    SOCKET_URL: 'https://api.sapiensbank.com.br:8186/',
    // --------------
    // -- HOMOLOGAÇÃO --
    // URL: 'http://localhost:8156/',
    // SOCKET_URL: 'http://localhost:9086/',
    // --------------
    // -- SANDBOX --
    // URL: 'https://api.sapiensbank.com.br:9056/',
    // SOCKET_URL: 'https://api.sapiensbank.com.br:9086/',
    // -------------

    ISSUER: 'sapiens-aluguel-verde-server',
    AUDIENCE: 'sapiens-aluguel-verde-app',
  },
  IMAGES: {
    URL: 'https://sapiensbank.com.br/assets/icons',
  },
  STORAGE: {
    NAME: 'sapiens-aluguel-verde',
  },
  PALLETE: {
    black: '#000000',
    disabled: '#FFFFFF60',
    green: '#0BF569',
    white: '#F9F9F9',
    lightGreen: '#0BF569',
    darkGreen: '#08B74F',
    grey: '#20242B',
    lightGrey: '#9C948C',
    translucidGrey: '#9C948C60',
    modal: '#9C948C80',
    modal2: '#4c484480',
    lighestGrey: '#CCCCCC',
    red: '#D93025',
    pink: '#F60665',
    lightBlack: '#131313',
    bluishGreen: '#03989E',
    inputGrey: '#757575',
    almostWhite: '#E5E5E5',
    almostGreen: '#D9FFE9',
  },
  STATES: [
    { label: 'AC', value: 'AC' },
    { label: 'AL', value: 'AL' },
    { label: 'AM', value: 'AM' },
    { label: 'BA', value: 'BA' },
    { label: 'CE', value: 'CE' },
    { label: 'DF', value: 'DF' },
    { label: 'ES', value: 'ES' },
    { label: 'GO', value: 'GO' },
    { label: 'MA', value: 'MA' },
    { label: 'MT', value: 'MT' },
    { label: 'MS', value: 'MS' },
    { label: 'MG', value: 'MG' },
    { label: 'PA', value: 'PA' },
    { label: 'PB', value: 'PB' },
    { label: 'PR', value: 'PR' },
    { label: 'PE', value: 'PE' },
    { label: 'PI', value: 'PI' },
    { label: 'RJ', value: 'RJ' },
    { label: 'RN', value: 'RN' },
    { label: 'RS', value: 'RS' },
    { label: 'RO', value: 'RO' },
    { label: 'RR', value: 'RR' },
    { label: 'SC', value: 'SC' },
    { label: 'SP', value: 'SP' },
    { label: 'SE', value: 'SE' },
    { label: 'TO', value: 'TO' },
  ],
  PAGES: {
    NEW_CLIENT: {
      INPUT_LIST: [
        { type: 'text', placeholder: 'CPNJ', name: 'cnpj', isRequired: true },
        {
          type: 'text',
          placeholder: 'Razão social',
          name: 'social',
          isRequired: true,
        },
        {
          type: 'text',
          placeholder: 'Nome fantasia',
          name: 'fantasy',
          isRequired: true,
        },
        {
          type: 'email',
          placeholder: 'Email',
          name: 'email',
          isRequired: true,
        },
        {
          type: 'phone',
          placeholder: 'Telefone',
          name: 'cellphone',
          isRequired: true,
        },
        {
          type: 'text',
          placeholder: 'CEP',
          name: 'postalCode',
          group: 'address',
          isRequired: true,
        },
        {
          type: 'text',
          placeholder: 'Endereço',
          name: 'street',
          group: 'address',
          isRequired: true,
        },
        {
          type: 'number',
          placeholder: 'Número',
          name: 'number',
          group: 'address',
          isRequired: true,
        },
        {
          type: 'text',
          placeholder: 'Complemento',
          name: 'complement',
          group: 'address',
          isRequired: false,
        },
        {
          type: 'text',
          placeholder: 'Bairro',
          name: 'district',
          group: 'address',
          isRequired: true,
        },
        {
          type: 'select',
          placeholder: 'UF',
          class: 'half',
          name: 'state',
          group: 'address',
          isRequired: true,
        },
        {
          type: 'text',
          placeholder: 'Cidade',
          class: 'half',
          name: 'city',
          group: 'address',
          isRequired: true,
        },
      ],
    },
    NEW_PRODUCT: {
      INPUT_LIST: [
        {
          type: 'number',
          placeholder: 'Ivestimento em reais',
          name: 'investimento_minimo',
        },
        { type: 'number', placeholder: 'Prazo em meses', name: 'prazo' },
        {
          type: 'number',
          placeholder: 'Rentabilidade em %',
          name: 'rentabilidade',
        },
        {
          type: 'text',
          placeholder: 'Documento CPF ou CNPJ',
          name: 'documento',
        },
      ],
    },
    TRANSFER: {
      INPUT_LIST: [
        { type: 'text', placeholder: 'Instituição de origem', name: 'origin' },
        { type: 'number', placeholder: 'Valor', name: 'amount' },
      ],
    },
    TRANSFER2: {
      INPUT_LIST: [
        { type: 'select', placeholder: 'Usuário ou Empresa', name: 'cpf' },
      ],
    },
    INCOME: {
      INPUT_LIST: [
        {
          type: 'autocomplete',
          placeholder: 'Documento ou nome',
          name: 'user',
          isRequired: true,
          autofill: [],
        },
        {
          type: 'select',
          placeholder: 'Investimento',
          name: 'investPurchasesId',
          isRequired: true,
        },
        {
          type: 'input',
          placeholder: 'Valor R$',
          name: 'amount',
          isRequired: true,
        },
      ],
    },
  },
}
