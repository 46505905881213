import { icons } from 'helper'
import { IIcons } from 'helper/icons'
import { LightTheme } from 'styles/Themes'
import Select from 'react-select'
import { ResumeInputCard, TextDiv } from './style'

interface ICardProps {
  children: string
  icon?: keyof IIcons
  small?: boolean
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  onClick?: any
  name?: string
  options?: object[]
  type?: any
  handleChange?: any
  value?: any
}

export default function ResumeInputCardComponent(props: ICardProps) {
  const { name, children, icon, options, handleChange, disabled, small } = props
  const selectStyle = {
    control: () => ({
      background: LightTheme.card.backgroundColor.primary,
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    }),
    valueContainer: () => ({
      width: '60%',
      display: 'inline-flex',
      justifyContent: 'center',
    }),
    indicatorsContainer: () => ({
      width: '20%',
    }),
    dropdownIndicator: () => ({
      color: disabled ? '#9C948C' : '#070707',
    }),
    menu: (styles: any) => ({
      ...styles,
      zIndex: 212,
      backgroundColor: ' #fdfdfd',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      textAlign: 'left',
      boxSizing: 'border-box',
      borderRadius: '5px',
      maxWidth: '280px',
      border: '0 !important',
      boxShadow: '0 !important',
    }),
    option: (styles: any, { isFocused }: any) => ({
      ...styles,
      backgroundColor: isFocused ? '#F5F4F3' : '#fdfdfd',
      color: '#9C948C',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      height: '50px',
      maxWidth: '280px',
      border: 'none',
      paddingLeft: '20px',
      fontStyle: 'normal',
      fontWeight: '400',
      padding: '15px 30px',
      textAlign: 'left',
      overflow: 'hidden',
    }),
    menuList: (base: any) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '8px',
        backgroundColor: '#C4C4C4',
        borderRadius: '50px',
      },
      '::-webkit-scrollbar-track': {
        background: '#fbfbfb',
        border: 0,
      },
      '::-webkit-scrollbar-thumb': {
        width: '8px',
        backgroundColor: '#C4C4C4',
        borderRadius: '50px',
      },
    }),
  }
  // const selectStyle = {
  //   control: () => ({
  //     background: LightTheme.card.backgroundColor.primary,
  //     display: 'inline-flex',
  //     alignItems: 'center',
  //     width: '100%',
  //     justifyContent: 'space-between',
  //   }),
  //   valueContainer: () => ({
  //     width: '60%',
  //     display: 'inline-flex',
  //     justifyContent: 'center',
  //   }),
  //   indicatorsContainer: () => ({
  //     width: '20%',
  //   }),
  //   dropdownIndicator: () => ({
  //     color: disabled ? '#9C948C' : '#070707',
  //   }),
  // }

  return (
    <ResumeInputCard {...props}>
      <Select
        name={name}
        onChange={(e) => handleChange(e)}
        options={options}
        placeholder=""
        styles={selectStyle}
        isSearchable={false}
        value=""
        isDisabled={disabled}
        {...props}
      />
      <TextDiv>
        {icon && (
          <img className="iconClickable" src={icons[icon]} alt="card icon" />
        )}
        <p>{name}</p>
      </TextDiv>
      <span className="title">{children}</span>
    </ResumeInputCard>
  )
}
