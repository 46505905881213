import icons from 'helper/icons'
import {
  Dispatch,
  SetStateAction,
  useState,
  ReactElement,
  useEffect,
} from 'react'
import Tooltip from 'react-tooltip'
import BugReport from '../BugReport'
import SuportReport from '../SuportReport'
import { RightMenu, Background, ExtendableMenu, Row } from './style'

interface IRightMenuProps {
  setShowModal: Dispatch<SetStateAction<boolean>>
  disable?: boolean
  extendedMenuData?: any
  setExtendedMenu?: any
}

export default function RightMenuComponent(props: IRightMenuProps) {
  const { setShowModal, disable, extendedMenuData, setExtendedMenu } = props

  const [extended, setExtended] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)

  const handleSetExtend = () => {
    if (!disable) {
      setExtended((oldState) => !oldState)
      setPageNumber(0)
    } else if (disable && pageNumber !== 0) {
      setExtended((oldState) => !oldState)
    }
  }

  useEffect(() => {
    setExtended(!!extendedMenuData)
  }, [extendedMenuData])

  useEffect(() => {
    if (pageNumber === 1 || pageNumber === 2) {
      setExtended(true)
    }
  }, [pageNumber])

  const pages: { [key: number]: any } = {
    0: extendedMenuData,
    1: <BugReport />,
    2: <SuportReport />,
  }

  return (
    <Row>
      {extended && <Background />}
      <ExtendableMenu {...props} extended={extended}>
        <img
          className="extend-button"
          src={icons.backSolid}
          alt="extend button"
          onClick={handleSetExtend}
        />
        {extended && pages[pageNumber]}
      </ExtendableMenu>
      <RightMenu>
        <img
          src={icons.xSolid}
          alt="Close Menu icon"
          className="closeModal"
          onClick={() => {
            setExtendedMenu && setExtendedMenu();
            setShowModal(false)
          }}
        />
        <img
          src={icons.bugSolid}
          alt="Bug icon"
          data-for="bug"
          data-tip="Relatar <br /> problemas"
          onClick={() => {
            setPageNumber(1)
            setExtended(true)
          }}
        />
        <Tooltip place="bottom" multiline id="bug" effect="solid" />

        <img
          src={icons.docSolid}
          alt="Bug icon"
          data-for="history"
          data-tip="Relatar <br /> problemas"
          onClick={() => {
            setPageNumber(2)
            setExtended(true)
          }}
        />
        <Tooltip place="bottom" multiline id="history" effect="solid" />
      </RightMenu>
    </Row>
  )
}
