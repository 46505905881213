import styled from 'styled-components'
import { ITheme } from 'styles/Themes'
import { IMaskInput } from 'react-imask'

interface IStyleProps {
  small?: boolean
  theme: ITheme
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  medium?: boolean
  clickable?: boolean
}

export const ResMaskedInput = styled(IMaskInput)`
  border: none;
  max-width: ${(props: IStyleProps) => {
    if (props.small) return '138px'
    return '197px'
  }};
  background: ${(props: IStyleProps) => {
    if (props.green) return `${props.theme.card.backgroundColor.green}`
    if (props.secondary) return `${props.theme.card.backgroundColor.secondary}`
    return `${props.theme.card.backgroundColor.primary}`
  }};
  cursor: ${(props: IStyleProps) => {
    if (props.disabled || !props.clickable) return ''
    return 'pointer'
  }};
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  text-align: left;
  line-height: 21.09px;
  margin: 0 0 12px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ::placeholder {
    color: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.fontColor.primary}`
      return `${props.theme.card.fontColor.secondary}`
    }};
  }
`

export const ResumeInputCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 142px;
  width: ${(props: IStyleProps) => {
    if (props.small) return '206px'
    if (props.medium) return '260px'
    return '315px'
  }};
  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #00000040;
  background: ${(props: IStyleProps) => {
    if (props.green) return `${props.theme.card.backgroundColor.green}`
    if (props.secondary) return `${props.theme.card.backgroundColor.secondary}`
    return `${props.theme.card.backgroundColor.primary}`
  }};
  cursor: ${(props: IStyleProps) => {
    if (props.disabled) return ''
    return 'pointer'
  }};
  .title {
    color: ${(props: IStyleProps) => {
      // if (props.disabled) return `${props.theme.card.fontColor.disabled}`
      // if (props.secondary) return `${props.theme.card.fontColor.secondary}`
      return `${props.theme.card.fontColor.primary}`
    }};
    font-size: ${(props: IStyleProps) => {
      if (props.small) return '14px'
      return '16px'
    }};
    font-style: normal;
    font-weight: 700;
    text-align: left;
    line-height: ${(props: IStyleProps) => {
      if (props.small) return '16.41px'
      return '18.75px'
    }};
    letter-spacing: 1%;
    margin: 0 0 22px 25px;
  }
  .iconClickable {
    filter: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.icon.disabled}`
      if (props.secondary) return `${props.theme.card.icon.white}`
      if (props.green) return `${props.theme.card.icon.green}`
      return `${props.theme.card.icon.green}`
    }};
    max-width: 24px;
    height: 24px;
    margin: 0 0 12px 25px;
  }
  .editIcon {
    filter: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.icon.disabled}`
      if (props.secondary) return `${props.theme.card.icon.white}`
      if (props.green) return `${props.theme.card.icon.green}`
      return `${props.theme.card.icon.green}`
    }};
    max-width: 10px;
    height: 10px;
    margin: ${(props: IStyleProps) => {
      if (props.small) return '0 0 22px 175px'
      return '30px 0 22px 274px'
    }};
  }
  input {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    border: none;
    max-width: ${(props: IStyleProps) => {
      if (props.small) return '138px'
      return '197px'
    }};
    background: ${(props: IStyleProps) => {
      if (props.green) return `${props.theme.card.backgroundColor.green}`
      if (props.secondary)
        return `${props.theme.card.backgroundColor.secondary}`
      return `${props.theme.card.backgroundColor.primary}`
    }};
    cursor: ${(props: IStyleProps) => {
      if (props.disabled) return ''
      return 'pointer'
    }};
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    text-align: left;
    line-height: 21.09px;
    vertical-align: text-bottom;
    margin: 0 0 12px 12px;
    ::placeholder {
      color: ${(props: IStyleProps) => {
        if (props.disabled) return `${props.theme.card.fontColor.primary}`
        return `${props.theme.card.fontColor.secondary}`
      }};
    }
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  p {
    width: 16px;
    max-width: 16px;
    height: 18px;
    max-height: 18px;
    color: #0bf569;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    line-height: 18px;
    margin: ${(props: IStyleProps) => {
      if (props.small) return '0 0 22px 175px'
      return '30px 0 22px 274px'
    }};
  }

  .tooltip-icon {
    align-self: end;
    margin-bottom: 34px;
    margin-right: 16px;
  }

  .tooltip {
    position: relative;
    align-self: end;
    margin-bottom: 0%;
    margin-right: 0%;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    font-size: 12px;
    visibility: hidden;
    width: auto;
    background-color: #000000;
    color: #fff;
    margin: 0;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;

    /* Position the tooltip text */
    top: 50%;
    margin-left: -135%;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`
