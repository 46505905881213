import { ProgressBar } from 'components'
import { images, ribbons } from 'helper'
import { MouseEvent } from 'react'
import { IImages } from 'helper/images'
import { IRibbons } from 'helper/ribbons'
import EsgCard from './style'

interface ICardProps {
  ribbon?: keyof IRibbons
  green?: boolean
  image?: keyof IImages
  imageUrl?: string
  children: string
  disabled?: boolean
  value: number
  months: number
  parcel: number
  rentability: string
  progress: number
  paid: number
  onClick?: (_e: MouseEvent<HTMLElement>) => void
}

export default function EsgCardComponent(props: ICardProps) {
  const {
    children,
    image,
    imageUrl,
    value,
    months,
    parcel,
    ribbon,
    rentability,
    progress,
    paid,
  } = props
  return (
    <EsgCard {...props}>
      {image ? (
        <div
          className="image"
          style={{ backgroundImage: `url(${images[image]})` }}
        />
      ) : (
        <div
          className="image"
          style={{ backgroundImage: `url(${imageUrl || images.blank})` }}
        />
      )}
      {ribbon && <img className="ribbon" src={ribbons[ribbon]} alt="ribbon" />}
      <span className="rentability">
        Rentabilidade: <b>{rentability}% a.a. </b>
      </span>

      <span className="title">{children}</span>

      <div className="row">
        <span>
          Saldo investido: <b>R$ {value}</b>
        </span>
        <span>
          Prazo: <b>Até {months} meses</b>
        </span>
      </div>
      <ProgressBar progress={progress} />
      <div className="row">
        <span>
          <b>{parcel}</b> Parcelas restantes
        </span>
        <span>
          Rentabilidade paga: R$ <b>{paid}</b>
        </span>
      </div>
    </EsgCard>
  )
}
