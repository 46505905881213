import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  theme: ITheme
  checked: boolean
}

export default styled.div`
  display: flex;
  width: 550px;
  height: 20px;

  margin-bottom: 30px;

  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;

  color: #9c948c;

  cursor: pointer;

  label {
    margin: 3px 0 0 20px;
    cursor: pointer;
  }
  input {
    cursor: pointer;
    display: none;
  }

  .checkmark {
    height: 20px;
    width: 20px;
    border: 1px solid black;
    border-radius: 4px;
    display: inline-block;
    background-color: white;
  }

  .iconClickable {
    height: 22px;
    width: 22px;
  }
`
