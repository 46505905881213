import { useStorage } from 'hooks'
import { createContext, Dispatch, SetStateAction } from 'react'
import jwtDecode from 'jwt-decode'

interface AuthContextType {
  setToken: Dispatch<SetStateAction<string>>
  token: string
  signin: (_newToken: string, _callback: VoidFunction) => void
  signout: (_callback: VoidFunction) => void
  user: {
    isLogged: boolean
    isUser: number
    level: any
    partner: {
      documentNumber: string
      documenttType: string
    }
  }
}

interface IdecodedUser {
  payload: {
    isLogged: boolean
    isUser: number
    level: any
    partner: {
      documentNumber: string
      documenttType: string
    }
    userId: number
  }
}

export const AuthContext = createContext<AuthContextType>(null!)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = useStorage('SapiensToken')
  const [user, setUser] = useStorage('SapiensUser')

  const signin = (newToken: string, callback: VoidFunction) => {
    setToken(newToken)
    const data: IdecodedUser = jwtDecode(newToken)
    setUser({ ...data.payload, isLogged: true })
    callback()
  }

  const signout = (callback: VoidFunction) => {
    setToken(null)
    setUser(null)
    callback()
  }

  const value = {
    token,
    setToken,
    signin,
    signout,
    user,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
