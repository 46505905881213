import { Kanban } from 'components'
import { SapiensContext } from 'context'
import useFetch from 'hooks/useFetch'
import { useSocket } from 'hooks'
import { useContext, useEffect, useState } from 'react'
import { CardsDiv } from 'styles'

import { IKanbanColumns, IKanbanData, IObject } from 'interfaces'

export default function ContratosKanban() {
  const [kanbanData, setKanbanData] = useState<IKanbanData>({})
  const [filteredData, setFilteredData] = useState({})
  const [socket, setSocket] = useState<IObject>({})

  const api = useFetch('property?format=kanban')
  const changeApi = useFetch('property')

  const { searchParam, setShowModal } = useContext(SapiensContext)

  useEffect(() => {
    setShowModal(false)
    const fetchUsers = async () => {
      try {
        const response = await api.fetch('get')
        if (response.fail) throw new Error()
        setKanbanData(response.data)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
  }, [])

  useEffect(() => {
    setFilteredData(kanbanData)
  }, [kanbanData])

  useEffect(() => {
    const shownData = Object.entries(kanbanData).reduce(
      (obj: { [key: string]: IKanbanColumns }, [columnId, { items }]) => {
        obj[columnId] = {
          ...kanbanData[Number(columnId)],
          items: items.filter(
            (item: IObject) =>
              item?.name?.toLowerCase().includes(searchParam.toLowerCase()) ||
              item?.document
                ?.toLowerCase()
                .includes(searchParam.toLowerCase()) ||
              item?.email?.toLowerCase().includes(searchParam.toLowerCase())
          ),
        }
        return obj
      },
      {}
    )
    setFilteredData(shownData)
  }, [searchParam])

  useSocket((io: IObject) => {
    setSocket(io)
    io.on('av_refresh_property', () => {
      api.fetch('get').then((res) => {
        setKanbanData(res.data)
      })
    })
  }, socket)

  const onCardMove = async (id: string, status: string) => {
    try {
      const response = await changeApi.fetch('put', { id, status })
      setKanbanData(response.data)
    } catch (err: any) {
      console.error(err.message)
      setKanbanData(filteredData)
    }
  }

  return (
    <CardsDiv>
      <Kanban
        data={filteredData}
        setData={setKanbanData}
        onDropCard={onCardMove}
        withoutChatIcon
      />
    </CardsDiv>
  )
}
