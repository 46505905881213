import { icons } from 'helper'
import { IIcons } from 'helper/icons'
import { useState } from 'react'
import styled from 'styled-components'
import { ResumeInputCard, ResMaskedInput } from './style'

interface ICardProps {
  isMask?: string
  children: string
  icon?: keyof IIcons
  small?: boolean
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  onClick?: any
  placeholder: string
  handleChangeInput?: any
  names: string
  clickable?: boolean
  medium?: boolean
  blocks?: any
  tooltip?: string
  cardStyle?: { [key: string]: any }
  valueStyle?: { [key: string]: any }
  placeholderStyle?: { [key: string]: any }
}

const TextDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

export default function ResumeInputCardComponent(props: ICardProps) {
  const [disabled, setDisabled] = useState(true)
  const {
    blocks,
    children,
    clickable = true,
    handleChangeInput,
    icon,
    isMask,
    names,
    placeholder,
    placeholderStyle,
    tooltip,
    valueStyle,
    cardStyle,
  } = props

  return (
    <ResumeInputCard style={cardStyle} {...props}>
      {clickable && (
        disabled ? (
          <img
            className="editIcon"
            src={icons.editOutlined}
            alt="edit icon"
            onClick={() => setDisabled((curr) => !curr)}
          />
        ) : (
          <p onClick={() => setDisabled((curr) => !curr)}>OK</p>
        )
      )}
      {
        tooltip && (
          <div className='tooltip'>
            <img
              className="tooltip-icon"
              width={16}
              src={icons.infoOutlined}
              alt="tooltip"
            />
            <span className="tooltiptext">{tooltip}</span>
          </div>
        )
      }
      <TextDiv>
        {icon && <img className="iconClickable" src={icons[icon]} alt="card icon" />}
        {isMask ? (
          disabled ? (
            <ResMaskedInput
              defaultValue={placeholder}
              mask={isMask}
              blocks={blocks}
              placeholder={placeholder}
              name={names}
              disabled
              style={placeholderStyle}
            />
          ) : (
            <ResMaskedInput
              mask={isMask}
              blocks={blocks}
              defaultValue={placeholder}
              onAccept={(e: any) => handleChangeInput(e)}
              placeholder={placeholder}
              name={names}
              disabled={disabled}
              style={placeholderStyle}
            />
          )
        ) : disabled ? (
          <input placeholder={placeholder} disabled value={placeholder} style={placeholderStyle} />
        ) : (
          <input
            placeholder={placeholder}
            value={placeholder}
            onChange={(e) => handleChangeInput(e.target.value)}
            style={placeholderStyle}
          />
        )}
      </TextDiv>
      <span className="title" style={valueStyle}>
        {children}
      </span>
    </ResumeInputCard>
  )
}
