import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import {
  Login,
  Demo,
  Dashboard,
  NotFound,
  GestaoUsuarios,
  Contratos,
  Recover,
  NovoContrato,
  NovoImovel,
  Imoveis,
  GestaoAlugueis,
  RedefinirSenha,
  NovoUsuario,
} from 'pages'
import { PrivateRoute } from 'layouts'
import Confirm from '../components/Confirm'

const UserRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* PUBLIC ROUTES */}
        {/* <Route path="/recover/:token" element={<RedefinirSenha />} /> */}
        <Route path="/recover" element={<RedefinirSenha />} />
        <Route path="/login/recover" element={<Recover />} />
        <Route path="/login" element={<Login />} />
        <Route path="/demo" element={<Demo />} />

        {/* PRIVATE ROUTES */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="confirm" element={<Confirm />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route
            path="gestaoContratos/novoContrato"
            element={<NovoContrato />}
          />
          <Route path="gestaoContratos" element={<Contratos />} />
          <Route path="gestaoImoveis/novoImovel" element={<NovoImovel />} />
          <Route path="gestaoImoveis" element={<Imoveis />} />
          <Route
            path="gestaoGarantias/inquilinos"
            element={<GestaoAlugueis />}
          />
          <Route
            path="gestaoGarantias/proprietarios"
            element={<GestaoAlugueis />}
          />
          <Route path="gestaoUsuarios/novoUsuario" element={<NovoUsuario />} />
          <Route path="gestaoUsuarios" element={<GestaoUsuarios />} />
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default UserRoutes
