import { icons } from 'helper'
import { IIcons } from 'helper/icons'
import { Card } from './style'

interface ICardProps {
  children: string
  icon?: keyof IIcons
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  onSelect?: (_e?: any) => any
  boldTitle?: boolean
  id?: string
}

export default function InsuranceCard(props: ICardProps) {
  const { children, icon, green, onSelect } = props
  return (
    <Card {...props}>
      <span className="container" onClick={() => onSelect && onSelect()}>
        <img
          className="selectIcon"
          src={green ? icons.checkOutlined : icons.vectorOutlined}
          alt="select icon"
        />
        {icon && (
          <img className="iconClickable" src={icons[icon]} alt="card icon" />
        )}
        <span className="title">{children}</span>
      </span>
    </Card>
  )
}
