import styled from "styled-components";

export const Container = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: scroll;

  .container {
    height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .sub-container {
    margin-top: 50px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: #F5F4F3;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #c4c4c4;
    border-radius: 50px;
    height: 140px;
  }
`;

export const Subtitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #070707;
  margin: 30px 0;
`;

export const Content = styled.div`
  margin: 10px 30px;
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;