import styled from 'styled-components'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  black?: boolean
  disabled?: boolean
  green?: boolean
  wide?: boolean
  value?: number
  ribbon?: string
  theme: ITheme
}

export default styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 190px;
  width: 315px;
  border-radius: 10px;
  font-size: 10px;

  cursor: ${(props: IStyleProps) => {
    if (props.disabled) return ''
    return 'pointer'
  }};
  box-shadow: 0px 3px 4px 0px ${(props: IStyleProps) => props.theme.boxShadow};

  background: ${(props: IStyleProps) =>
    props.theme.card.backgroundColor.secondary};

  .image {
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 110px;
    filter: brightness(50%);
  }

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    z-index: 1;
    color: ${(props: IStyleProps) => props.theme.card.fontColor.secondary};
    position: relative;
    top: -20px;
    left: 20px;
  }

  .ribbon {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 57px;
    height: 60px;
  }

  .rentability {
    position: absolute;
    right: 20px;
    top: 15px;
    line-height: 12px;
    letter-spacing: 0em;
    color: ${(props: IStyleProps) => props.theme.card.fontColor.secondary};

    b {
      background: ${(props: IStyleProps) => {
        if (props.ribbon === 'pro')
          return `${props.theme.card.ribbon.backgroundColor.pro}`
        if (props.ribbon === 'soon')
          return `${props.theme.card.ribbon.backgroundColor.soon}`
        return `${props.theme.card.ribbon.backgroundColor.basic}`
      }};
      padding: 3px 8px;
      border-radius: 100px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin: 5px 20px 10px 20px;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: ${(props: IStyleProps) => props.theme.card.fontColor.esg};

    b {
      font-weight: 700;
      color: ${(props: IStyleProps) => props.theme.card.fontColor.esgBold};
    }
  }
`
