import styled from 'styled-components'
import ReactSelect from 'react-select'
import { ITheme } from 'styles/Themes'

interface IStyleProps {
  small?: boolean
  theme: ITheme
  green?: boolean
  secondary?: boolean
  disabled?: boolean
}

export const Select = styled(ReactSelect)`
  .Select__control {
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    height: 50px;
    width: 200px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    padding-left: 10px;

    /* box-sizing: border-box;
    &:hover {
      border: 1px solid #9c948cdd;
      outline: hidden;
      background-color: #8f888133;
    } */

    background: #f9f9f9;
    border: 1px solid #9c948c;
    box-sizing: border-box;
    &:hover {
      border: 1px solid #9c948cdd;
      outline: hidden;
      background-color: #8f888133;
    }
  }

  .Select__control--is-focused {
    box-shadow: 0 0 0 1px black;
    outline: hidden;

    &:hover {
      outline: hidden;
      background-color: #f5f4f3;
    }
  }

  /* .Select__indicator-separator {
    display: none;
  }

  .Select__indicator {
    color: #9c948c;
    padding-right: 15px;
  } */

  .Select__menu {
    background-color: #fefefe;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    box-sizing: border-box;
    margin-top: 2px;
    border-radius: 5px;
    max-width: 200px;
  }
`

export const ResumeInputCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  height: 142px;
  width: ${(props: IStyleProps) => {
    if (props.small) return '206px'
    return '315px'
  }};

  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #00000040;

  background: ${(props: IStyleProps) => {
    if (props.green) return `${props.theme.card.backgroundColor.green}`
    if (props.secondary) return `${props.theme.card.backgroundColor.secondary}`
    return `${props.theme.card.backgroundColor.primary}`
  }};

  cursor: ${(props: IStyleProps) => {
    if (props.disabled) return ''
    return 'pointer'
  }};

  .title {
    color: ${(props: IStyleProps) => {
      // if (props.disabled) return `${props.theme.card.fontColor.disabled}`
      // if (props.secondary) return `${props.theme.card.fontColor.secondary}`
      return `${props.theme.card.fontColor.primary}`
    }};

    font-size: ${(props: IStyleProps) => {
      if (props.small) return '14px'
      return '16px'
    }};
    font-style: normal;
    font-weight: 700;

    text-align: left;
    line-height: ${(props: IStyleProps) => {
      if (props.small) return '16.41px'
      return '18.75px'
    }};
    letter-spacing: 1%;

    margin: 0 0 22px 25px;
  }

  .iconClickable {
    filter: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.icon.disabled}`
      if (props.secondary) return `${props.theme.card.icon.white}`
      if (props.green) return `${props.theme.card.icon.green}`
      return `${props.theme.card.icon.green}`
    }};
    max-width: 24px;
    height: 24px;
    margin: 0 0 12px 25px;
  }

  .editIcon {
    filter: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.icon.disabled}`
      if (props.secondary) return `${props.theme.card.icon.white}`
      if (props.green) return `${props.theme.card.icon.green}`
      return `${props.theme.card.icon.green}`
    }};
    max-width: 10px;
    height: 10px;
    margin: ${(props: IStyleProps) => {
      if (props.small) return '0 0 22px 175px'
      return '30px 0 22px 274px'
    }};
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;

    text-align: left;
    line-height: 21.09px;

    margin: 0 0 12px 12px;
    color: ${(props: IStyleProps) => {
      if (props.disabled) return `${props.theme.card.fontColor.disabled}`
      if (props.secondary) return `${props.theme.card.fontColor.secondary}`
      return `${props.theme.card.fontColor.primary}`
    }};
  }
`

export const TextDiv = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
`
