import { Dispatch, ReactElement, SetStateAction, useState } from "react"
import Dashboard from './Dashboard';
import Extrato from './Extrato';

interface ISaldoModal {
  data?: any
  extendedMenu?: any
  setExtendedMenu: Dispatch<SetStateAction<ReactElement | boolean>>
}

export default function GestaoFinancas(props: ISaldoModal) {
  const { setExtendedMenu, extendedMenu } = props;
  const [page, setPage] = useState<String>('home');

  switch (page) {
    case 'extract':
      return <Extrato setExtendedMenu={setExtendedMenu}  setPage={setPage} extendedMenu={extendedMenu}/>
    default:
      return <Dashboard setExtendedMenu={setExtendedMenu} setPage={setPage} />
  }
}
