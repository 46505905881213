import styled from 'styled-components'

export const CardsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 50px;
`

export const Span = styled.div`
  width: 475px;
  height: 17px;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 1%;
  margin-bottom: 30px;
`
