import { useRef, useEffect } from 'react'

import { Props as SelectProps, StylesConfig } from 'react-select'
import { useField } from '@unform/core'

import { Background } from 'components/Modal/style'
import { InputError, Select, SelectContainer } from './style'

interface ISelectProps extends SelectProps {
  name: string
  icon?: string
  placeholder?: any
  disabled?: boolean
  value?: any
  small?: boolean
  xsmall?: boolean
  large?: boolean
  marginRight?: number
}

interface ColourOption {
  readonly isDisabled?: boolean
}

export default function SelectComponent(props: ISelectProps) {
  const { name, placeholder, isMulti, disabled, small } = props
  const {
    defaultValue: _defaultValue,
    onChange: _onChange,
    ...inputErrorProps
  } = props
  const selectRef: any = useRef<HTMLSelectElement>(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  const colourStyles: StylesConfig<ColourOption> = {
    control: (styles) => ({
      ...styles,
      backgroundColor: disabled ? 'lightGray !important' : '#fbfbfb',
      placeholder: disabled ? 'white !important' : '#9c948c',
      border: disabled ? '0 !important' : '1px solid #9c948c',
      color: disabled ? 'rgb(84, 84, 84) !important' : '#9c948c',
    }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? '#cdcac5 !important' : '#fbfbfb',
        color: isSelected ? '#ffffff !important' : '#9c948c',
        padding: '5px',
      }
    },
    menuList: (base) => ({
      ...base,
      width: small ? '160px' : '325px',
      '::-webkit-scrollbar': {
        width: '8px',
        backgroundColor: '#C4C4C4',
        borderRadius: '50px',
      },
      '::-webkit-scrollbar-track': {
        background: '#fbfbfb',
        border: 0,
      },
      '::-webkit-scrollbar-thumb': {
        width: '8px',
        backgroundColor: '#C4C4C4',
        borderRadius: '50px',
      },
    }),
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (isMulti) {
          if (!ref.state.selectValue) {
            return []
          }
          return ref.state.selectValue.map((option: any) => option.value)
        }
        if (!ref.state.selectValue[0]) {
          return ''
        }
        return ref.state.selectValue[0].value
      },
    })
  }, [fieldName, registerField, isMulti])

  return (
    <SelectContainer>
      <Select
        placeholder={placeholder || 'Selecione...'}
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="Select"
        error={error}
        styles={colourStyles}
        isDisabled={disabled}
        // value={disabled && ''}
        {...props}
      />
      <InputError {...inputErrorProps}>{error}</InputError>
    </SelectContainer>
  )
}
