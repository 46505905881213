import { ErrorMessage, Row } from 'styles'
import {
  PageTitle,
  PagePathing,
  ButtonNext,
  ResumeInputCard,
  ResumeSelectCard,
  LoadingBanner,
} from 'components'
import { IMask } from 'react-imask'
import { Form } from '@unform/web'
import { useContext, useRef, useState } from 'react'
import { SapiensContext } from 'context/sapiens'
import * as Yup from 'yup'
import { icons } from 'helper'
import { useFetch } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { clearString } from 'helper/utils'
import { FormDiv, ScrollDiv } from './style'

export default function ResumoImovel({ handleBack }: any) {
  const { propertyData, setPropertyData, stateList, ownerData, setOwnerData } =
    useContext(SapiensContext)

  const formRef = useRef<HTMLFormElement | any>(null)
  const navigate = useNavigate()
  const [status, setStatus] = useState<string>('idle')
  const [errs, setError] = useState(false)

  const api = useFetch('property')

  const handleChangePage = async () => {
    try {
      setStatus('loading')
      const body = {
        propertyData: {
          ...propertyData,
          state: propertyData.state.value,
          type: propertyData.type.value,
        },
        ownerData: {
          ...ownerData,
          maritalStatus: ownerData.maritalStatus.value,
        },
      }
      const response = await api.fetch('post', body)
      if (!response.data) throw new Error()
      setStatus('loaded')
    } catch (err: any) {
      setStatus('idle')
      setError(true)
      if (err instanceof Yup.ValidationError) {
        const validationErrors: {
          [key: string]: { path: string; message: string }
        } = {}
        err.inner.forEach((error: any) => {
          validationErrors[error.path] = error.message
        })
        formRef.current.setErrors(validationErrors)
      } else {
        console.log(err)
      }
    }
  }

  const maritalList = [
    { label: 'Solteiro', value: 'solteiro' },
    { label: 'Casado', value: 'casado' },
  ]

  switch (status) {
    case 'loading':
      return <LoadingBanner status="loading" />
    case 'loaded':
      return (
        <LoadingBanner
          status="loaded"
          confirmationText="Imóvel cadastrado!"
          onFinished={() => {
            navigate('/gestaoImoveis')
          }}
        />
      )
    default:
      return (
        <div
          style={{
            opacity: status === 'loading' ? '0.4' : '1.0',
            height: '90%',
          }}
        >
          <PagePathing
            withoutMarginLeft
          >{`Gestão de imóveis > Novo imóvel > Residencial`}</PagePathing>
          <PageTitle withoutMarginLeft>Resumo da solicitação</PageTitle>
          <ScrollDiv>
            <FormDiv>
              <Form onSubmit={handleChangePage} ref={formRef}>
                <PageTitle withoutMarginLeft>
                  <img
                    className="titleIcon"
                    src={icons.homeSolid}
                    alt="first title icon"
                  />
                  Endereço do imóvel
                </PageTitle>
                <Row gap={15} className="row">
                  <ResumeSelectCard
                    name={propertyData.type.label || ''}
                    icon="mailOutlined"
                    options={[{ label: 'Residencial', value: 'residencial' }]}
                    handleChange={(e: any) =>
                      setPropertyData({
                        ...propertyData,
                        type: e,
                      })
                    }
                  >
                    Tipo de imóvel
                  </ResumeSelectCard>
                  <ResumeInputCard
                    isMask="00000-000"
                    handleChangeInput={(e: any) =>
                      setPropertyData({
                        ...propertyData,
                        zipcode: clearString(e),
                      })
                    }
                    names="resumo-meta-captacao"
                    small
                    icon="calendarOutlined"
                    placeholder={propertyData.zipcode || ''}
                  >
                    CEP
                  </ResumeInputCard>
                  <ResumeInputCard
                    handleChangeInput={(e: any) =>
                      setPropertyData({
                        ...propertyData,
                        addressNumber: e,
                      })
                    }
                    names="resumo-meta-captacao"
                    small
                    icon="calendarOutlined"
                    placeholder={propertyData.addressNumber}
                  >
                    Número
                  </ResumeInputCard>
                  <ResumeInputCard
                    handleChangeInput={(e: any) =>
                      setPropertyData({
                        ...propertyData,
                        complement: e,
                      })
                    }
                    names="resumo-meta-captacao"
                    small
                    icon="calendarOutlined"
                    placeholder={propertyData.complement}
                  >
                    Complemento
                  </ResumeInputCard>
                </Row>
                <Row gap={15} className="row">
                  <ResumeInputCard
                    handleChangeInput={(e: any) =>
                      setPropertyData({
                        ...propertyData,
                        neighborhood: e,
                      })
                    }
                    names="resumo-meta-captacao"
                    small
                    icon="calendarOutlined"
                    placeholder={propertyData.neighborhood}
                  >
                    Bairro
                  </ResumeInputCard>
                  <ResumeInputCard
                    handleChangeInput={(e: any) =>
                      setPropertyData({
                        ...propertyData,
                        address: e,
                      })
                    }
                    names="resumo-meta-captacao"
                    icon="mailOutlined"
                    placeholder={propertyData.address}
                  >
                    Endereço
                  </ResumeInputCard>
                  <ResumeSelectCard
                    name={propertyData.state.label || ''}
                    small
                    icon="calendarOutlined"
                    options={stateList}
                    handleChange={(e: any) =>
                      setPropertyData({
                        ...propertyData,
                        state: e,
                      })
                    }
                  >
                    UF
                  </ResumeSelectCard>
                  <ResumeInputCard
                    handleChangeInput={(e: any) =>
                      setPropertyData({
                        ...propertyData,
                        city: e,
                      })
                    }
                    names="resumo-meta-captacao"
                    small
                    icon="calendarOutlined"
                    placeholder={propertyData.city}
                  >
                    Cidade
                  </ResumeInputCard>
                </Row>
                <Row>
                  <ResumeInputCard
                    handleChangeInput={(e: any) =>
                      setPropertyData({
                        ...propertyData,
                        code: e,
                      })
                    }
                    names="resumo-meta-captacao"
                    small
                    icon="calendarOutlined"
                    placeholder={propertyData.code}
                  >
                    Código do imóvel
                  </ResumeInputCard>
                </Row>
                <PageTitle withoutMarginLeft>
                  <img
                    className="titleIcon"
                    src={icons.clientOutlined}
                    alt="first title icon"
                  />
                  Dados do proprietário
                </PageTitle>
                <Row gap={15} className="row">
                  <ResumeInputCard
                    handleChangeInput={(e: any) =>
                      setOwnerData({
                        ...ownerData,
                        name: e,
                      })
                    }
                    names="resumo-meta-captacao"
                    icon="mailOutlined"
                    placeholder={ownerData.name}
                  >
                    Nome
                  </ResumeInputCard>
                  <ResumeInputCard
                    isMask="000.000.000-00"
                    small
                    handleChangeInput={(e: any) =>
                      setOwnerData({
                        ...ownerData,
                        documentNumber: clearString(e),
                      })
                    }
                    names="resumo-meta-captacao"
                    icon="calendarOutlined"
                    placeholder={ownerData.documentNumber}
                  >
                    CPF
                  </ResumeInputCard>
                  <ResumeInputCard
                    isMask="d/m/Y"
                    blocks={{
                      d: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 31,
                        maxLength: 2,
                      },
                      m: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 12,
                        maxLength: 2,
                      },
                      Y: {
                        mask: IMask.MaskedRange,
                        from: 1900,
                        to: new Date().getFullYear() - 18,
                      },
                    }}
                    small
                    handleChangeInput={(e: any) =>
                      setOwnerData({
                        ...ownerData,
                        birthday: e,
                      })
                    }
                    names="resumo-meta-captacao"
                    icon="calendarOutlined"
                    placeholder={ownerData.birthday}
                  >
                    Data de nascimento
                  </ResumeInputCard>
                </Row>
                <Row gap={15} className="row">
                  <ResumeInputCard
                    handleChangeInput={(e: any) =>
                      setOwnerData({
                        ...ownerData,
                        mainEmail: e,
                      })
                    }
                    names="resumo-meta-captacao"
                    icon="mailOutlined"
                    placeholder={ownerData.mainEmail}
                  >
                    E-mail
                  </ResumeInputCard>
                  <ResumeInputCard
                    isMask="(00) 0 0000-0000"
                    handleChangeInput={(e: any) =>
                      setOwnerData({
                        ...ownerData,
                        mainPhone: clearString(e),
                      })
                    }
                    names="resumo-meta-captacao"
                    icon="phoneOutlined"
                    placeholder={ownerData.mainPhone}
                  >
                    Telefone
                  </ResumeInputCard>
                  <ResumeSelectCard
                    small
                    name={ownerData.maritalStatus.label || ''}
                    icon="calendarOutlined"
                    options={maritalList}
                    handleChange={(e: any) =>
                      setOwnerData({
                        ...ownerData,
                        maritalStatus: e,
                      })
                    }
                  >
                    Estado civil
                  </ResumeSelectCard>
                </Row>
              </Form>
            </FormDiv>
          </ScrollDiv>
          <ButtonNext
            noSpaceBetween
            large
            static
            onClick={handleChangePage}
            onClickBack={handleBack}
            disabled={status === 'loading'}
          >
            CADASTRAR
          </ButtonNext>
          {errs && <ErrorMessage>Erro de conexão</ErrorMessage>}
        </div>
      )
  }
}
