import {
  PagePathing,
  RadioInput,
  ButtonNext,
  SecurityConfirm,
  LoadingBanner,
} from 'components/index'
import { useState, useRef, useEffect, useCallback } from 'react'
import { useFetch } from 'hooks'
import { Column, Row } from 'styles/index'
import { icons } from 'helper'

import {
  Container,
  Subtitle,
  Title,
  RadioContent,
  TinyText,
  Amount,
  AmountInput,
  AmountContainer,
  TinyRadioText,
  Trash,
  TrashContainer,
  Err,
} from './style'
import ExcedeLimite from '../ExcedeLimite'
import { numberToMoney } from 'helper/utils'
import ImportanteTED from '../ImportanteTED'
import uuid from 'react-uuid'

export default function Transferir() {
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [paymentLabel, setPaymentLabel] = useState<string>()
  const [status, setStatus] = useState<string>('idle')
  const [pageNumber, setPageNumber] = useState<number>(2)
  const [available, setAvailable] = useState<number>(0)
  const [error, setError] = useState<string>()
  const [amount, setAmount] = useState<number>(0)

  const [assets, setAssets] = useState<any[]>([])
  const [keyList, setKeyList] = useState<any[]>([])
  const [source, setSource] = useState<any[]>([])

  const requestAPI = useFetch('transaction')
  const accAPI = useFetch('transaction/accounts')

  const inputRef = useRef<any>(null)
  const requestRef = useRef({
    amount: 0,
    requestType: '',
    to: [0,0],
  })

  const balanceValidation = () => (amount > (Number(available) * 100))

  const request = async() => {
    setAvailable(0)
    accAPI
      .fetch('get')
      .then((response: any) => {
        const { sources, accountList } = response.data;
        setAssets(sources.map((item: any) => ({
          id: uuid(),
          content: <RadioContent available={item.available}>{item.label}</RadioContent>,
          isInicial: item.isInicial,
          available: item.available,
          value: item.value,
        })));
        
        sources.map((item: any) => {
          if (item.isInicial) {
            requestRef.current.requestType = item.value;
            setAvailable(item.available);
          }
        })

        setSource(accountList.map((item: any, index: number) => ({
          id: uuid(),
          keyList: item.keyList,
          content: <RadioContent>{item.type}</RadioContent>,
          transferType: item.type,
          keyLabel: item.label,
          value: index,
          available: true,
        })));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    request()
  }, [])

  const onSubmit = (pin: any) => {
    const body = { ...pin, ...requestRef.current };
    setError('');
    setStatus('loading');
    requestAPI
      .fetch('post', body)
      .then((r: any) => {
        if (r.fail) throw new Error(r.errors);
        setStatus('loaded');
        setShowPasswordModal(false);
      })
      .catch((err) => {
        switch (Number(err.message.split(',')[1])) {
          case 416:
            setError('Saldo insuficiente');
            break;
          case 400:
            setError('Pin inválido');
            break;
          default:
            setError('Erro desconhecido');
            break;
        }
      })
      .finally(() => {
        request();
        setStatus('iddle');
      })
  }

  const deleteAccount = async (index: number) => {
    const [src] = requestRef.current.to;
    const data = btoa(JSON.stringify([src, index]));
    
    accAPI
      .fetch('delete', {}, `transaction/account/${data}`)
        .then(() => {
          setKeyList((curList) => curList.splice(index, 1));
        })
        .catch((err) => {
          console.error(err.message);
        })
  }


  const pages = [
    <ImportanteTED
      onConfirm={() => { setPageNumber(2) }}
      onModalClose={() => { setShowPasswordModal(false) }}
    />,
    <ExcedeLimite
      onModalClose={() => {
        setPageNumber(2);
        setShowPasswordModal(false);
      }}
    />,
    <SecurityConfirm
      onModalClose={() => { setShowPasswordModal(false) }}
      onClick={onSubmit}
      isLoading={status === 'loading'}
      error={error}
    />,
  ];

  switch (status) {
    case 'loading':
      return <LoadingBanner status="loading" />
    case 'loaded':
      return (
        <LoadingBanner
          status="loaded"
          confirmationText="Transferência realizada!"
          onFinished={() => { setStatus('idle') }}
        />
      )
    default:
      return (
        <Container>
          <>
            <PagePathing
              withoutMarginLeft
            >{`Dashboard > Saldo > Sacar`}</PagePathing>
            <Title>Sacar</Title>
            <Subtitle>De qual saldo você quer sacar?</Subtitle>
            <RadioInput
              selected={assets.findIndex((item) => (item.isInicial))}
              row
              options={assets}
              onSelect={(c: any) => {
                requestRef.current.requestType = c.value;
                setAvailable(c.available);
              }}
            />
            <Subtitle marginTop={70} marginBottom={10}>Qual valor você quer sacar?</Subtitle>
            <TinyText err={balanceValidation()}>
              Voce possui R$ {numberToMoney(available)} disponíveis para o saque
            </TinyText>
            <AmountContainer>
              <Amount err={balanceValidation()} >R$</Amount>
              <AmountInput
                err={balanceValidation()}
                max={300}
                className="masked-input"
                ref={inputRef}
                mask="num"
                blocks={{
                  num: {
                    mask: Number,
                    thousandsSeparator: '.',
                    scale: 2,
                    signed: true,
                    padFractionalZeros: true,
                    normalizeZeros: true,
                    radix: ',',
                    mapToRadix: ['.'],
                  },
                }}
                placeholder={'0,00'}
                onAccept={(e: any) => {
                  e = Number(e.replace(/[.]/g, '').replace(/[,]/g, '.')) * 100;
                  requestRef.current.amount = e;
                  setAmount(e)
                }}
              />
            </AmountContainer>
            { balanceValidation() ? (<Err>Você excedeu o limite</Err>) : <Err></Err>}
            <Subtitle
              marginTop={1}
              marginBottom={40}
            >{`Qual tipo de transferência você quer realizar?`}</Subtitle>
            <RadioInput
              options={source}
              row
              onSelect={(e: any) => {
                setPageNumber(e.transferType === 'TED' ? 0 : 2);
                setKeyList(e.keyList);
                setPaymentLabel(e.keyLabel);
                const [_, s] = requestRef.current.to;
                requestRef.current.to = [e.value, s];
              }}
            />
            {
              keyList.length > 0 && (
                <>
                  <Subtitle marginTop={70} marginBottom={40}>
                    {`Qual ${paymentLabel} você quer utilizar?`}
                  </Subtitle>
                  <Row style={{ width: '100%', justifyContent: 'flex-start' }}>
                    <RadioInput
                      selected={0}
                      options={keyList.map(([label, value], index: number) => (
                        {
                          id: uuid(),
                          content: (
                            <Column>
                              <RadioContent style={{ fontWeight: 600 }}>{label}</RadioContent>
                              <TinyRadioText>{value}</TinyRadioText>
                            </Column>
                          ),
                          available: true,
                          value: index,
                          type: 'account',
                        }
                      ))}
                      onSelect={(e: any) => {
                        const [s] = requestRef.current.to;
                        requestRef.current.to = [s, e.value];
                      }}
                    />
                    <TrashContainer>
                      {keyList.map((_, index) => (
                        <Trash
                          key={uuid()}
                          type="button"
                          onClick={() => deleteAccount(index)}
                        >
                          <img
                            className="icon"
                            src={icons.trashOutlined}
                            alt="trash icon"
                          />
                        </Trash>
                      )
                      )}
                    </TrashContainer>
                  </Row>
                </>
              )
            }
            <div style={{ marginTop: 40 }}>
              <ButtonNext
                onClick={() => {
                  setError('');
                  if (
                    requestRef.current.amount >= (100000 * 100) &&
                    String(paymentLabel).search(/pix/i) > -1
                  ) setPageNumber(1);
                  setShowPasswordModal(true);
                }}
                isExpensible
                notFixed
                disabled={amount === 0 || keyList.length === 0 || balanceValidation()}
              >
                CONTINUAR
              </ButtonNext>
            </div>
          </>
          {showPasswordModal && pages[pageNumber]}
        </Container>
      )
  }
}
