import { PagePathing, PageTitle, KanbanSubMenu } from 'components'
import { SapiensContext } from 'context'
import { useContext } from 'react'
import { Content } from 'styles'
import { icons } from 'helper'
import Kanban from './Kanban'

export default function GestaoContratos() {
  const { extended } = useContext(SapiensContext)

  const categories = [
    ['Usuários', `/gestaoUsuarios`, icons.client2Outlined, true],
    ['Novo usuário', '/gestaoUsuarios/novoUsuario', icons.plusSolid, false],
  ]

  return (
    <Content extended={extended}>
      <div>
        <PagePathing>Gestão de usuários</PagePathing>
        <PageTitle>Usuários</PageTitle>
        <KanbanSubMenu categories={categories} />
      </div>
      <Kanban />
    </Content>
  )
}
