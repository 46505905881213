import styled from 'styled-components'

interface IStyleProps {
  status: string
}

export const AccountStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: none;
  gap: 20px;
  margin-top: 8px;

  .row {
    display: flex;
    flex-direction: row;
    width: 150px;
    justify-content: flex-start;
    align-items: center;
  }

  .ball-icon {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    color: red;
    margin-right: 10px;
  }

  img {
    margin-right: 10px;

    filter: ${(props: IStyleProps) => {
      if (props.status === 'aberta')
        return 'invert(79%) sepia(71%) saturate(3606%) hue-rotate(90deg) brightness(109%) contrast(92%)'

      if (props.status === 'fechada')
        return 'invert(17%) sepia(80%) saturate(4105%) hue-rotate(325deg) brightness(93%) contrast(109%)'
      return ''
    }};
  }
`
