import { Row } from 'styles'
import {
  Select,
  PageTitle,
  PagePathing,
  Input,
  InsuranceCard,
  ButtonNext,
  LoadingBanner,
  MaskedInput,
} from 'components'
import { Form } from '@unform/web'
import { useEffect, useRef, useState } from 'react'
import useFetch from 'hooks/useFetch'
import { icons } from 'helper'
import { maskCPForCNPJ } from 'helper/utils'
import { IObject } from 'interfaces'
import { FormDiv, CardsDiv, Span, ScrollDiv } from './style'

const debtClasses = [
  { id: 0, label: 'Aluguel', name: 'rent', max: 12, qtd: 0, value: 0 },
  {
    id: 1,
    label: 'Condomínio',
    name: 'condominium',
    max: 12,
    qtd: 0,
    value: 0,
  },
  { id: 2, label: 'IPTU', name: 'iptu', max: 12, qtd: 0, value: 0 },
  {
    id: 3,
    label: 'Energia elétrica',
    name: 'light',
    max: 12,
    qtd: 0,
    value: 0,
  },
  { id: 4, label: 'Reparos', name: 'repair', max: 12, qtd: 0, value: 0 },
  { id: 5, label: 'Gás', name: 'gas', max: 12, qtd: 0, value: 0 },
  {
    id: 6,
    label: 'Outros',
    name: 'other',
    qtd: 0,
    value: 0,
  },
]

export default function Request(props: IObject) {
  const { onSubmit = () => null } = props
  const [clientData, setClientData] = useState<any>([])
  const [clientSelected, setClientSelected] = useState<IObject>()
  const [contractSelected, setContractSelected] = useState<IObject>()
  const [selected, setSelected] = useState<any>([])
  const [lodgerList, setLodgerList] = useState<any>([])
  const [propertyList, setPropertyList] = useState<any>([])
  const [status, setStatus] = useState<string>('idle')
  const api = useFetch('contract')
  const apiRentals = useFetch('guarantee')

  const formRef = useRef<HTMLFormElement | any>(null)
  const usernameRef = useRef<any>(null)

  const chooseOption = (val: any) => {
    if (selected.includes(val)) {
      setSelected((cur: []) => {
        return cur.filter((value: any) => value !== val)
      })
    } else {
      setSelected((cur: []) => [...cur, val])
    }
  }

  const onSelectContractType = () => {
    if (!clientData) throw new Error('No data received')
    setLodgerList(
      clientData
        .map((item: any) => ({
          label: maskCPForCNPJ(item?.lodger?.documentNumber),
          value: item?.lodger?.id,
          name: `${item?.lodger?.name} ${item?.lodger?.lastName}`,
        }))
        .filter(
          (obj: IObject, i: number, self: [IObject]) =>
            i === self.findIndex((el: IObject) => el?.value === obj?.value)
        )
    )
  }

  const onSelectClient = (client: any) => {
    setClientSelected(client)
    setPropertyList(
      clientData
        .filter((item: IObject) => {
          return item?.lodger?.id === client.value
        })
        .map((item: IObject) => ({
          label: item?.property?.code,
          value: item?.id,
          contractId: item?.id?.split('-')[0],
        }))
    )
  }

  const onSelectProperty = (contract: any) => {
    setContractSelected(contract)
  }

  const submit = () => {
    setStatus('loading')
    const selectedCoverage = debtClasses
      .filter((item: IObject) => selected.includes(item.id))
      .map((item: IObject) => ({
        type: item.name,
        quantity: item.qtd ? Number(item.qtd) : undefined,
        amount: Number(item.value),
      }))
    const data = {
      type: 0, // Basic ou Pro
      contractId: contractSelected?.value,
      debtList: selectedCoverage,
    }
    // return console.log(data)

    apiRentals
      .fetch('post', data)
      .then(() => {
        setStatus('loaded')
      })
      .catch(() => {
        // TODO - Acrescentar falha
        setStatus('loaded')
      })
  }

  useEffect(() => {
    api.fetch('get').then((res) => {
      if (!res.data) throw new Error('No data received')
      setClientData(res.data)
    })
  }, [])

  switch (status) {
    case 'loading':
      return <LoadingBanner status="loading" />
    case 'loaded':
      return (
        <LoadingBanner
          confirmationText="Enviado para análise!"
          status="loaded"
          onFinished={() => {
            onSubmit()
          }}
        />
      )
    default:
      return (
        <>
          <PagePathing
            withoutMarginLeft
          >{`Gestão de cobertura > Nova solicitação`}</PagePathing>
          <ScrollDiv>
            <FormDiv>
              <Form onSubmit={() => null} ref={formRef}>
                <PageTitle withoutMarginLeft>
                  <img
                    className="titleIcon"
                    src={icons.shieldOutlined}
                    alt="first title icon"
                  />
                  Informações da solicitação
                </PageTitle>
                <Row style={{ alignItems: 'flex-start' }} gap={15}>
                  <Select
                    name="coverageType"
                    placeholder="Tipo de cobertura"
                    options={[
                      {
                        label: 'Basic',
                        value: 0,
                      },
                    ]}
                    onChange={onSelectContractType}
                  />
                  <Select
                    options={lodgerList}
                    name="document"
                    placeholder="CPF do inquilino"
                    onChange={onSelectClient}
                  />
                </Row>
                <Row style={{ alignItems: 'flex-start' }} gap={15}>
                  <Input
                    value={clientSelected?.name}
                    type="text"
                    ref={usernameRef}
                    name="name"
                    disabled
                    placeholder="Nome do inquilino"
                  />
                  <Select
                    name="propertyCode"
                    placeholder="Código do imóvel"
                    options={propertyList}
                    onChange={onSelectProperty}
                  />
                  <Input
                    type="text"
                    disabled
                    name="contractId"
                    placeholder="Id do contrato"
                    value={contractSelected?.contractId}
                  />
                </Row>
                <PageTitle withoutMarginLeft>
                  <img
                    className="titleIcon"
                    src={icons.shieldOutlined}
                    alt="first title icon"
                  />
                  Classes de dívida
                </PageTitle>
                <Row>
                  <CardsDiv style={{ width: '100%', paddingLeft: 3 }}>
                    {debtClasses.map((item: any, i: number) => (
                      <div style={{ margin: 0 }} key={item.id}>
                        <InsuranceCard
                          green={selected?.includes(item.id)}
                          icon="homeSolid"
                          id={item.id}
                          onSelect={() => chooseOption(item.id)}
                        >
                          {item.label}
                        </InsuranceCard>
                        {selected?.includes(item.id) && (
                          <>
                            {item.max && (
                              <Span>
                                <Select
                                  small
                                  name={`amount_${item.name}`}
                                  placeholder="Quantidade"
                                  options={(() => {
                                    const arr = []
                                    for (let j = 0; j < item.max; j += 1) {
                                      arr.push({
                                        label: `${j + 1}`,
                                        value: j + 1,
                                      })
                                    }
                                    return arr
                                  })()}
                                  onChange={(e: any) => {
                                    debtClasses[i].qtd = e.value
                                  }}
                                />
                              </Span>
                            )}
                            <Span>
                              <MaskedInput
                                small
                                type="money"
                                name={`total_${item.name}`}
                                placeholder="Valor total"
                                handleChange={(e: string) => {
                                  e = e
                                    .replace('R$ ', '')
                                    .replace(/\./g, '')
                                    .replace(',', '.')
                                  debtClasses[i].value = Number(e)
                                }}
                              />
                            </Span>
                          </>
                        )}
                      </div>
                    ))}
                  </CardsDiv>
                </Row>
              </Form>
            </FormDiv>
          </ScrollDiv>
          <ButtonNext onClick={submit}>Enviar para análise</ButtonNext>
        </>
      )
  }
}
