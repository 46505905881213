import { useEffect, useRef } from 'react'
import { IObject } from 'interfaces'
import { icons } from 'helper'
import { LoadGif } from '..'
import { Content } from './style'

export default function Loading(props: IObject) {
  const { status, confirmationText, onFinished = () => null } = props
  const timer = useRef<any>()

  useEffect(() => {
    timer.current = setTimeout(() => {
      onFinished()
    }, 3000)

    return () => {
      clearInterval(timer?.current)
    }
  })

  switch (status) {
    case 'loading':
      return (
        <Content background="white">
          <LoadGif confirm black />
        </Content>
      )
    case 'loaded':
      return (
        <Content background="#00cd52">
          <img src={icons.confirmationOutlined} alt="confirmIcon" />
          <p>{confirmationText}</p>
        </Content>
      )
    default:
      return <div />
  }
}
