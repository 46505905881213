import { SapiensContext } from 'context'
import icons from 'helper/icons'
import { ChangeEvent, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { SubMenu, MenuButton, SearchBar, SearchBarContainer } from './style'

interface IProps {
  categories?: any[][]
}

export default function SubMenuComponent(props: IProps) {
  const location = useLocation()
  const { setSearchParam, setShowModalNewClient } = useContext(SapiensContext)

  const { categories = [] } = props

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchParam(e.target.value)
  }

  const onModalOpen = (ev: (_click: boolean) => boolean | string) => {
    if (typeof ev === 'function') return ev(true)
    return setShowModalNewClient(true)
  }

  const onRedirect = (to: any) => {
    if (typeof to === 'string') return to
    return '.'
  }

  const isActive = (to: any) => {
    if (typeof to === 'function') return false
    const [page] = location.pathname.split('/').reverse()
    const [curPage] = to.split('/').reverse()
    return page === curPage
  }

  return (
    <SubMenu>
      <SearchBarContainer>
        <img
          src={icons.filterOutlined}
          alt="search icon"
          className="leftIcon"
        />
        <SearchBar placeholder="Filtrar" onChange={handleSearch} />
      </SearchBarContainer>
      {categories.map(
        ([
          label,
          to = 'gestaoContratos',
          icon = icons.clientPFSolid,
          // isModal,
        ]) => (
          <MenuButton
            to={onRedirect(to)}
            key={`item_${to}`}
            active={isActive(to)}
            state={{ from: location }}
            onClick={() => onModalOpen(to)}
          >
            <img src={icon} alt="search icon" />
            {label}
          </MenuButton>
        )
      )}
    </SubMenu>
  )
}
