import { icons } from 'helper'
import PageTitle from './style'

interface IPageTitleComponent {
  withoutMarginLeft?: boolean
  marginBottom?: boolean
  children: any
  icon?: string
}

export default function PageTitleComponent(props: IPageTitleComponent) {
  const { children, icon } = props
  return icon ? (
    <PageTitle {...props}>
      <img src={icons[icon]} alt="icon"/>
      {children}
    </PageTitle>
  ) : (
    <PageTitle {...props}>{children}</PageTitle>
  )

}
