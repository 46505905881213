import styled from 'styled-components'

interface IStyleProps {
  signal: string
  onClick?: any
}

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 27px 0 34px 30px;
  width: calc(315px - 30px);
  /* height: calc(142px - 61px); */
  min-height: 81px;
  background: #fdfdfd;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: ${(props: IStyleProps) => {
    return props.onClick ? 'pointer' : 'default';
  }};
  .value {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: ${(props: IStyleProps) => {
      if (props.signal === '+') return '#0BF569'
      return '#F60665'
    }};
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    padding: 0;
  }

  .icon {
    margin-right: 10px;
  }

  .bold {
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`
