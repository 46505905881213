import { MouseEvent } from 'react'
import { icons } from 'helper'
import { ButtonNext, ButtonsContainer, Background } from './style'

interface IButtonNextProps {
  onClick?: (_e: MouseEvent<HTMLElement>) => void
  onClickBack?: (_e: MouseEvent<HTMLElement>) => void
  disabled?: boolean
  static?: boolean
  children?: string
  large?: boolean
  alsoBack?: boolean
  noSpaceBetween?: boolean
  isExpensible?: boolean 
  notFixed?: boolean
  justBack?: boolean
  marginBottom?: string
}

export default function btnNext(props: IButtonNextProps) {
  const { alsoBack, onClickBack, isExpensible = false, notFixed = false, justBack, marginBottom } = props
  return (
    <Background notFixed={notFixed} isExpensible={isExpensible} marginBottom={marginBottom}>
      <ButtonsContainer>
        <div>
          {alsoBack && (
            <div className="circle-new" onClick={onClickBack} role="button">
              <img
                src={icons.arrowRightSolid}
                alt="button icon back"
                className="button-back"
              />
            </div>
          )}
        </div>
        <ButtonNext {...props}>
          {props.children || 'AVANÇAR'}
          {!justBack && <div className="circle" role="button">
            <img src={icons.arrowRightSolid} alt="button icon" />
          </div>}
        </ButtonNext>
      </ButtonsContainer>
    </Background>
  )
}
