import styled from 'styled-components'

export const TextSpan = styled.span`
  max-width: 231px;
  height: 38px;
  color: #070707;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 18.75px;
  text-align: center;
`
