import styled from 'styled-components'

interface IStyleProps {
  gap?: number
  width?: string
}

export default styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${(props: IStyleProps) => {
    if (props.gap) return `${props.gap}px`
    return '0'
  }};
  width: ${(props: IStyleProps) => {
    if (props.width) return `${props.width}`
    return ''
  }};
`
