import { ErrorMessage, Row } from 'styles'
import {
  Select,
  PageTitle,
  PagePathing,
  Input,
  ButtonNext,
  RadioButton,
  MaskedInput,
  LoadingBanner,
} from 'components'
import { IObject } from 'interfaces'
import { Form } from '@unform/web'
import { useContext, useEffect, useRef, useState } from 'react'
import { useFetch } from 'hooks'
import { SapiensContext } from 'context/sapiens'
import * as Yup from 'yup'
import { icons } from 'helper'
import { useNavigate } from 'react-router-dom'
import { FormDiv, ScrollDiv } from './style'
import { formatDate } from 'helper/utils'

const schemResident = Yup.object().shape({
  name: Yup.string().min(3).required('campo obrigatório: nome'),
  documentNumber: Yup.string()
    .min(14)
    .max(14)
    .required('campo obrigatório: cpf'),
  birthday: Yup.string()
    .min(10)
    .required('campo obrigatório: data de nascimento'),
  mainPhone: Yup.string()
    .min(15)
    .max(15)
    .required('campo obrigatório: telefone'),
  mainEmail: Yup.string().min(3).required('campo obrigatório: email'),
})

const schemaInfoCorresponsavel = Yup.object().shape({
  name: Yup.string().min(3).required('campo obrigatório: nome'),
  documentNumber: Yup.string()
    .min(14)
    .max(14)
    .required('campo obrigatório: cpf'),
  birthday: Yup.string()
    .min(10)
    .required('campo obrigatório: data de nascimento'),
  mainPhone: Yup.string()
    .min(15)
    .max(15)
    .required('campo obrigatório: telefone'),
  mainEmail: Yup.string().min(3).required('campo obrigatório: email'),
})

export default function Residencial({ handleBack }: any) {
  const [infoCorresponsavel, setInfoCorresponsavel] = useState<IObject>({})

  const { contract, property, residents, setResidents } =
    useContext(SapiensContext)

  const [errs, setError] = useState(false)
  const [status, setStatus] = useState<string>('idle')

  const api = useFetch('contract')
  const navigate = useNavigate()

  const formRef = useRef<HTMLFormElement | any>(null)

  const handleChangePage = async () => {
    setStatus('loading')
    try {
      const [inquilino] = residents
      try {
        setResidents((r: any) => {
          const [_, ...newResidents] = r
          return newResidents
        })
      } catch (err: any) {
        setStatus('idle')
        if (err instanceof Yup.ValidationError) {
          const validationErrors: {
            [key: string]: { path: string; message: string }
          } = {}
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        } else {
          console.error(err)
        }
      }

      const data = {
        contractData: {
          ...contract,
          contractType: contract.contractType.value,
          coverageType: contract.coverageType.value,
          installmentsCard: contract.installmentsCard.value,
          paymentType: contract.paymentType.value,
          residentsNumber: residents.length,
        },
        propertyData: {
          ...property,
          type: property.type.value,
          state: property.state.value,
        },
        clientData: inquilino,
        residentsData: [...residents, infoCorresponsavel].map((item) => {return { ...item, birthday: formatDate(item.birthday)}}),
      }

      api.fetch('post', data)
      .then(() => setStatus('loaded'))
      .catch((r: any) => {
        if (r.fail) throw new Error(r.errors);
      })
    } catch (err: any) {
      console.log(err);
      setStatus('idle')
      setError(true)
    }
  }

  useEffect(() => {
    const validateInputs = async () => {
      try {
        if (residents.length === 1) {
          try {
            await schemaInfoCorresponsavel?.validate(infoCorresponsavel, {
              abortEarly: false,
            })
          } catch (err: any) {
            console.log(err.message)
          }
        } else {
          residents.forEach(async (element: any) => {
            try {
              await schemResident.validate(element, {
                abortEarly: false,
              })
            } catch (err: any) {
              console.log(err?.message)
            }
          })
          let hasCorresponsavel = false
          residents.forEach((resident: any) => {
            if (resident.corresponsavel) hasCorresponsavel = true
          })
          if (!hasCorresponsavel) {
            try {
              await schemaInfoCorresponsavel?.validate(infoCorresponsavel, {
                abortEarly: false,
              })
            } catch (err: any) {
              console.log(err?.message)
            }
          }
        }
      } catch (err: any) {
        console.log(err?.message)
      }
    }
    validateInputs()
  }, [residents, infoCorresponsavel])

  const changeHandler = (i: number, e: any, corresponsavel = false) => {
    const { name, value } = e

    const newValue = name === 'corresponsavel' ? corresponsavel : value

    setResidents((curr: any) => {
      if (name === 'corresponsavel') {
        return curr.map((c: any, index: number) => {
          if (index === i) {
            return {
              ...c,
              [name]: newValue,
            }
          }
          return {
            ...c,
            [name]: false,
          }
        })
      }
      return curr.map((c: any, index: number) => {
        if (index === i) {
          return {
            ...c,
            [name]: newValue,
          }
        }
        return c
      })
    })
  }

  const setResidentsList = (e: any) => {
    const newResidents = [residents[0]]
    for (let i = 1; i < e.value; i++) {
      newResidents.push({
        name: '',
        documentNumber: '',
        birthday: '',
        mainEmail: '',
        mainPhone: '',
        documentType: 'CPF',
        isCoresponsible: false,
      })
    }
    setResidents(newResidents)
  }

  const optionsresidents = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
  ]

  const renderCorresponsavel = () => {
    const resident = residents.find((c: any) => c.corresponsavel)
    return (
      <>
        <PageTitle withoutMarginLeft>
          <img
            className="titleIcon"
            src={icons.clientOutlined}
            alt="first title icon"
          />
          Corresponsável&nbsp;
          <span
            style={{ fontWeight: '300', fontSize: 12, alignSelf: 'flex-end' }}
          >
            (opcional)
          </span>
        </PageTitle>
        <Row style={{ alignItems: 'flex-start' }} gap={15}>
          <Input
            disabled={resident?.corresponsavel}
            type="text"
            name="name"
            placeholder={resident?.name || 'Nome'}
            value={resident?.name || infoCorresponsavel?.name}
            onChange={(e: any) =>
              setInfoCorresponsavel({
                ...infoCorresponsavel,
                name: e.target.value,
              })
            }
          />
          <MaskedInput
            smallPad
            disabled={resident?.corresponsavel}
            small
            type="cpf"
            handleChange={(e: any) => {
              setInfoCorresponsavel({
                ...infoCorresponsavel,
                documentNumber: e,
              })
            }}
            placeholder={resident?.documentNumber || 'CPF'}
            name="documentNumber"
            defaultValue={
              resident?.documentNumber || infoCorresponsavel?.documentNumber
            }
          />
          <MaskedInput
            smallPad
            disabled={resident?.corresponsavel}
            small
            type="birthday"
            handleChange={(e: any) => {
              setInfoCorresponsavel({
                ...infoCorresponsavel,
                birthday: e,
              })
            }}
            placeholder={resident?.birthday || 'Data nascimento'}
            name="birthday"
            defaultValue={resident?.birthday || infoCorresponsavel?.birthday}
          />
        </Row>
        <Row style={{ alignItems: 'flex-start' }} gap={15}>
          <MaskedInput
            disabled={resident?.corresponsavel}
            type="cell"
            handleChange={(e: any) => {
              setInfoCorresponsavel({
                ...infoCorresponsavel,
                mainPhone: e,
              })
            }}
            name="phoneNumber"
            placeholder={resident?.mainPhone || 'Telefone'}
            defaultValue={resident?.mainPhone || infoCorresponsavel?.mainPhone}
          />
          <Input
            disabled={resident?.corresponsavel}
            type="text"
            name="mainEmail"
            placeholder={resident?.mainEmail || 'Email'}
            value={resident?.mainEmail || infoCorresponsavel?.mainEmail}
            onChange={(e: any) =>
              setInfoCorresponsavel({
                ...infoCorresponsavel,
                mainEmail: e.target.value,
              })
            }
          />
        </Row>
      </>
    )
  }

  switch (status) {
    case 'loading': // Página branca de loading
      return <LoadingBanner status="loading" />
    case 'loaded': // Página verde de confirmação
      return (
        <LoadingBanner
          status="loaded"
          confirmationText="Contrato confirmado!"
          onFinished={() => {
            navigate('/gestaoContratos')
          }}
        />
      )
    default: // Página que o cliente está renderizando
      return (
        <div
          style={{
            opacity: status === 'loading' ? '0.4' : '1.0',
            height: '100%',
          }}
        >
          <PagePathing
            withoutMarginLeft
          >{`Gestão de inquilinos > Novo inquilino > Cadastrar imóvel residencial > Seguro`}</PagePathing>
          <ScrollDiv>
            <FormDiv>
              <Form onSubmit={handleChangePage} ref={formRef}>
                <PageTitle withoutMarginLeft>
                  <img
                    className="titleIcon"
                    src={icons.shieldOutlined}
                    alt="first title icon"
                  />
                  Número de Moradores
                </PageTitle>
                <Row style={{ alignItems: 'flex-start' }} gap={15}>
                  <Select
                    name="imovelType"
                    placeholder={residents.length}
                    options={optionsresidents}
                    onChange={(e) => setResidentsList(e)}
                  />
                </Row>
                {residents?.map((morador: any, i: number) => {
                  if (i === 0) return ''
                  return (
                    <>
                      <PageTitle withoutMarginLeft>
                        <img
                          className="titleIcon"
                          src={icons.clientOutlined}
                          alt="first title icon"
                        />
                        {`Morador ${i + 1}`}
                      </PageTitle>
                      <Row style={{ alignItems: 'flex-start' }} gap={15}>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Nome"
                          onChange={(e: any) => changeHandler(i, e.target)}
                        />
                        <MaskedInput
                          smallPad
                          small
                          type="cpf"
                          handleChange={(e: any) => {
                            changeHandler(i, {
                              name: 'documentNumber',
                              value: e,
                            })
                          }}
                          name="documentNumber"
                          placeholder="CPF"
                        />
                        <MaskedInput
                          smallPad
                          small
                          type="birthday"
                          handleChange={(e: any) => {
                            changeHandler(i, { name: 'birthday', value: e })
                          }}
                          name="birthday"
                          placeholder="Data nascimento"
                        />
                      </Row>
                      <Row style={{ alignItems: 'flex-start' }} gap={15}>
                        <MaskedInput
                          smallPad
                          type="cell"
                          handleChange={(e: any) => {
                            changeHandler(i, { name: 'mainPhone', value: e })
                          }}
                          name="mainPhone"
                          placeholder="Telefone"
                        />
                        <Input
                          type="text"
                          name="mainEmail"
                          placeholder="Email"
                          onChange={(e: any) => changeHandler(i, e.target)}
                        />
                      </Row>
                      <RadioButton
                        checked={morador.corresponsavel}
                        name="corresponsavel"
                        value={morador.corresponsavel}
                        id={morador.documentNumber}
                        onClick={() =>
                          changeHandler(
                            i,
                            { name: 'corresponsavel' },
                            !morador.corresponsavel
                          )
                        }
                      >
                        Selecione caso o morador em questão também for
                        corresponsável
                      </RadioButton>
                    </>
                  )
                })}
                {renderCorresponsavel()}
              </Form>
            </FormDiv>
            {errs && <ErrorMessage>Erro de conexão</ErrorMessage>}
          </ScrollDiv>
          <ButtonNext
            large
            static
            onClick={handleChangePage}
            alsoBack
            onClickBack={handleBack}
          >
            ENVIAR PARA ANÁLISE
          </ButtonNext>
        </div>
      )
  }
}
