import { IMAGES } from 'configs/configs.js'

export interface IInputIcons {
  password: string[]
  eye: string[]
  upload: string[]
}

const inputIcons: IInputIcons = {
  password: [
    `${IMAGES.URL}/outlined/lockClosed.svg`,
    `${IMAGES.URL}/outlined/lockOpen.svg`,
  ],
  eye: [`${IMAGES.URL}/solid/eyeClosed.svg`, `${IMAGES.URL}/solid/eyeOpen.svg`],
  upload: [`${IMAGES.URL}/solid/upload.svg`, `${IMAGES.URL}/solid/upload.svg`],
}

export default inputIcons
