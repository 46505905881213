import { BugCard } from 'components'
import { SapiensContext } from 'context/sapiens'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'
import { CardsContainer } from 'styles'
import { Container, DivRow } from './style'

interface SelectList {
  label: string
  color: string
}

export default () => {
  const [selectList, setSelectetList] = useState<any>([
    {
      label: 'Aluguel-verde',
      value: 'Aluguel-verde',
      color: ' #0BF569',
    },
  ])
  const { newCard, setNewCard } = useContext(SapiensContext)
  const api = useFetch('person/report/list-topics')

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const list = await api.fetch('get')
        if (list.fail) throw new Error()
        const { data } = list
        const select = data.map((item: SelectList) => {
          return {
            label: item.label,
            value: item.label,
            color: item.color,
          }
        })
        setSelectetList(select)
      } catch (e) {
        console.error(e)
      }
    }
    fetchCards()
  }, [newCard])

  return (
    <Container>
      <p className="bold-text" style={{ marginBottom: '7px' }}>
        Relatar Problemas
      </p>
      <DivRow width={350} marginBottom={25}>
        <p className="bold-text">Relatório:</p>
        <button type="button" className="add" onClick={() => setNewCard(true)}>
          Adicionar +
        </button>
      </DivRow>
      <CardsContainer
        overflow
        scrollTrack="#f9f9f9"
        withoutMarginLeft
        width="800px"
        height="800px"
      >
        {newCard && <BugCard isNewCard selectList={selectList} type="report" />}
      </CardsContainer>
    </Container>
  )
}
