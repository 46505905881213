import { Dispatch, SetStateAction, useRef, ReactElement } from 'react'
import RightMenuComponent from './RightMenu'
import { Modal, Background, ChildrenContainer, CloseButton } from './style'
import { icons } from 'helper'

interface IModal {
  showModal: boolean
  setShowModal: (r: any) => void | Dispatch<SetStateAction<boolean>>
  children: JSX.Element
  closeButton?: boolean
  removeChildrenPadding?: boolean
  width?: number
  height?: number
  FS?: boolean
  hideMenu?: boolean

  extendedMenuData?: ReactElement
  zIndex?: number
  setExtendedMenu?: any
}

export default function ModalComponent(props: IModal) {
  const {
    showModal,
    children,
    setShowModal,
    hideMenu,
    extendedMenuData,
    removeChildrenPadding,
    zIndex,
    setExtendedMenu,
    closeButton
  } = props
  const backgroundRef = useRef<HTMLDivElement>(null)

  const handleClickBackground = (e: any) => {
    if (backgroundRef.current === e.target) {
      setShowModal(false)
    }
  }

  return showModal ? (
    <Background
      ref={backgroundRef}
      onClick={handleClickBackground}
      zIndex={zIndex}
    >
      <Modal {...props}>
        <ChildrenContainer removeChildrenPadding={removeChildrenPadding}>
        {closeButton && <CloseButton onClick={() => { setShowModal(false) }}>
        <img
          src={icons.xSolid}
          alt="Close Menu icon"
          className="closeModal"
        />
        </CloseButton>}
          {children}
        </ChildrenContainer>
        {!hideMenu && (
          <RightMenuComponent
            setShowModal={setShowModal}
            extendedMenuData={extendedMenuData}
            setExtendedMenu={setExtendedMenu}
            disable={!extendedMenuData}
          />
        )}
      </Modal>
    </Background>
  ) : null
}
