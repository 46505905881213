import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const SubMenu = styled.div`
  margin: 0 0 0 10px;
  width: 180px;
  height: 30px;
`

interface IButtonStyleProps {
  active?: boolean
}

export const MenuButton = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: 10px;
  font-style: normal;
  font-weight: ${(props: IButtonStyleProps) => {
    if (props.active) return '700'
    return '400'
  }};
  line-height: 15px;
  letter-spacing: 0.01em;
  text-align: left;

  color: ${(props: IButtonStyleProps) => {
    if (props.active) return '#f9f9f9'
    return '#4c4c4c'
  }};

  background-color: ${(props: IButtonStyleProps) => {
    if (props.active) return '#191919 !important'
    return '#f9f9f9'
  }};

  border: 1px solid #f5f4f3;
  border-right: 0;
  height: 30px;

  margin-left: 10px;

  overflow: hidden;
  width: 180px;

  img {
    transition: width 1s;
    margin: 0 10px 0 20px;
    width: 10px;
    height: 10px;

    filter: ${(props: IButtonStyleProps) => {
      if (props.active)
        return 'invert(96%) sepia(99%) saturate(1%) hue-rotate(167deg) brightness(104%) contrast(95%)'
      return 'invert(31%) sepia(0%) saturate(0%) hue-rotate(269deg) brightness(90%) contrast(91%)'
    }};
  }
`

export const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;

  box-sizing: border-box;
  padding: 10px 12px;

  margin-bottom: 20px;

  height: 40px;
  width: 180px;

  .leftIcon {
    position: relative;
    height: 25px;
    left: 0;
    margin-right: 12px;
    filter: invert(98%) sepia(1%) saturate(1173%) hue-rotate(259deg)
      brightness(113%) contrast(66%);
  }

  img {
    width: 13px;
    filter: invert(72%) sepia(89%) saturate(0%) hue-rotate(242deg)
      brightness(96%) contrast(104%);
  }
`

export const SearchBar = styled.input`
  height: 40px;
  width: 180px;

  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;

  color: #4c4c4c;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;

  ::placeholder {
    color: #d4d4d4;
  }
`
