import { Modal, Button } from "components"
import { Container, PageTitle, PageContent } from './style'
import impor from './importante.png'

interface IModalProps {
  onConfirm: () => void
  onModalClose?: (e?: any) => void
}

export default function ImportanteTED (props: IModalProps) {
  const { onConfirm, onModalClose } = props
  return (
    <Modal
      setShowModal={(e: any) => { onModalClose && onModalClose(e) }}
      closeButton
      showModal
      width={500}
      height={500}
      hideMenu
    >
      <Container>
        <PageTitle>Importante!</PageTitle>
        <PageContent>TEDs levam 1 dia útil para compensação.</PageContent>
        <img src={impor} alt="imagem_TED" height={211} />
        <Button rounded secondary onClick={onConfirm}>ENTENDI</Button>
      </Container>
    </Modal>
  )
}