import styled from 'styled-components'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
export const Title = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #070707;
`
