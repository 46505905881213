import { icons } from 'helper'
import { IIcons } from 'helper/icons'
import { Link } from 'react-router-dom'
import ClickableCard from './style'

interface ICardProps {
  children: string
  icon?: keyof IIcons
  green?: boolean
  secondary?: boolean
  disabled?: boolean
  onClick?: any
  to?: string
  boldTitle?: boolean
  smallIcon?: boolean
}

export default function ClickableCardComponent(props: ICardProps) {
  const { children, icon, to } = props
  return (
    <Link to={to || ''}>
      <ClickableCard {...props}>
        {icon && (
          <img className="iconClickable" src={icons[icon]} alt="card icon" />
        )}
        <span className="title">{children}</span>
      </ClickableCard>
    </Link>
  )
}
