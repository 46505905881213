import { Kanban, Modal } from 'components'
import { SapiensContext } from 'context'
import { IIcons } from 'helper/icons'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'
import { CardsDiv } from 'styles'
import DetalhesUsuario from '../DetalhesUsuario'

interface IKanbanItem {
  id: string
  values: { [key: string]: any }
  name: string
  document: string
  email: string
  moveable?: string[]
}
interface IKanbanColumns {
  id: string // Identificador da coluna
  name: string // Nome da coluna
  icon: keyof IIcons // Ícone da coluna
  items: IKanbanItem[] // Lista de cards
  color: string // Cor da coluna
  moveable?: boolean | string[] // Se os items da coluna são móveis ou para quais colunas os ítems podem ir
}

interface IKanbanData {
  [key: string]: IKanbanColumns
}

export default function ContratosKanban() {
  const [kanbanData, setKanbanData] = useState<IKanbanData>({})
  // const { setUserData } = useContext(SapiensContext)
  const [showModal, setShowModal] = useState(false)
  const [filteredData, setFilteredData] = useState({})

  const api = useFetch('person/list-people?format=kanban')
  // const [showModalDetalhes, setShowModalDetalhes] = useState(false)
  const { searchParam, setUserData } = useContext(SapiensContext)

  useEffect(() => {
    setShowModal(false)
    const fetchUsers = async () => {
      try {
        const response = await api.fetch('get')
        if (response.fail) throw new Error()
        setKanbanData(response.data)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUsers()
  }, [])

  useEffect(() => {
    setFilteredData(kanbanData)
  }, [kanbanData])

  useEffect(() => {
    const shownData = Object.entries(kanbanData).reduce(
      (obj: { [key: string]: IKanbanColumns }, [columnId, { items }]) => {
        obj[columnId] = {
          ...kanbanData[columnId],
          items: items.filter(
            (item) =>
              item?.name?.toLowerCase().includes(searchParam.toLowerCase()) ||
              item?.document
                ?.toLowerCase()
                .includes(searchParam.toLowerCase()) ||
              item?.email?.toLowerCase().includes(searchParam.toLowerCase())
          ),
        }
        return obj
      },
      {}
    )
    setFilteredData(shownData)
  }, [searchParam])

  const onCardClick = async (item: { [key: string]: any }) => {
    try {
      const response = await api.fetch('get', {}, `person/get-user/${item.id}`)
      if (response.fail) throw new Error()
      if (Object.keys(response.data).length === 0) return;
      setUserData({ ...response.data, id: item.id })
      setShowModal(true)
    } catch (e) {
      console.error(e)
    }
  }

  const onDropCard = async (id: string, columnId: number) => {
    try {
      const response = await api.fetch('put', {status: columnId },`person/${id}`)
      console.log(response)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <CardsDiv>
      <Kanban
        data={filteredData}
        setData={setKanbanData}
        onCardClick={onCardClick}
        onDropCard={onDropCard}
        withoutChatIcon
      />
      <Modal FS setShowModal={setShowModal} showModal={showModal}>
        <DetalhesUsuario />
      </Modal>
    </CardsDiv>
  )
}
