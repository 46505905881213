import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface IButtonStyleProps {
  active?: boolean
  extended: boolean
  extendedIcon?: boolean
  fixed?: boolean
}

interface IStyleProps {
  extended: boolean
}

export const Menu = styled.div`
  transition: width 1s;
  z-index: 3;
  width: ${(props: IStyleProps) => (props.extended ? '255px' : '76px')};
  height: 100vh;
  position: fixed;
  border: 1px solid #f5f4f3;
  background-color: #ffffff;

  .buttons {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  .logo {
    overflow: hidden;

    box-sizing: border-box;
    margin: ${(props: IStyleProps) =>
      props.extended ? '19px 31px 15px' : '0'};
    display: flex;
    height: 28px;
    width: 160px;
  }

  .extendButton {
    transition: transform 1s;
    transform: rotate(180deg);
    transform: ${(props: IStyleProps) => props.extended && 'none'};
    z-index: 4;
    position: absolute;
    right: -12px;
    top: 15px;
    filter: invert(72%) sepia(53%) saturate(1415%) hue-rotate(85deg)
      brightness(99%) contrast(97%);
  }
`

export const MenuButton = styled(Link)`
  transition: width 1s;

  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-align: left;

  color: ${(props: IButtonStyleProps) => {
    if (props.active) return '#f9f9f9'
    if (props.fixed) return '#070707'
    return '#d0d0d0'
  }};

  background-color: ${(props: IButtonStyleProps) => {
    if (props.active) return '#191919 !important'
    return '#ffffff'
  }};

  border: 1px solid #f5f4f3;
  border-right: 0;
  height: 60px;

  overflow: hidden;
  width: ${(props: IButtonStyleProps) => (props.extended ? '254px' : '75px')};

  &:hover {
    background-color: #f9f9f9;
  }

  img {
    transition: width 1s;
    margin-left: 30px;
    width: 20px;
    height: 20px;

    filter: ${(props: IButtonStyleProps) => {
      if (props.fixed) return ''
      return 'invert(98%) sepia(0%) saturate(0%) hue-rotate(149deg) brightness(85%) contrast(93%)'
    }};
  }

  span {
    min-width: 200px;
    margin-left: 30px;
    color: #d0d0d0;
  }
`
