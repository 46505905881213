import { useEffect, useState } from 'react'
import { AccountStatus, Button } from 'components'
import { IObject } from 'interfaces'
import jwtDecode from 'jwt-decode'
import { icons } from 'helper'
import { useFetch, useStorage } from 'hooks'
import { ExtendedContainer } from './style'

interface IChatProps {
  name: string
  room: string
  socket: IObject
  title: string
  subtitle?: string
  status?: {
    color: string
    name: string
  }
}

const selectOptions = [
  {
    label: 'Iniciado',
    value: 0,
    color: '#FDAB3D',
  },
  {
    label: 'Atendendo',
    value: 1,
    color: '#0087C1',
  },
  {
    label: 'Parado',
    value: 2,
    color: '#F60665',
  },
  {
    label: 'Resolvido',
    value: 3,
    color: '#0BF569',
  },
]

export default (props: IChatProps) => {
  const { name, status, room, socket, title, subtitle } = props
  const [message, setMessage] = useState<string>('')
  const [messageList, setMessageList] = useState<any>([
    { origin: 1, message: 'Carregando...' },
  ])
  const [st] = useStorage('SapiensToken')

  const getMessagesApi = useFetch(`person/chat?room=${room}`)

  const onRefresh = () => {
    getMessagesApi.fetch('get').then((res) => {
      setMessageList(res.data)
    })
  }

  const onSubmit = async () => {
    const buffer = message
    setMessage('')
    getMessagesApi
      .fetch('post', { message })
      .then(() => {
        setMessageList((m = []) => [...m, { origin: 0, message: buffer }])
      })
      .catch((err: any) => {
        const [_, ...m] = messageList
        setMessageList([...m])
        console.error(err.message)
      })
  }

  useEffect(() => {
    onRefresh()
  }, [])

  useEffect(() => {
    if (!socket?.connected) return
    const { payload } = jwtDecode<IObject>(st)

    socket.on(
      `${room}_refresh_${payload?.partner?.document}`,
      (data: object) => {
        setMessageList((m = []) => [...m, data])
      }
    )
  }, [socket])

  return (
    <ExtendedContainer>
      <div className="left-menu-container">
        <div>
          <p className="bold-text">{title}</p>
          <p className="bold-text">{name}</p>
          {subtitle && <p className="bold-text">{subtitle}</p>}
          {status && (
            <AccountStatus color={status.color} status={status.name} />
          )}
        </div>
      </div>
      <div className="main-container">
        <div className="chat-window">
          {messageList.map((item: any) => {
            return item.origin === 0 ? (
              <div className="mess-conteiner-user">
                <div className="user-message">{item.message}</div>
              </div>
            ) : (
              <div className="mess-conteiner-sapiens">
                <img src={icons.chatLeafLogo} alt="leaf-logo" />
                <div className="sapiens-message">{item.message}</div>
              </div>
            )
          })}
        </div>
        <div className="input-container">
          <textarea
            name="message"
            placeholder="Digite aqui..."
            onChange={(ev) => setMessage(ev.target.value)}
            value={message}
          />
          <Button
            type="button"
            onClick={onSubmit}
            disabled={!message}
            color="white"
            $loading={false}
            secondary
            xSmall
            rounded
          >
            ENVIAR
          </Button>
        </div>
      </div>
    </ExtendedContainer>
  )
}
