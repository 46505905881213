import { Card, Modal } from 'components'
import { SapiensContext } from 'context'
// import { IIcons } from 'helper/icons'
import { useFetch } from 'hooks'
import { ReactElement, useContext, useEffect, useState } from 'react'
import { Content, CardsContainer } from 'styles'
import GestaoFinancas from '../GestaoFinancas'

interface IResponse {
  data?: any
  errors?: any
  status?: number
  fail: boolean
}

export default function Dashboard() {
  const { extended } = useContext(SapiensContext)

  const [dashboardInfo, setDashboardInfo] = useState([])
  const [toPage, setToPage] = useState<any>(false)
  const [showModal, setShowModal] = useState(false)
  const [extendedMenu, setExtendedMenu] = useState<any>(false)

  const modalComponent: { [key: string]: ReactElement } = {
    assets: <GestaoFinancas setExtendedMenu={setExtendedMenu} extendedMenu={extendedMenu}/>,
  }
  
  useEffect(() => {
    if (!showModal) {
      setExtendedMenu(false)
    }
  }, [showModal])

  const api = useFetch('dashboard')

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response: IResponse = await api.fetch('get')
        if (response.fail) throw new Error(response.errors.message)
        const info = response.data.map(
          ([icon, size, value, label, _variation, color, to, rating]: any) => ({
            icon, size, value, label, /*variation,*/ color, to, rating,
          })
        )
        setDashboardInfo(info)
      } catch (err) {
        console.log(err)
      }
    }

    fetchCards()
  }, [])

  const onPressCard = (to: string) => {
    setToPage(to)
    setShowModal(true)
  }

  return (
    <Content extended={extended}>
      <CardsContainer width="996px" marginTop gap={14}>
        {dashboardInfo?.map((card: any) => (
          <Card
            key={card.label}
            variation={card.variation}
            value={card.value}
            green={card.color === 'green'}
            secondary={card.color === 'black'}
            wide={card.size === 'l'}
            icon={card.icon}
            miner={card.icon}
            onClick={card.to && (() => onPressCard(card.to))}
            rating={card.rating}
          >
            {card.label}
          </Card>
        ))}
      </CardsContainer>
     {showModal && (
        <Modal 
          FS 
          setShowModal={setShowModal} 
          showModal={showModal} 
          extendedMenuData={extendedMenu}
        >
          {modalComponent[toPage]}
        </Modal>
      )}
    </Content>
  )
}
