import styled from 'styled-components'

interface IStyleProps {
  width?: number
  height?: number
  removeChildrenPadding?: boolean
  FS?: boolean
  zIndex?: number
}

export const Modal = styled.div`
  width: ${(props: IStyleProps) => {
    if (!props.FS) {
      return `${props.width}px`
    }
    return '95vw'
  }};
  overflow: hidden;
  height: ${(props: IStyleProps) => {
    if (!props.FS) {
      return `${props.height}px`
    }
    return '95vh'
  }};
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;

  background-color: #fff;
  box-shadow: 0px 3px 4px 0px #00000040;

  display: flex;
  justify-content: space-between;
`

export const ChildrenContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding: ${(props: IStyleProps) => {
    if (props.removeChildrenPadding) {
      return `0px`
    }
    return '50px'
  }};
  display: flex;
  flex-direction: column;
`

export const Background = styled.div`
  z-index: ${(props: IStyleProps) => {
    if (props.zIndex) return '11'
    return '10'
  }};
  background: #00000026;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CloseButton = styled.button`
  height: 15px;
  width: 15px;
  border: none;
  background-color: transparent;
  align-self: flex-end;
  /* padding: 25px; */
`