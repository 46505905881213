import { PagePathing, PageTitle, KanbanSubMenu } from 'components'
import { SapiensContext } from 'context'
import { useContext } from 'react'
import { Content } from 'styles'
import { icons } from 'helper'
import Kanban from './Kanban'

export default function GestaoContratos() {
  const { extended } = useContext(SapiensContext)

  const categories = [
    ['Contratos', `/gestaoContratos`, icons.client2Outlined, true],
    ['Novo contrato', '/gestaoContratos/novoContrato', icons.plusSolid, false],
  ]

  return (
    <Content extended={extended}>
      <div>
        <PagePathing>{`Gestão de garantias > Novo contrato`}</PagePathing>
        <PageTitle>Tipo de contrato</PageTitle>
        <KanbanSubMenu categories={categories} />
      </div>
      <Kanban />
    </Content>
  )
}
